import { styled } from "../style/stitches.config";

export const ToggleSlider = ({
  isOn,
  setIsOn,
}: {
  isOn: boolean;
  setIsOn: Function;
}) => {
  return (
    <StyledToggleSlider isOn={isOn} onClick={() => setIsOn(!isOn)}>
      <div className="toggle-button"></div>
    </StyledToggleSlider>
  );
};

const StyledToggleSlider = styled("div", {
  width: "32px",
  backgroundColor: "$gray200",
  borderRadius: "8px",
  height: "16px",
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  position: "relative",
  transition: "all 0.2s",

  "& .toggle-button": {
    width: "14px",
    height: "14px",
    backgroundColor: "white",
    borderRadius: "50%",
    left: "1px",
    position: "absolute",
    transition: "all 0.2s",
  },
  variants: {
    isOn: {
      true: {
        backgroundColor: "black",
        "& .toggle-button": {
          left: "17px",
        },
      },
    },
  },
});
