import html2canvas from "html2canvas";
import { Button } from "../components/Button";
import { keyframes, styled, theme } from "../style/stitches.config";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import { useStorage } from "~/state/liveblocks.config";
import { AuthContext } from "~/utils/useAuth";
import screenshotThemeData from "src/assets/screenshot-styles.json";
import { freeDeckIds, RoomState, videoDeckIds } from "~/types/room/types";
import { textTransform } from "html2canvas/dist/types/css/property-descriptors/text-transform";
import { getFormattedDate } from "~/utils/datetime";
import { SimpleLoadingIndicator } from "~/pages/loading/LoadingPage";
import { shallow } from "@liveblocks/client";
import JSZip from "jszip";
import { localState } from "~/state/state";
import { useSnapshot } from "valtio";
import { imageUrl } from "~/utils/imageurl";
import { trackEvent } from "~/api/analyticsApi";

interface ScreenshotTheme {
  name: string;
  mainTableItems: string[];
  pageTableItems: string[];
  backgroundColor?: string;
  backgroundImage?: string;
  highlightColor: string;
  boxOutlineColor: string;
  boxBackgroundColor: string;
  boxTextColor: string;
  previewColor: string;
  freeDeckFilter?: string;
}

const themes: ScreenshotTheme[] = screenshotThemeData;

const QuestionEntry = ({
  username,
  question,
  setQuestion,
  questionBoxHeight,
  style,
}: {
  username: string;
  question: string;
  setQuestion: (q: string) => void;
  questionBoxHeight: number;
  style?: any;
}) => {
  useEffect(() => {
    // make list
    Array.from(document.getElementsByClassName("question-input")).forEach(
      (el) => {
        (el as HTMLInputElement).value = question;
      }
    );
  }, [question]);

  return (
    <div
      className="question-section"
      style={{ height: `${questionBoxHeight + 16}px` }}
    >
      <textarea
        id="question-entry"
        className="question question-input"
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        onInput={(e) => {
          e.stopPropagation();
          // max 60 char
          setQuestion((e.target as HTMLInputElement).value);
        }}
        placeholder="Your question / theme"
        defaultValue={question}
        autoFocus
        maxLength={40}
        style={{
          backgroundColor: "transparent",
          display: "block",
          height: `${questionBoxHeight}px`,
          ...style,
        }}
        spellCheck={false}
      ></textarea>
      <div className="question-plaintext question">{question}</div>
      <div
        className="question-attr"
        style={{
          opacity: question === "" ? 0 : 1,
        }}
      >{`– ${username}`}</div>
    </div>
  );
};

export const RoundScreenshotButton = ({ onClick }: { onClick: Function }) => {
  const diameter = 96;
  const { inDarkMode } = useSnapshot(localState);
  return (
    <StyledRoundScreenshotButton
      id="recap-button"
      theme={inDarkMode ? "dark" : "light"}
      onClick={() => {
        trackEvent("/room/save_recap");
        onClick();
      }}
    >
      <div className="inner-button-container">
        <div className="svg-container">
          <svg
            viewBox={`0 0 ${diameter} ${diameter}`}
            // style={{ backgroundColor: "blue" }}
          >
            <path
              id="curve"
              fill="transparent"
              d="
            M 10,50
            a 40, 40 0 1,1 80,0
            a 40,40 0 1,1 -80,0
          "
            />
            <text className="text" width={diameter} fill="black">
              <textPath xlinkHref="#curve">save recap</textPath>
            </text>
          </svg>
        </div>
        <img src="/images/share.svg" />
        <div className="screenshot-button" id="download-button"></div>
      </div>
    </StyledRoundScreenshotButton>
  );
};

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const rotate = keyframes({
  "0%": {
    transform: "scale(0.85) rotate(0deg) ",
  },
  "100%": {
    transform: "scale(0.85) rotate(360deg) ",
  },
});

const StyledRoundScreenshotButton = styled("div", {
  position: "absolute",
  bottom: "60px",

  right: "13px",
  zIndex: 3,
  "@mobile": {
    bottom: "8px",
    right: "8px",
    zIndex: 10000,
  },
  animation: `${fadeIn} 0.5s ease-in-out`,
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(0.9)",
    "& .screenshot-button": {
      backgroundColor: "$gray900 !important",
    },
  },
  cursor: "pointer",
  "& img": {
    width: "20px",
    height: "20px",
    position: "absolute",
    top: "calc(50% - 2px)",
    left: "calc(50% + 2px)",
    transform: "translate(-50%, -50%)",
  },
  "& .inner-button-container": {
    position: "relative",
    width: "88px",
    height: "88px",
    "& .svg-container": {
      fontWeight: "300",
      animation: `${rotate} 20s linear infinite`,
      transformOrigin: "center",
      fontSize: "14px",
      letterSpacing: "0.05em",
      "@mobile": {
        fontWeight: 400,
      },
    },
    "& .screenshot-button": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      backgroundColor: "black",
      position: "absolute",
      border: "0.5px solid #5e5e5e",
      top: 0,
      left: 0,
      zIndex: -1,
      transition: "all 0.2s ease-in-out",
    },
  },

  variants: {
    theme: {
      light: {
        "& text": {
          fill: "white",
        },
      },
      dark: {
        "& text": {
          fill: "black",
        },

        "&:hover": {
          "& .screenshot-button": {
            backgroundColor: "#f1f1f1 !important",
          },
        },

        "& .screenshot-button": {
          backgroundColor: "white !important",
          border: ".5px solid #c1c1c1",
        },
        "& img": {
          filter: "invert(1)",
        },
      },
    },
  },
});

export const ScreenshotOverlay = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currThemeIdx, setCurrThemeIdx] = useState(0);
  const [currImgIdx, setCurrImgIdx] = useState(0);
  const [question, setQuestion] = useState("");
  const { isOnMobile } = useSnapshot(localState);

  const { user } = useContext(AuthContext);
  const isShuffling = useStorage((root) => root.state === RoomState.Shuffle);

  // cards only first 10
  const cards = useStorage((root) => root.cards, shallow)
    .filter((card) => card.open)
    .slice(0, 10);
  const deck = useStorage((root) => root.deck, shallow);
  const spread = useStorage((root) => root.spread, shallow);
  const reversesOn = useStorage((root) => root.reversesOn);
  const [cardWidth, setCardWidth] = useState("");
  const [maxHeight, setMaxHeight] = useState("");
  const [borderRadius, setBorderRadius] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const footer = (
    <div className="footer">
      <img
        className="moon"
        src={`/images/moon${
          themes[currThemeIdx].highlightColor === "#000" ||
          themes[currThemeIdx].highlightColor === "black" ||
          themes[currThemeIdx].highlightColor === "#000000"
            ? "-dark"
            : "-light"
        }.png`}
      ></img>
      <div className="footer-text">
        <div className="footer-text-1">@entermoonlight</div>
      </div>
    </div>
  );

  useEffect(() => {
    const elToHide = document.getElementById("mobile-message");
    const elToHide2 = document.getElementById("recap-button");
    if (isOpen) {
      if (elToHide) {
        elToHide.style.display = "none";
      }
      if (elToHide2) {
        elToHide2.style.display = "none";
      }
    } else {
      if (elToHide) {
        elToHide.style.display = "block";
      }
      if (elToHide2) {
        elToHide2.style.display = "block";
      }
    }
  }, [isOpen]);

  const takeScreenshot = () => {
    const zip = new JSZip();
    const imgFolder = zip.folder(
      `moonlight tarot reading ${getFormattedDate()}`
    );
    const imageFiles: File[] = [];
    const canvasDivs = Array.from(
      document.getElementsByClassName("outer-canvas")
    );

    // Use Promise.all to wait for all canvases to be processed
    Promise.all(
      canvasDivs.reverse().map((outerCanvas, index) =>
        html2canvas(outerCanvas as HTMLElement, {
          scale: 5,
          useCORS: true,
          allowTaint: true,
          logging: true,
        }).then((canvas) => {
          return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
              if (!blob) {
                reject("Failed to create blob");
                return;
              }
              const filename = `moonlight tarot reading ${getFormattedDate()}_${index}.png`;
              if (!isMobile()) {
                imgFolder!.file(filename, blob);
                resolve(filename); // Resolve the filename
              } else {
                const file = new File([blob], filename, {
                  type: "image/png",
                  lastModified: Date.now(), // Use the current timestamp
                });

                imageFiles.push(file);
                // Only share once all files are ready
                if (imageFiles.length === canvasDivs.length) {
                  if (
                    navigator.canShare &&
                    navigator.canShare({ files: imageFiles })
                  ) {
                    navigator
                      .share({
                        files: imageFiles,
                        title: "Photos",
                        text: "Today's tarot reading on Moonlight 🌙 Try it here: https://moonlight.world",
                      })
                      .then(() => {
                        console.log("Share was successful.");
                        setIsSaving(false);
                        setIsOpen(false);
                      })
                      .catch((error) => {
                        console.log("Sharing failed", error);
                        setIsSaving(false);
                        setIsOpen(false);
                      });
                  }
                }
                resolve(filename);
              }
            }, "image/png");
          });
        })
      )
    )
      .then((filenames) => {
        // This .then() waits for all canvases to be processed
        if (!isMobile()) {
          // Generate the ZIP file
          zip
            .generateAsync({ type: "blob" })
            .then((content) => {
              saveFile(
                new File(
                  [content],
                  `moonlight tarot reading ${getFormattedDate()}.zip`
                )
              );
            })
            .then(() => {
              // Close the modal
              setIsSaving(false);
              setIsOpen(false);
            });
        }
      })
      .catch((error) => {
        console.error("Error creating images", error);
      });
  };

  const saveFile = (file: File) => {
    const link = document.createElement("a");
    link.download = file.name;
    link.href = URL.createObjectURL(file);
    link.click();
    URL.revokeObjectURL(link.href);
  };

  // Utility function to check if the device is mobile
  // You might want to use a more sophisticated check
  const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

  const p = 12;

  const makeWidth = (percent: number, padding: number) => {
    return `calc(${percent}% - ${padding}px)`;
  };

  useEffect(() => {
    // if there are <= 3 cards, set width to 33%
    if (cards.length <= 3) {
      setCardWidth(makeWidth(33, p));
      setMaxHeight(makeWidth(100, p));
      setBorderRadius("8px");
    }
    // if there are 4 cards, set width to 25%
    else if (cards.length === 4) {
      setCardWidth(makeWidth(25, p));
      setMaxHeight(makeWidth(100, p));
      setBorderRadius("6px");
    }
    // if there are 5  or 6 cards, set width to 33%, we're on 2 lines
    else if (cards.length === 5 || cards.length === 6) {
      setCardWidth(makeWidth(33, p));
      setMaxHeight(makeWidth(45, p));
      setBorderRadius("6px");
    }
    // if there are 7 or 8 cards, set width to 25%, we're on 2 lines
    else if (cards.length === 7 || cards.length === 8) {
      setCardWidth(makeWidth(25, p));
      setMaxHeight(makeWidth(45, p));
      setBorderRadius("6px");
    }
    // if there are 9 cards, set width to 20%, we're on 2 lines
    else if (cards.length === 9 || cards.length >= 10) {
      setCardWidth(makeWidth(20, p));
      setMaxHeight(makeWidth(45, p));
      setBorderRadius("5px");
    }
  }, [cards.length]);
  if (isShuffling || cards.length === 0) return <></>;

  return (
    <>
      {!videoDeckIds.includes(deck.id) && (
        <RoundScreenshotButton
          onClick={() => {
            setIsOpen(true);
          }}
        />
      )}

      {isOpen && (
        <StyledScreenshotOverlay
          css={{
            $$boxOutlineColor: themes[currThemeIdx].boxOutlineColor,
            $$boxBackgroundColor: themes[currThemeIdx].boxBackgroundColor,
            $$boxTextColor: themes[currThemeIdx].boxTextColor,
            $$backgroundColor:
              themes[currThemeIdx].backgroundColor ?? "#fdfdfd",
            $$highlightColor: themes[currThemeIdx].highlightColor,
            $$focusHighlightColor: themes[currThemeIdx].highlightColor + "60",
            $$borderRadius: borderRadius,
            $$deckFilter: freeDeckIds.includes(deck.id)
              ? themes[currThemeIdx].freeDeckFilter
              : "none",
          }}
          onClick={(e) => {
            if (e.target === e.currentTarget) setIsOpen(false);
          }}
        >
          <div className="view-container">
            {isSaving && (
              <div className="downloading-container">
                <SimpleLoadingIndicator style={{ top: "-70px" }} />
                <div className="downloading-title">Downloading...</div>
                <div className="downloading-subtitle">
                  This may take a minute
                </div>
              </div>
            )}
            <img
              className="x-button"
              onClick={() => setIsOpen(false)}
              src="/images/exit.svg"
            />

            <div className={`outer-canvas-container downloading-${isSaving}`}>
              <img
                className="prev-button arrow-button"
                src={"/images/next-arrow.svg"}
                onClick={() => {
                  if (currImgIdx === 0) setCurrImgIdx(cards.length);
                  else setCurrImgIdx((currImgIdx - 1) % (cards.length + 1));
                }}
              />
              {cards.map((card, i) => {
                const slot =
                  spread.slots.find(
                    (slot) => slot.cards && slot.cards.includes(card.filePath)
                  )?.name || null;

                const deckCard = deck.cards.find((deckCard) =>
                  card.filePath.includes(deckCard.filePath)
                );

                const isCardReversed = reversesOn && card.reversed;
                const cardRotation = isCardReversed ? 180 : 0;

                return (
                  <div
                    key={i}
                    className="z-index-canvas"
                    style={{
                      zIndex: currImgIdx === i + 1 ? 1 : 0,
                    }}
                  >
                    <div
                      key={i}
                      id="outer-canvas"
                      className={`outer-canvas card card-${i}`}
                    >
                      <img
                        className="background-image"
                        src={imageUrl(
                          `/images/screenshot-bgs/${themes[currThemeIdx].backgroundImage}`,
                          680
                        )}
                      />
                      <>
                        <div className="border-box">
                          <div className="question-section">
                            <QuestionEntry
                              username={user?.name ?? ""}
                              question={question}
                              setQuestion={setQuestion}
                              questionBoxHeight={28}
                              style={{
                                width: "calc(100% - 10px)",
                                marginRight: "-6px",
                              }}
                            />
                          </div>
                          <div className="card-hero-box">
                            <div
                              className="card-image-container"
                              style={{
                                transform: `rotate(-2deg)`,
                              }}
                            >
                              <img
                                className="card-image"
                                src={imageUrl(`/images${card.filePath}`, 240)}
                                style={{
                                  transform: `rotate(${cardRotation}deg)`,
                                  borderBottom:
                                    reversesOn && card.reversed
                                      ? "1px solid black"
                                      : `3px solid black`,
                                  borderTop:
                                    reversesOn && card.reversed
                                      ? "3px solid black"
                                      : "1px solid black",
                                }}
                              />
                              <div className="deck-artist">
                                Deck: {deck.name} <br />
                                by {deck.creator}
                              </div>
                            </div>
                            <div className="card-description-box">
                              {slot && <div className="slot-title">{slot}</div>}
                              <div className="card-title">{deckCard?.name}</div>
                              {isCardReversed && (
                                <div className="card-reversed">Reversed</div>
                              )}
                              <div className="keywords-container">
                                {isCardReversed &&
                                deckCard?.keywordsReverse &&
                                deckCard.keywordsReverse.length > 1
                                  ? deckCard?.keywordsReverse.join(" • ")
                                  : deckCard?.keywords.join(" • ")}
                              </div>
                            </div>
                          </div>
                          <img
                            className="table-item-2"
                            src={imageUrl(
                              `/images/table-items/${
                                themes[currThemeIdx].pageTableItems[
                                  i % themes[currThemeIdx].pageTableItems.length
                                ]
                              }`,
                              200
                            )}
                          />
                        </div>
                        {footer}
                      </>
                    </div>
                  </div>
                );
              })}
              <div
                id="outer-canvas"
                className="outer-canvas cover"
                style={{
                  zIndex: currImgIdx === 0 ? 1 : 0,
                }}
              >
                <img
                  className="background-image"
                  src={imageUrl(
                    `/images/screenshot-bgs/${themes[currThemeIdx].backgroundImage}`,
                    680
                  )}
                />
                {themes[currThemeIdx].mainTableItems[0] && (
                  <img
                    className="table-item-1"
                    src={imageUrl(
                      `/images/table-items/${themes[currThemeIdx].mainTableItems[0]}`,
                      200
                    )}
                  />
                )}
                {themes[currThemeIdx].mainTableItems[1] && (
                  <img
                    className="table-item-2"
                    src={imageUrl(
                      `/images/table-items/${themes[currThemeIdx].mainTableItems[1]}`,
                      200
                    )}
                  />
                )}
                <div className="border-box">
                  <QuestionEntry
                    username={user?.name ?? ""}
                    question={question}
                    setQuestion={setQuestion}
                    questionBoxHeight={62}
                    style={{
                      paddingTop: "6px",
                    }}
                  />
                  <div className="card-container">
                    {cards.map((card, i) => {
                      const cardJitter =
                        i % 3 === 0
                          ? 1.5
                          : i % 3 === 1
                          ? 0
                          : i % 3 === 2
                          ? -1.5
                          : 0;

                      const cardRotate = reversesOn && card.reversed ? 180 : 0;

                      return (
                        <img
                          key={i}
                          className="card"
                          style={{
                            maxWidth: cardWidth,
                            maxHeight: maxHeight,
                            border: `1px solid ${card.borderColor}`,
                            borderBottom:
                              reversesOn && card.reversed
                                ? `1px solid black`
                                : `3px solid black`,
                            borderTop:
                              reversesOn && card.reversed
                                ? "3px solid black"
                                : "1px solid black",
                            transformOrigin: "center",
                            transform: `rotate(${cardRotate + cardJitter}deg)`,
                          }}
                          src={imageUrl("/images" + card.filePath, 200)}
                        />
                      );
                    })}
                    <div className="deck-text">
                      Deck: {deck.name} <br />
                      by {deck.creator}
                    </div>
                  </div>
                  {footer}
                </div>
              </div>
              <img
                className="next-button arrow-button"
                src={"/images/next-arrow.svg"}
                onClick={() =>
                  setCurrImgIdx((currImgIdx + 1) % (cards.length + 1))
                }
              />
            </div>
            <div className="slide-buttons">
              <>
                {[0, ...cards].map((_, i) => (
                  <div
                    key={i}
                    className={`slide-button selected-${currImgIdx === i}`}
                    onClick={() => setCurrImgIdx(i)}
                  ></div>
                ))}
              </>
            </div>
            <div className="theme-selector-container">
              <>
                {themes.map((theme, i) => (
                  <div
                    key={i}
                    className={`theme-selector selected-${currThemeIdx === i}`}
                    onClick={() => setCurrThemeIdx(i)}
                  >
                    <div
                      className="dot"
                      style={{
                        backgroundColor: theme.previewColor,
                      }}
                    ></div>
                  </div>
                ))}
              </>
            </div>
            <Button
              style={{
                marginTop: isOnMobile ? "14px" : "10px",
                borderRadius: "14px",
                width: "155px",
                height: "45px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "13px",
                fontWeight: 400,
              }}
              onClick={() => {
                trackEvent("/room/save_recap/saved");
                setIsSaving(true);
                window.setTimeout(() => {
                  takeScreenshot();
                }, 10);
              }}
              darkHover
            >
              <img
                style={{
                  width: "20px",
                  height: "20px",
                  marginTop: "1px",
                  marginRight: isOnMobile ? 0 : "4px",
                  marginBottom: "1px",
                  marginLeft: isOnMobile ? 0 : "-8px",
                }}
                src={`/images/${isOnMobile ? "export" : "download"}-icon.svg`}
              ></img>
              {isOnMobile ? "" : "Save all"}
            </Button>
            <div className="bottom-note">
              Save, send, or post your reflections!
              <br />
              We’re{" "}
              <a href="https://instagram.com/entermoonlight" target="_blank">
                @entermoonlight
              </a>
            </div>
          </div>
        </StyledScreenshotOverlay>
      )}
    </>
  );
};

const StyledScreenshotOverlay = styled("div", {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  zIndex: 1000,
  "& .downloading-container": {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(246, 246, 246, 0.9)",
    zIndex: 1001,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
    flexDirection: "column",
    "& .downloading-title": {
      fontFamily: "KeplerLightCondensed",
      fontSize: "24px",
      marginTop: "-8px",
    },
    "& .downloading-subtitle": {
      fontSize: "12px",
    },

    backdropFilter: "blur(10px)",
  },
  "& .view-container": {
    color: "black",
    overflow: "hidden",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "30px",

    padding: "50px 40px 20px 40px",
    boxShadow: "0px 3px 0px 0px rgba(0, 0, 0, 1)",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "$wash",
    border: "1px solid black",
    "& .slide-buttons": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "5px",
      marginTop: "10px",
      "@mobile": {
        marginTop: "-6px",
      },
      "& .slide-button": {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#D9D9D9",
        cursor: "pointer",
        transition: "all 0.1s ease-in-out",

        "&:hover": {
          backgroundColor: "$gray300",
        },
        "&.selected-true": {
          backgroundColor: "$gray400",
        },
      },
    },

    "& .theme-selector-container": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "1px",
      marginTop: "10px",
      "& .theme-selector": {
        padding: "6px",
        cursor: "pointer",
        borderRadius: "6px",

        "& .dot": {
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          border: "1px solid black",
        },

        "&:hover": {
          backgroundColor: "$gray200",
        },

        "&.selected-true": {
          backgroundColor: "$gray300",
        },
      },
    },
    "& .x-button": {
      position: "absolute",
      top: "10px",
      right: "10px",
      cursor: "pointer",
      width: "30px",
      height: "30px",
      "&:hover": {
        opacity: 0.5,
      },
    },
    "& .outer-canvas-container": {
      border: "1px solid black",
      // borderRadius: "18px",
      position: "relative",
      "& .arrow-button": {
        position: "absolute",
        top: "0",
        bottom: 0,
        margin: "auto",
        height: "30px",
        width: "30px",
        borderRadius: "50%",
        zIndex: 100,
        cursor: "pointer",
        padding: "5px",
        "&:hover": {
          backgroundColor: "$gray200",
        },
        "&.prev-button": {
          left: "-34px",
          transform: "rotate(180deg)",
        },
        "&.next-button": {
          right: "-34px",
        },
        "@mobile": {
          padding: "10px",
          width: "40px",
          height: "80px",
          paddingTop: "30px",
          paddingBottom: "30px",
          "&.prev-button": {
            left: "-44px",
          },
          "&.next-button": {
            right: "-44px",
          },
          "&:hover": {
            backgroundColor: "unset",
          },
        },
      },
    },
    "& .z-index-canvas": {
      position: "absolute",
    },

    "& #outer-canvas": {
      "& img": {
        pointerEvents: "none",
      },
      width: "340px",
      height: "340px",
      position: "relative",
      "& .background-image": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -2,
        width: "100%",
        height: "100%",
        backgroundColor: "$$backgroundColor",
      },
      // borderRadius: "18px",

      backgroundSize: "cover",
      color: "$$highlightColor",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },
    "& .question-section": {
      width: "calc(100% - 100px)",
      marginTop: "16px",
      display: "flex",
      flexDirection: "column",
      marginBottom: 0,
      position: "relative",
      // justifyContent: "center",
      alignItems: "center",
      "& .question": {
        width: "calc(100%)",
        left: 0,
        right: 0,
        margin: "auto",
        top: 0,
        overflow: "hidden",
        resize: "none",
        fontFamily: "KeplerLightCondensed",
        lineHeight: "0.9",
        fontSize: "30px",
        backgroundColor: "transparent",
        border: "1px solid transparent",
        padding: "4px",
        // paddingBottom: 0,
        cursor: "text",
        position: "absolute",
        "&::placeholder": {
          color: "$$highlightColor",
          opacity: 0.5,
        },
        "&:focus": {
          outline: "none",
          border: "1px solid $$focusHighlightColor",
          borderRadius: "12px",
        },

        "&.question-input:focus": {
          opacity: 1,
          zIndex: 1,
          "& + .question-plaintext": {
            opacity: 0,
            zIndex: 0,
          },
        },

        "&.question-input:not(:focus):not(:empty)": {
          opacity: 0,
          zIndex: 1,
          "& + .question-plaintext": {
            opacity: 1,
            zIndex: 0,
          },
        },
        textAlign: "center",
      },
      "& .question-attr": {
        fontSize: "10px",
        position: "absolute",
        bottom: "0px",
        // marginTop: "-4px",
      },
    },
    "& .table-item-1": {
      position: "absolute",
      top: "-5px",
      left: "-20px",
      width: "80px",
    },
    "& .table-item-2": {
      position: "absolute",
      bottom: "-20px",
      right: "-10px",
      width: "100px",
    },
    "& .border-box": {
      width: "calc(100% - 16px)",
      height: "calc(100% - 16px)",
      border: "1px solid $$highlightColor",
      borderRadius: "13px",
      display: "flex",
      flexDirection: "column",
      // justifyContent: "center",
      alignItems: "center",
    },
    "& .card-container": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      height: "200px",
      "& .card": {
        flexShrink: 0,
        alignSelf: "flex-center",
        margin: "6px",
        borderRadius: "$$borderRadius",
        border: "1px solid black",
        filter: "$$deckFilter",
      },
      "& .deck-text": {
        fontSize: "10px",
        textAlign: "center",
        width: "80%",
      },
    },
    "& .footer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      height: "30px",
      position: "absolute",
      bottom: "10px",
      left: "6px",
      "& .moon": {
        marginRight: "2px",
        marginLeft: "6px",
        width: "25px",
        height: "25px",
      },
      "& .footer-text": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        paddingBottom: "5px",
        height: "100%",
        "& .footer-text-1": {
          fontSize: " 10px",
        },
      },
    },
    "& .outer-canvas.card": {
      "& .question-section": {
        display: "flex",
        alignItems: "flex-end",
        width: "100%",
        paddingRight: "12px",
        marginTop: "6px",
        "& .question": {
          textAlign: "right",
          fontSize: "23px",
        },
      },
      "& .card-hero-box": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .card-image-container": {
          width: "120px",
        },
        "& .card-image": {
          border: "1px solid black",
          width: "100%",
          borderRadius: "12px",
          filter: "$$deckFilter",
          marginBottom: "2px",
        },
        "& .deck-artist": {
          fontSize: "8px",
          textAlign: "center",
        },
        "& .card-description-box": {
          marginTop: "16px",
          width: "150px",
          border: "1px solid $$boxOutlineColor",
          backgroundColor: "$$boxBackgroundColor",
          color: "$$boxTextColor",
          marginLeft: "20px",
          borderRadius: "12px",
          padding: "20px 16px",
          "& .slot-title": {
            textTransform: "uppercase",
            fontFamily: "KeplerLightCondensed",
            fontSize: "15px",
            fontStyle: "italic",
            width: "100%",
            textAlign: "center",
            "&::after": {
              content: "",
              display: "block",
              width: "100%",
              height: "0.5px",
              backgroundColor: "$$boxTextColor",
            },
          },
          "& .card-title": {
            fontFamily: "GorgeousBaby",
            fontSize: "28px",
            textAlign: "center",
            marginTop: "16px",
          },
          "& .card-reversed": {
            textTransform: "uppercase",
            fontSize: "8px",
            fontWeight: "200",
            textAlign: "center",
          },
          "& .keywords-container": {
            marginTop: "5px",
            textAlign: "center",
            textWrap: "wrap",
            width: "100%",
            display: "inline-block",
            fontSize: "10px",

            textTransform: "lowercase",
          },
        },
      },
    },
    "& .bottom-note": {
      textAlign: "center",
      fontSize: "11px",
      marginTop: "16px",
      pointerEvents: "all",
      lineHeight: "1.3em",
      userSelect: "text",
      "& a": {
        fontWeight: "500",
        textDecoration: "none",
        "&:hover": {
          opacity: 0.5,
        },
      },
      "@mobile": {
        fontSize: "12px",
      },
    },
  },
  "@mobile": {
    "& .view-container": {
      width: "100%",
      height: "100%",
      borderRadius: 0,
    },
    "& .outer-canvas-container": {
      "&.downloading-false": {
        transform: "scale(0.9)",
      },
      border: "none",
    },
    "& .x-button": {
      top: "50px !important",
    },
  },
});
