import { useEffect } from "react";
import { RoomTheme } from "~/types/room/types";
import { styled } from "~/ui/style/stitches.config";

export const backgroundColors = [
  {
    color: "#262626",
    theme: "dark",
  },
  {
    color: "#495198",
    theme: "dark",
  },
  {
    color: "#287378",
    theme: "dark",
  },
  {
    color: "#D8B9FF",
    theme: "light",
  },
  {
    color: "#F3E4D4",
    theme: "light",
  },
  {
    color: "#E6E6E6",
    theme: "light",
  },
];

export const DEFAULT_BACKGROUND = backgroundColors[5];

export const accentColors = [
  {
    color: "#EFC1FF",
    theme: "light",
  },
  {
    color: "#FFC2C6",
    theme: "light",
  },
  {
    color: "#F6B687",
    theme: "light",
  },
  {
    color: "#F2C889",
    theme: "light",
  },
  {
    color: "#F8E08A",
    theme: "light",
  },
  {
    color: "#DAEEA1",
    theme: "light",
  },
];
export const DEFAULT_ACCENT = accentColors[0];

export const ColorEditor = ({
  setBackgroundColor,
  backgroundColor,
  setAccentColor,
  accentColor,
  theme,
}: {
  setBackgroundColor: Function;
  setAccentColor: Function;
  theme: RoomTheme;
  backgroundColor: string;
  accentColor: string;
}) => {
  return (
    <StyledColorEditor Theme={theme}>
      <div className="color-title">Background color</div>
      <div className="background-colors color-container">
        {backgroundColors.map((clr, i) => (
          <div
            key={clr.color}
            style={{ backgroundColor: clr.color }}
            onClick={() => setBackgroundColor(clr)}
            className={`color-selector selected-${
              clr.color === backgroundColor
            }`}
          ></div>
        ))}
      </div>
      <div className="color-title">Accent color</div>

      <div className="accent-colors color-container">
        {accentColors.map((clr, i) => (
          <div
            key={clr.color}
            style={{ backgroundColor: clr.color }}
            onClick={() => setAccentColor(clr)}
            className={`color-selector selected-${clr.color === accentColor}`}
          ></div>
        ))}
      </div>
    </StyledColorEditor>
  );
};

const StyledColorEditor = styled("div", {
  marginBottom: "20px",
  "& .color-container": {
    display: "flex",
    flexDirection: "row",
    marginBottom: "12px",
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  "& .color-title": {
    fontSize: "12px",
    marginBottom: "4px",
  },

  ".color-selector": {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    cursor: "pointer",
    marginRight: "6px",
    border: "1px solid",
    "&.selected-true": {
      border: "2px solid",
    },
  },
  "@mobile": {
    marginTop: "-16px",
    "& .color-title": {
      fontSize: "13px",
      marginBottom: "10px",
    },
  },
  variants: {
    Theme: {
      light: {
        ".color-selector": {
          borderColor: "black",
        },
      },
      dark: {
        ".color-selector": {
          borderColor: " white",
        },
      },
    },
  },
});
