import queryString from "query-string";
import { useContext, useEffect } from "react";
import useLocation from "wouter/use-location";
import { calendarAuthorized } from "~/api/calendarApi";
import { UserProfile } from "~/api/userApi";
import { SimpleLoadingIndicator } from "../loading/LoadingPage";
import { AuthContext } from "~/utils/useAuth";

interface Oauth2CallbackData {
  code?: string;
  scope?: string[];
  authuser?: string;
  hd?: string;
  prompt?: string;
}

export const Oauth2Callback = ({ user }: { user: UserProfile | null }) => {
  const { getUser } = useContext(AuthContext);
  const parsed = queryString.parse(window.location.search);
  const [_, setLocation] = useLocation();
  useEffect(() => {
    const oauth2CallbackData: Oauth2CallbackData = {
      code: parsed.code as string | undefined,
      scope: parsed.scope as string[] | undefined,
      authuser: parsed.authuser as string | undefined,
      hd: parsed.hd as string | undefined,
      prompt: parsed.prompt as string | undefined,
    };
    const setTokenAndRedirect = async () => {
      if (user?.uuid && oauth2CallbackData.code) {
        calendarAuthorized(user.uuid, oauth2CallbackData.code)
        .then(() => getUser())
        .then(() => setLocation("/availability"))
        .catch((error) => {
          console.log(error);
          switch ((error as unknown as any).response?.status) {
            case 417:
            case 400:
            case 404:
              setLocation("/availability");
              break;
            default:
              setLocation("/404");
              break;
          }
        })
      }
    };
    setTokenAndRedirect();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div>
        <SimpleLoadingIndicator />
      </div>
    </div>
  );
};
