import { keyframes, styled } from "@stitches/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { localState } from "~/state/state";

export const SimpleLoadingIndicator = ({ style }: { style?: Object }) => {
  return (
    <StyledSimpleLoadingIndicator>
      <div style={style} id="circle"></div>
    </StyledSimpleLoadingIndicator>
  );
};

export const LoadingIndicator = ({
  noText,
  loading,
}: {
  noText?: boolean;
  loading?: boolean;
}) => {
  return (
    <StyledLoadingIndicator
      noText={noText}
      loading={loading}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div id="bottom-moon" className="moon"></div>
      <div id="middle-moon" className="moon"></div>
      <div id="top-moon" className="moon"></div>
      <div id="toppest-moon" className="moon"></div>
      <div className="loading-text">Loading</div>
    </StyledLoadingIndicator>
  );
};

export const LoadingPage = ({
  noText,
  connectionPage,
}: {
  noText?: boolean;
  connectionPage?: boolean;
}) => {
  useEffect(() => {
    if (connectionPage) localState.isConnecting = true;
  }, []);
  return (
    <AnimatePresence>
      <StyledLoadingPage>
        <LoadingIndicator noText={noText} />
      </StyledLoadingPage>
    </AnimatePresence>
  );
};

const pulse = keyframes({
  "0%": { transform: "scale(0.5)" },
  "50%": { transform: "scale(1)" },
  "100%": { transform: "scale(0.5)" },
});

const StyledSimpleLoadingIndicator = styled("div", {
  "& #circle": {
    width: "40px",
    height: "40px",
    backgroundColor: "$gray500",
    position: "absolute",
    left: 0,
    right: 0,
    margin: "auto",
    top: "-20px",
    bottom: 0,
    borderRadius: "50%",
    animation: `${pulse} 3s infinite`,
  },
});

const StyledLoadingPage = styled(motion.div, {
  width: "100%",
  height: "100vh",
  backgroundColor: "$wash",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const waxAndWane = keyframes({
  "0%": { transform: "rotateY(0deg)", backgroundColor: "black" },
  "24.99%": {
    backgroundColor: "black",
  },
  "25%": { transform: "rotateY(90deg)", backgroundColor: "white" },
  "49.99%": {
    backgroundColor: "white",
  },
  "50%": {
    transform: "rotateY(180deg)",
  },
  "74.99%": { backgroundColor: "white" },
  "75%": { backgroundColor: "black", transform: "rotateY(270deg)" },
  "99.99%": { transform: "rotateY(360deg)", backgroundColor: "black" },
});

const StyledLoadingIndicator = styled(motion.div, {
  position: "relative",
  "& .moon": {
    position: "absolute",
    width: "60px",
    height: "60px",
    left: 0,
    right: 0,
    margin: "auto",
    borderRadius: "50%",
    border: "1px solid black",
  },
  "& #top-moon": {
    backgroundColor: "black",
    animation: `${waxAndWane} 6s cubic-bezier(.27,.61,.74,.42) infinite`,
    border: "none",
  },
  "& #middle-moon": {
    backgroundColor: "black",
    borderRadius: "150px 150px 0 0",
    height: "30px",
    transform: "rotate(90deg)",
    transformOrigin: "0 0",
    marginLeft: "calc(50% + 30px)",
    border: "none",
  },
  "& #bottom-moon": {
    backgroundColor: "white",
  },
  "& .loading-text": {
    // marginLeft: "-30px",
    marginTop: "70px",
    textAlign: "center",
    width: "100px",
    fontSize: "12px",
  },
  variants: {
    loading: {
      true: {
        "& > *": {},
      },
      false: {},
    },
    noText: {
      true: {
        "& .loading-text": {
          opacity: 0,
        },
      },
    },
  },
});
