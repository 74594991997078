import { useEffect, useState, useContext } from "react";
import {
  getAvailabilityByUuid,
  getMeetingAvailability,
  Service,
} from "~/api/calendarApi";
import { createSession } from "~/api/sessionApi";
import { Button } from "~/ui/components/Button";
import { styled } from "~/ui/style/stitches.config";
import {
  AvailabilityRequest,
  yyyyMMddToDate,
  ServiceAvailability,
} from "~/utils/datetime";
import "react-day-picker/dist/style.css";
import { MobileOnlyBr } from "../DeckMarketplace";
import { TarotPageSurvey } from "~/ui/menus/Survey";
import { UserProfile } from "~/api/userApi";
import { AuthContext } from "~/utils/useAuth";
import { getIsUserBookable } from "~/api/bookableChecks";
import { ServiceSelectorPage } from "./ServiceSelectorPage";
import { BookingButtonBar } from "./BookingButtonBar";
import { DatePickerPage } from "./DatePickerPage";
import { ServiceInfoPage } from "./ServiceInfoPage";
import { PaymentPage } from "./PaymentPage";
import { GuidelinesConfirmPage } from "./GuidelinesConfirmPage";
import { BookingSignInPage } from "./BookingSignInPage";
import {
  LoadingIndicator,
  SimpleLoadingIndicator,
} from "~/pages/loading/LoadingPage";
import { Message } from "~/ui/components/Message";
import { HelpButton } from "~/ui/components/HelpButton";
import { StyledVideoOverlay } from "../ProDirectory";
import { mp4Url } from "~/utils/imageurl";
import { useLocation } from "wouter";
import { trackEvent } from "~/api/analyticsApi";
import { getLatestQuestion } from "~/api/questionsApi";

export enum BookingPage {
  ServiceSelector,
  DatePicker,
  ServiceInfo,
  SignIn,
  GuidelinesConfirm,
  Payment,
  BookWithSelf,
}

const BOOKING_MODULE_ID = "booking-module-overlay";
export const isBookingOverlayVisible = () => {
  const bookingOverlay = document.getElementById(BOOKING_MODULE_ID);
  if (!bookingOverlay) return false;
  return bookingOverlay.style.display === "flex";
};

const VIDEO_MODAL_ID = "video-modal";
export const setVideoModalVisible = (visible: boolean) => {
  const videoModal = document.getElementById(VIDEO_MODAL_ID);
  if (!videoModal) return;
  // reset video to beginning
  const video = videoModal.querySelector("video");

  if (video) {
    if (visible) {
      video.currentTime = 0;
      video.play();
    } else {
      video.pause();
    }
  }

  if (visible) videoModal.style.display = "flex";
  else videoModal.style.display = "none";
};

export const setBookingOverlayVisible = (visible: boolean) => {
  const bookingOverlay = document.getElementById(BOOKING_MODULE_ID);
  if (!bookingOverlay) {
    const profilePage = document.getElementById("profile-page");
    if (!profilePage) return;
    profilePage.scrollTop = profilePage.scrollHeight;
    return;
  }
  if (visible) bookingOverlay.style.display = "flex";
  else bookingOverlay.style.display = "none";
};

const HomepageMidContent = () => {
  const [location, setLocation] = useLocation();

  return (
    <StyledHomepageMidContent>
      <div className="heading">Whatever is going on, our readers got you.</div>

      <div className="insights">
        LIFE TRANSITIONS · DECISION-MAKING · GETTING UNSTUCK · RELATIONSHIPS ·
        WORK · CURIOSITY · AND MORE
      </div>
      <Button
        style={{ width: "180px", alignSelf: "center" }}
        darkHover
        onClick={() => {
          setLocation("/book");
          setVideoModalVisible(false);
        }}
      >
        Browse all pros
      </Button>
    </StyledHomepageMidContent>
  );
};

const StyledHomepageMidContent = styled("div", {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  width: "80%",
  "& .heading": {
    fontFamily: "KeplerLightCondensed",
    fontSize: "26px",
    fontWeight: "500",
    marginTop: "16px",
    marginBottom: "6px",
  },

  "& .insights": {
    fontSize: "14px",
    width: "90%",
    marginBottom: "16px",
    fontWeight: "300",
    textTransform: "lowercase",
    textAlign: "center",
  },
});
export const VideoModal = ({
  pro,
  isBookable,
  videoUrl,
  buttonCopy,
  titleCopy,
  callback,
  secondCallback,
  subtitleCopy,
  secondButtonCopy,
  minPrice,
  isHomepageModal,
}: {
  pro?: UserProfile;
  isBookable?: boolean;
  videoUrl?: string;
  buttonCopy?: string;
  titleCopy?: string;
  callback?: Function;
  secondCallback?: Function;
  subtitleCopy?: string;
  secondButtonCopy?: string;
  minPrice?: number | undefined;
  isHomepageModal?: boolean;
}) => {
  return (
    <StyledVideoOverlay
      style={{ display: "none", backgroundColor: "rgba(0, 0, 0, 0.4)" }}
      id={VIDEO_MODAL_ID}
      onClick={(e) => {
        e.stopPropagation();
        setVideoModalVisible(false);
      }}
    >
      <div
        className="pro-container"
        style={{ width: "500px" }}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          className="x-button"
          onClick={() => setVideoModalVisible(false)}
          src="/images/exit.svg"
        />
        <div className="video-container">
          <video
            loop
            playsInline
            src={pro ? "/images/pro-videos/" + pro.username + ".mp4" : videoUrl}
          />
          <div className="video-loading" />
        </div>
        {isHomepageModal ? (
          <HomepageMidContent />
        ) : (
          <div className="text-box">
            <div className="name">{pro ? pro.name : titleCopy}</div>
            {!titleCopy && (
              <div className="tagline" style={{ paddingBottom: "5px" }}>
                Tarot Professional
              </div>
            )}

            {subtitleCopy && <div className="sub-tagline">{subtitleCopy}</div>}

            {(isBookable || buttonCopy) && (
              <Button
                style={{
                  marginTop: "8px",
                  width: "160px",
                  borderRadius: "16px",
                }}
                onClick={() => {
                  if (isBookable) {
                    setVideoModalVisible(false);
                    setBookingOverlayVisible(true);
                  } else {
                    if (callback) callback();
                    setVideoModalVisible(false);
                    if (pro) {
                      trackEvent("/profile/watched_video", {
                        username: pro.username,
                      });
                    }
                  }
                }}
              >
                {isBookable ? "See availability" : buttonCopy}
              </Button>
            )}
            {secondButtonCopy && (
              <Button
                darkHover
                style={{
                  marginTop: "6px",
                  // marginBottom: "12px",
                  borderRadius: "16px",
                  width: "160px",
                }}
                onClick={() => {
                  if (secondCallback) secondCallback();
                  setVideoModalVisible(false);
                }}
              >
                {secondButtonCopy}
              </Button>
            )}
          </div>
        )}
      </div>
    </StyledVideoOverlay>
  );
};

export const BookingOverlay = ({
  setIsAvailabilityVisible,
  page,
  setPage,
  offeredServices,
  accentColor,
  pro,
}: {
  setIsAvailabilityVisible: Function;
  page: BookingPage;
  setPage: Function;
  offeredServices: Service[];
  accentColor: string;
  pro: UserProfile;
}) => {
  const [selectedService, setSelectedService] = useState<Service>();
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { user, updateUser } = useContext(AuthContext);

  const [timeSlot, setTimeSlot] = useState<string>();
  const [timeSlotTimestamp, setTimeSlotTimestamp] = useState("");

  const [timezone, setTimezone] = useState(browserTimezone);

  const [meetingAvailability, setMeetingAvailability] =
    useState<ServiceAvailability>();

  const [selected, setSelected] = useState<Date>();

  const [firstAvailableDateObj, setFirstAvailableDateObj] = useState<Date>();

  const [sessionContext, setSessionContext] = useState("");

  const [isLoadingAvailability, setIsLoadingAvailability] = useState(true);

  useEffect(() => {
    (async () => {
      if (!pro || !pro.uuid) {
        return;
      }

      // get meeting availability from the backend
      const a = await getMeetingAvailability(pro.uuid, timezone);
      setMeetingAvailability(a);
      setIsLoadingAvailability(false);

      if (Object.keys(a.timeSlots).length > 0)
        setFirstAvailableDateObj(yyyyMMddToDate(Object.keys(a.timeSlots)[0]));
    })();
  }, [user?.uuid, timezone]);

  useEffect(() => {
    setSelected(firstAvailableDateObj);
  }, [firstAvailableDateObj]);

  useEffect(() => {
    if (page === BookingPage.ServiceSelector)
      setSelected(firstAvailableDateObj);
  }, [page]);

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const useQuestion = urlParams.get("useQuestion");
      if (useQuestion === "true" && user) {
        const question = await getLatestQuestion(user.uuid);
        if (question) setSessionContext(question.question);
      }
    })();
  }, []);

  const handleTimezoneUpdate = (timezone: string) => {
    setTimezone(timezone);
    if (user?.uuid && user.timeZone !== timezone) {
      updateUser({
        timeZone: timezone,
      });
    }
  };

  return (
    <>
      <div
        id={BOOKING_MODULE_ID}
        className="availability-window"
        style={{ display: "none" }}
        onClick={(e) => {
          if (
            (e.target as HTMLElement).classList.contains("availability-window")
          ) {
            setIsAvailabilityVisible(false);
          }
        }}
      >
        <HelpButton
          isOnProPage={true}
          isOnBillingPage={true}
          text={"?"}
        ></HelpButton>

        <div className="availability-window-content">
          <BookingButtonBar
            page={page}
            setPage={setPage}
            skipTimeSelect={
              selectedService?.duration && selectedService?.duration < 0
                ? true
                : false
            }
            setIsAvailabilityVisible={setIsAvailabilityVisible}
          />
          {page === BookingPage.ServiceSelector && (
            <ServiceSelectorPage
              onClick={(s: Service) => {
                setSelectedService(s);
                trackEvent("/profile/select_service", {
                  username: pro.username,
                });
                if (s.duration < 0) {
                  console.log("duration <0");
                  setPage(BookingPage.ServiceInfo);
                } else {
                  setPage(BookingPage.DatePicker);
                }
              }}
              offeredServices={offeredServices}
              accentColor={accentColor}
            />
          )}
          {page === BookingPage.DatePicker && (
            <DatePickerPage
              selectedService={selectedService}
              name={pro.name}
              selected={selected}
              setSelected={setSelected}
              timezone={timezone}
              setTimezone={handleTimezoneUpdate}
              onClick={() => {
                if (!user?.timeZone) {
                  updateUser({
                    timeZone: timezone,
                  });
                }
                setPage(BookingPage.ServiceInfo);
                trackEvent("/profile/select_time", {
                  username: pro.username,
                });
              }}
              setTimeSlot={setTimeSlot}
              setTimeSlotTimestamp={setTimeSlotTimestamp}
              meetingAvailability={meetingAvailability}
            />
          )}
          {page === BookingPage.ServiceInfo && (
            <ServiceInfoPage
              selectedService={selectedService}
              selected={selected}
              timeSlot={timeSlot}
              timezone={timezone}
              name={pro.name}
              sessionContext={sessionContext}
              setSessionContext={setSessionContext}
              onClick={() => {
                if (!user) {
                  trackEvent("/profile/sign_in_up/viewed");
                  setPage(BookingPage.SignIn);
                } else if (user?.uuid === pro.uuid) {
                  setPage(BookingPage.BookWithSelf);
                } else {
                  setPage(BookingPage.GuidelinesConfirm);
                }
                trackEvent("/profile/create_note", {
                  username: pro.username,
                });
              }}
            />
          )}
          {page === BookingPage.GuidelinesConfirm && (
            <GuidelinesConfirmPage
              isLoggedIn={!!user}
              onConfirm={() => {
                setPage(BookingPage.Payment);
                if (!user?.timeZone) {
                  updateUser({
                    timeZone: timezone,
                  });
                }
                trackEvent("/profile/agree_guidelines", {
                  username: pro.username,
                });
              }}
            />
          )}
          {page === BookingPage.Payment && (
            <PaymentPage
              selectedService={selectedService}
              sessionStartTime={timeSlotTimestamp}
              pro={pro}
              useremail={user?.email ?? ""}
              userUuid={user?.uuid ?? ""}
              stripeConnectId={user?.proDetails?.stripeConnectId ?? ""}
              accentColor={accentColor}
              onSuccess={(paymentIntentId: string) => {
                // create the session booking request
                if (!user || !selectedService || !selected) return;
                createSession({
                  userIds: [user.uuid],
                  ownerId: pro.uuid,
                  title: selectedService.name,
                  sessionUserContext: [
                    {
                      userId: user.uuid,
                      context: sessionContext,
                    },
                  ],
                  cost: selectedService.price,
                  startDate: timeSlotTimestamp,
                  serviceId: selectedService.id,
                  paymentIntentId,
                }).then((session) => {
                  console.log("session created", session);
                  // once session is created, load it in browser
                  window.location.href = `/session/${session.id}`;
                });
                trackEvent("/profile/purchased_session", {
                  username: pro.username,
                });
              }}
            />
          )}
          {page === BookingPage.BookWithSelf && (
            <div
              className="inset-page"
              style={{ fontSize: "13px", textAlign: "left" }}
            >
              You can’t book a session with yourself! This is just a preview of
              what guests see when they’re booking with you.
            </div>
          )}
          {page === BookingPage.SignIn && (
            <BookingSignInPage
              price={selectedService?.price}
              hostUserName={pro.username}
              onSubmitSignIn={() => {
                trackEvent("/profile/sign_in_up/continued");
                if (!user?.timeZone) {
                  updateUser({
                    timeZone: timezone,
                  });
                }
                setPage(BookingPage.GuidelinesConfirm);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export const BookingModule = ({
  pro,
  accentColor,
  minPrice,
}: {
  pro: UserProfile;
  accentColor: { color: string; theme: string };
  minPrice: number | undefined;
}) => {
  const [isAvailabilityVisible, setIsAvailabilityVisible] = useState(false);
  const [page, setPage] = useState<BookingPage>(BookingPage.ServiceSelector);
  const [availabilityData, setAvailabilityData] =
    useState<AvailabilityRequest>();
  const [isProBookable, setIsProBookable] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await getAvailabilityByUuid(pro.uuid);
      setAvailabilityData(data);
      const isBookable = await getIsUserBookable(pro);
      setIsProBookable(isBookable);
    })();
  }, [pro.uuid]);

  useEffect(() => {
    setPage(BookingPage.ServiceSelector);
  }, [isAvailabilityVisible]);

  const offeredServices = availabilityData?.services
    ? availabilityData.services.filter((s) => s.offered)
    : [];

  return (
    <StyledBookingModule
      css={{
        $$accentColor: accentColor.color,
        $$accentColorOpacity: `${accentColor.color}55`,
      }}
    >
      <Message />
      {pro.proDetails?.hasVideo ? (
        <VideoModal pro={pro} isBookable={isProBookable} minPrice={minPrice} />
      ) : (
        <></>
      )}
      {isProBookable ? (
        <BookingOverlay
          setIsAvailabilityVisible={setBookingOverlayVisible}
          page={page}
          setPage={setPage}
          offeredServices={offeredServices}
          accentColor={accentColor.color}
          pro={pro}
        />
      ) : (
        <></>
      )}
    </StyledBookingModule>
  );
};

export const BookingModuleButton = ({
  pro,
  accentColor,
  minPrice,
}: {
  pro: UserProfile;
  accentColor: { color: string; theme: string };
  minPrice: number | undefined;
}) => {
  const [isProBookable, setIsProBookable] = useState(false);
  const [isLoadingBookability, setIsLoadingBookability] = useState(true);
  const [hasVideo, setHasVideo] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      const hasVideo = pro.proDetails?.hasVideo ? true : false;
      setHasVideo(hasVideo);
    })();
  }, [pro.username]);

  useEffect(() => {
    (async () => {
      const isBookable = await getIsUserBookable(pro);
      setIsProBookable(isBookable);
      setIsLoadingBookability(false);
    })();
  }, [pro.uuid]);

  return isLoadingBookability ? (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <SimpleLoadingIndicator></SimpleLoadingIndicator>
    </div>
  ) : !isProBookable ? (
    <div
      style={{
        height: "100%",
      }}
    >
      <TarotPageSurvey
        emailToNotify={user?.email}
        usernameToNotify={user?.username}
        tarotProUsername={pro.username}
        tarotProEmail={pro.email}
      />
    </div>
  ) : (
    <>
      <div
        className="booking-module-header booking-module-header-display"
        style={{
          fontFamily: "KeplerLightCondensed",
          fontSize: "27px",
          color: "black",
          width: "70%",
          textAlign: "center",
        }}
      >
        Tarot offerings <MobileOnlyBr bigger />
        <i>now available!</i>
      </div>
      {minPrice && (
        <PriceTagline
          minPrice={minPrice}
          style={{ marginTop: "6px", marginBottom: "12px" }}
        />
      )}

      <div className="booking-window">
        <Button
          Theme={"dark"}
          mobileSz16
          className="book-me-button"
          style={{
            border: "none",
            width: "120px",
            height: "48px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={(e) => {
            setBookingOverlayVisible(true);
            e.stopPropagation();
            e.preventDefault();
            trackEvent("/profile/open_book_popup", {
              username: pro.username,
            });
          }}
        >
          Book me ✨
        </Button>
        {hasVideo && (
          <div
            className="button-subheader"
            style={{
              fontSize: "13px",
              cursor: "pointer",
              textDecoration: "underline",
              fontWeight: "500",
              marginTop: "14px",
              color: "black",
            }}
            onClick={() => {
              setVideoModalVisible(true);
              trackEvent("/profile/watched_video", {
                username: pro.username,
              });
            }}
          >
            Watch intro
          </div>
        )}
      </div>
    </>
  );
};

export const PriceTagline = ({
  minPrice,
  style,
}: {
  minPrice: number;
  style?: any;
}) => {
  return (
    <div
      style={{
        fontSize: "12px",
        color: "black",
        fontWeight: "500",
        ...style,
      }}
    >
      {`$${minPrice}+ / `}
      <span
        style={{
          fontSize: "11px",
          textTransform: "uppercase",
          fontWeight: "200",
        }}
      >
        session
      </span>
    </div>
  );
};

const StyledBookingModule = styled("div", {
  width: "100%",
  display: "flex",
  color: "black",

  "& .booking-window": {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  ".availability-window": {
    position: "fixed",
    zIndex: "99999999",
    left: 0,
    right: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",

    "& .availability-window-content": {
      "& .btn": {
        width: "30px",
        height: "30px",
        cursor: "pointer",
        position: "absolute",
        bottom: "0",
        top: "2px",
        margin: "auto",
        transition: "0.2s all",
        "&:hover": {
          opacity: 0.5,
        },
      },
      "& .button-bar-container": {
        width: "100%",
        position: "sticky",
        top: "0px",
        zIndex: 9999999999,
      },
      "& .button-bar": {
        width: "calc(100% + 16px)",
        marginLeft: "-8px",
        backgroundColor: "$wash",
        height: "64px",
        position: "relative",
        "& .x": {
          right: "0",
        },
        "& .back": {
          left: "0",
        },
      },

      width: "500px",
      position: "relative",
      maxHeight: "85%",
      backgroundColor: "$wash",
      borderRadius: "33px",
      paddingLeft: "32px",
      paddingRight: "24px",
      "& .inset-page": {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
      paddingBottom: "52px",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        webkitAppearance: "none",
        width: "8px",
        marginLeft: "1px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "20px",
        backgroundColor: "rgba(0, 0, 0, .5)",
        border: "1px solid $wash",
      },
      "&::-webkit-scrollbar-track": {
        overflow: "hidden",
        marginTop: "64px",
        marginBottom: "30px",
        width: "8px",
      },
      scrollMarginTop: "64px",
      border: "1px solid black",
      boxShadow: "0px 3px 0px 0px #000",

      "& .offering": {
        display: "flex",
        backgroundColor: "white",
        borderRadius: "21px",
        border: "1px solid black",
        marginBottom: "16px",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "28px 32px",
        "& .name": {
          fontSize: "27px !important",
          fontFamily: "KeplerLightCondensed",
          "@mobile": {
            fontSize: "29px !important",
          },
        },
        "& .line-container": {
          display: "flex",
          alignItems: "center",
          marginTop: "4px",
          "& .price": {
            fontSize: "18px",
          },
          "& .duration": {
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            marginLeft: "10px",
            backgroundColor: "$gray100",
            padding: "2px 7px 2px 5px",
            borderRadius: "6px",
            "& img": {
              width: "20px",
              height: "20px",
              marginRight: "3px",
            },
            "@mobile": {
              fontSize: "13px",
            },
          },
        },
        "& .description": {
          fontSize: "12px",
          textAlign: "left",
          marginTop: "9px",
          "@mobile": {
            fontSize: "13px",
          },
        },
      },

      "& .service-info": {
        fontSize: "12px !important",
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        marginTop: "0px",
        "&.guidelines": {
          fontSize: "13px",
          textAlign: "left",
          maxWidth: "94%",
          "@mobile": {
            fontSize: "14px !important",
          },
        },

        "& .name": {
          fontFamily: "WorkSans",
          fontSize: "12px !important",
        },
        "& .date": {
          // fontWeight: "500",
          "@mobile": {
            fontSize: "14px !important",
          },
        },
        "& .line": {
          display: "flex",
          alignItems: "center",
          marginBottom: "8px",
          "@mobile": {
            fontSize: "14px !important",
          },
          "& img": {
            marginRight: "6px",
            width: "20px",
            height: "20px",
          },
        },
      },
      "& .calendar": {
        marginTop: "16px",
        width: "100%",
        color: "black",
        backgroundColor: "white",
        borderRadius: "28px",
        border: "1px solid black",
        boxShadow: "0px 3px 0px 0px #000",

        "& .rdp-cell, & .rdp-caption, & .rdp-head": {
          color: "black",
        },
        "& .rdp-caption": {
          position: "relative",
        },
        "& .rdp-caption_label": {
          position: "absolute",
          width: "100%",
          textAlign: "center",
          padding: 0,
          display: "block",
          zIndex: 0,
          fontFamily: "WorkSans",
          fontSize: "15px",
          fontWeight: "500",
        },
        "& .rdp-nav": {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        },

        "& .rdp-day_today": {
          fontWeight: "normal",
          position: "relative",
          "&::after": {
            content: "''",
            position: "absolute",
            bottom: "4px",
            left: "0",
            right: "0",
            margin: "auto",
            height: "4px",
            backgroundColor: "black",
            width: "4px",
            borderRadius: "4px",
          },
        },
        "& .rdp-day_selected": {
          backgroundColor: "$$accentColor",
          color: "black",
        },

        "& .rdp-button:hover:not([disabled]):not(.rdp-day_selected)": {
          backgroundColor: "$wash",
        },

        "& table": {
          "& .rdp-button": {
            marginRight: "4px",
            marginBottom: "4px",
          },
          "& .rdp-button:hover:not([disabled]):not(.rdp-day_selected)": {
            backgroundColor: "$$accentColorOpacity",
          },
          "& .rdp-button:not([disabled]):not(.rdp-day_selected)": {
            backgroundColor: "$gray100",
          },
        },
      },
      "& .select-message": {
        fontSize: "14px",
        marginTop: "16px",
      },
      "& .time-slots": {
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .date": {
          fontSize: "15px",
          fontFamily: "WorkSans",
          marginBottom: "12px",
          fontWeight: "500",
          "@mobile": {
            fontSize: "16px",
          },
        },
      },
    },
    "& .strong": {
      fontWeight: "500",
      marginRight: "4px",
    },

    "& .survey": {
      marginTop: "18px",

      "& .question": {
        fontSize: "13px",
        fontWeight: "500",
        textAlign: "left",
        marginBottom: "12px",
        "@mobile": {
          fontSize: "14px",
        },
      },
      "& .text-section": {
        position: "relative",
      },
      "& .character-counter": {
        fontSize: "12px",
        position: "absolute",
        bottom: "8px",
        right: 0,
        "@mobile": {
          fontSize: "13px",
        },
      },
      "& textarea": {
        pointerEvents: "all",
        border: "0.5px solid black !important",
        marginBottom: "20px",
        backgroundColor: "white",
        "@mobile": {
          fontSize: "14px",
        },
      },
    },
    "& .button-subtitle": {
      fontSize: "12px",
      marginTop: "16px",
      "@mobile": {
        fontSize: "13px",
      },
    },

    "& .black-button": {
      fontSize: "13px",
      cursor: "pointer",
      width: "50%",
      color: "white",
      backgroundColor: "black",
      borderRadius: "18px",
      height: "52px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "6px",
      transition: "0.2s all",
      "&:hover": {
        backgroundColor: "$gray800",
      },
      "@mobile": {
        fontSize: "14px",
      },
    },
  },
  "@mobile": {
    "& .availability-window-content": {
      border: "none !important",
      height: "100% !important",
      width: "100% !important",
      borderRadius: "0 !important",
      maxHeight: "100% !important",
      maxWidth: "100% !important",
      "& .disclaimer-info": {
        position: "relative",
        "& img": {
          position: "absolute",
          left: "0px",
        },
        "& .line": {
          display: "inline-block",
          marginLeft: "28px",
          maxWidth: "100%",
          textWrap: "wrap",
        },
      },
      "& .inset-page": {
        padding: "0px !important",
      },
    },
    "& #booking-module-overlay": {
      padding: "0 !important",
    },
  },
});
