import { CheckIcon } from "@radix-ui/react-icons";
import { Button } from "../components/Button";
import {
  useMutateRoomState,
  useMutateStorage,
  useStorage,
} from "../../state/liveblocks.config";
import {
  Deck,
  RoomState,
  freeDeckIds,
  videoDeckIds,
} from "../../types/room/types";
import { localState } from "~/state/state";

import riderWaiteDeck from "../../assets/decks/rider-waite.json";
import marseilleDeck from "../../assets/decks/marseille.json";
import mushroomDeck from "../../assets/decks/mushroom.json";
import gothmancyDeck from "../../assets/decks/gothmancy.json";
import viatorDeck from "../../assets/decks/viator-somniorum.json";
import blackDeck from "../../assets/decks/black.json";
import eldritchDeck from "../../assets/decks/eldritch.json";
import iChingDeck from "../../assets/decks/i-ching.json";
import soraDeck from "../../assets/decks/sora.json";
import summerOfBibliomancyDeck from "../../assets/decks/summer-of-bibliomancy.json";
import mixedSignalsDeck from "../../assets/decks/mixed-signals.json";

import { StyledLeftListItem, StyledLeftMenu, ConfirmMenu } from "./StyledMenus";

import { useEffect, useState } from "react";
import { LiveList } from "@liveblocks/client";
import { getUserByUsername } from "~/api/userApi";
import { styled } from "~/ui/style/stitches.config";
import { imageUrl } from "~/utils/imageurl";
import { deckNameToDeckPageId } from "~/pages/marketplace/DeckMarketplace";
import { allBackgrounds } from "./BackgroundMenu";
import { trackEvent } from "~/api/analyticsApi";

export const allDecks: Deck[] = [
  riderWaiteDeck as unknown as Deck,
  marseilleDeck as unknown as Deck,
  mushroomDeck as unknown as Deck,
  gothmancyDeck as unknown as Deck,
  viatorDeck as unknown as Deck,
  blackDeck as unknown as Deck,
  eldritchDeck as unknown as Deck,
  soraDeck as unknown as Deck,
  summerOfBibliomancyDeck as unknown as Deck,
  mixedSignalsDeck as unknown as Deck,
];

export function DeckMenu({
  isOpen,
  setIsOpen,
  userDecks,
}: {
  isOpen: boolean;
  setIsOpen: Function;
  userDecks: string[];
}) {
  const updateStorage = useMutateStorage();
  const theme = useStorage((root) => root.theme);
  const currentDeck = useStorage((root) => root.deck);
  const cards = useStorage((root) => root.cards);
  const state = useStorage((root) => root.state);
  const [showDeckMenu, setShowDeckMenu] = useState(false);
  const [showConfirmMenu, setShowConfirmMenu] = useState(false);
  const [selectedDeck, setSelectedDeck] = useState(currentDeck);

  const spread = useStorage((root) => root.spread);

  allDecks.forEach((deck) => {
    deck.cards.forEach((card) => {
      card.filePath = deck.basePath + "/" + card.artwork;
    });
  });

  useEffect(() => {
    if (!isOpen) {
      setShowDeckMenu(false);
      setShowConfirmMenu(false);
    } else {
      trackEvent("/room/open_deck_menu");
      setShowDeckMenu(true);
      setShowConfirmMenu(false);
    }
  }, [isOpen]);

  const deckSelectMenu = (
    <StyledLeftMenu id={"shuffle-deck"} outerDeck={true} Theme={theme}>
      <Button
        Theme={theme}
        role="menuitem"
        className="arrow-button"
        style={{
          padding: "2px 6px",
          position: "absolute",
          right: "10px",
          top: "8px",
          transform: "rotate(-90deg)",
        }}
        transparent
        onClick={() => {
          setShowDeckMenu(false);
          setShowConfirmMenu(false);
          setIsOpen(false);
        }}
      >
        &larr;
      </Button>

      {showConfirmMenu ? (
        <ConfirmMenu
          theme={theme}
          padding={16}
          onConfirm={() => {
            localState.lastViewedCard = null;
            let newSpread = { ...spread };
            newSpread.slots.forEach((slot) => {
              slot.cards = null;
            });
            // Remove cards from local active spread
            localState.activeSpread.slots.forEach((slot) => {
              slot.cards = null;
            });
            updateStorage((storage) => {
              storage.set("cards", new LiveList([]));
              storage.set("spread", newSpread);

              storage.set("deck", selectedDeck!);

              if (state === RoomState.Draw)
                storage.set("state", RoomState.Shuffle);
            });
            setShowConfirmMenu(false);
            setIsOpen(false);
          }}
          messageText={
            "This will clear your current cards from the table. Are you sure?"
          }
          confirmButtonText={"Continue"}
        />
      ) : (
        <>
          <StyledLeftMenu innerDeck={true} Theme={theme}>
            <div className="section-header">Your decks</div>
            {allDecks
              .filter(
                (deck) =>
                  freeDeckIds.includes(deck.id) || userDecks.includes(deck.id)
              )
              .map((deck) => {
                return (
                  <StyledLeftListItem
                    key={"left-list" + deck.name.split(" ").join("")}
                    Theme={theme}
                  >
                    <Button
                      Theme={theme}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "8px 8px",
                        width: "100%",
                        // whiteSpace: "nowrap",
                        fontSize: "12px",
                      }}
                      transparent
                      menuOption
                      onClick={(e) => {
                        setShowDeckMenu(false);
                        trackEvent("/room/choose_deck", {
                          deckname: deck.name,
                        });

                        if (deck.id === currentDeck.id) {
                          setIsOpen(false);
                          return;
                        }
                        if (cards.length > 0) {
                          setShowConfirmMenu(true);
                          setSelectedDeck(deck);
                          e.preventDefault();
                        } else {
                          setShowConfirmMenu(false);
                          setIsOpen(false);
                          updateStorage((storage) => {
                            storage.set("deck", deck);
                            if (videoDeckIds.includes(deck.id)) {
                              storage.set("reversesOn", false);
                              updateStorage((storage) => {
                                storage.set("theme", "dark");
                              });
                            }
                            if (deck.name.toLowerCase().includes("mixed")) {
                              updateStorage((storage) => {
                                storage.set("theme", "dark");
                              });
                            }

                            if (state === RoomState.Draw)
                              storage.set("state", RoomState.Shuffle);
                          });
                        }
                      }}
                    >
                      <img
                        className="thumbnail"
                        src={imageUrl(
                          "/decks/deck-previews/" + deck.preview,
                          200
                        )}
                        style={{
                          position: "absolute",
                          width: "50px",
                          height: "50px",
                          right: "-57px",
                          marginTop: "0",
                          borderRadius: "8px",
                        }}
                      ></img>
                      {deck.name}
                      <CheckIcon
                        style={{
                          flexShrink: 0,
                          right: 0,
                          opacity: deck.id === currentDeck.id ? "1" : "0",
                        }}
                      />
                    </Button>
                  </StyledLeftListItem>
                );
              })}{" "}
          </StyledLeftMenu>
          {allDecks.filter(
            (deck) =>
              !(freeDeckIds.includes(deck.id) || userDecks.includes(deck.id))
          ).length !== 0 && (
            <div
              className="section-header"
              style={{
                fontSize: "10px",
                textAlign: "center",
              }}
            >
              Explore featured decks
            </div>
          )}
          <Button
            className="visit-shop-button"
            style={{
              marginBottom:
                allDecks.filter(
                  (deck) =>
                    !(
                      freeDeckIds.includes(deck.id) ||
                      userDecks.includes(deck.id)
                    )
                ).length !== 0
                  ? 0
                  : "10px",
            }}
            onClick={() => {
              window.open("/decks", "_blank");
            }}
          >
            Visit our shop
          </Button>
          {allDecks
            .filter(
              (deck) =>
                !(freeDeckIds.includes(deck.id) || userDecks.includes(deck.id))
            )
            .map((deck, i) => {
              return (
                <HoverImgContainer
                  key={"hover-img-container-" + deck.name.split(" ").join("")}
                >
                  <StyledLeftListItem
                    Theme={theme}
                    style={{ position: "relative" }}
                  >
                    <Button
                      Theme={theme}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "8px 8px",
                        width: "100%",
                        whiteSpace: "nowrap",
                        fontSize: "12px",
                      }}
                      transparent
                      menuOption
                      onClick={(e) => {
                        // open deck page in new window
                        window.open(
                          "/decks/" + deckNameToDeckPageId(deck.name),
                          "_blank"
                        );
                      }}
                    >
                      <img
                        className="thumbnail"
                        src={imageUrl(
                          "/decks/deck-previews/" + deck.preview,
                          200
                        )}
                        style={{
                          position: "absolute",
                          width: "50px",
                          height: "50px",
                          right: "-68px",
                          marginTop: "0",
                          borderRadius: "8px",
                        }}
                      ></img>
                      {deck.name}
                      <StyledLinkOutIcon
                        className="link-out-icon"
                        src={"/images/room/link-out.svg"}
                      />
                    </Button>
                  </StyledLeftListItem>
                </HoverImgContainer>
              );
            })}
        </>
      )}
    </StyledLeftMenu>
  );

  return (
    <div>
      {isOpen && (showDeckMenu || showConfirmMenu) ? deckSelectMenu : <></>}
    </div>
  );
}

const HoverImgContainer = styled("div", {
  "&:hover": {
    "& .link-out-icon": {
      opacity: "1 !important",
    },
  },
});

const StyledLinkOutIcon = styled("img", {
  flexShrink: 0,
  position: "absolute",
  marginLeft: "4px",
  right: "2px",
  height: "18px",
  opacity: "0 !important",
});
