import { Session, SessionRequestBody, SessionStatus } from "~/types/sessions/types";
import axios from "./axiosConfig.js";

const SESSION_ENDPOINT = "session/";

const getStatusEnumFromString = (status: string): SessionStatus => {
  switch (status) {
    case 'REQUESTED':
      return SessionStatus.REQUESTED;
    case 'CONFIRMED':
      return SessionStatus.CONFIRMED;
    case 'CANCELLED':
      return SessionStatus.CANCELLED;
    case 'COMPLETED':
      return SessionStatus.COMPLETED;
    case 'EXPIRED':
      return SessionStatus.EXPIRED;

  }
  return SessionStatus.ERROR;
}

export const getSessionById = async (meetingId: string, timeZone: string): Promise<Session> => {
  try {
    const response = await axios.get(SESSION_ENDPOINT + meetingId, {params: {timeZone}});
    const s = {...response.data} as Session;
    s.status = getStatusEnumFromString(response.data.status);
    return s;

  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getRecordingUrl = async (sessionId: string): Promise<string> => {
  try {
    const response = await axios.get(SESSION_ENDPOINT + `${sessionId}/recordingUrl`);
    return response.data.recordingUrl as string;
  } catch (error) {
    console.log(error)
    throw error
  }
}


export const getSessionsByAuth = async (timeZone: string, includeAll = false): Promise<Session[]> => {
  try {
    const response = await axios.get(SESSION_ENDPOINT, {params: {timeZone, includeAll}});
    const s = [...response.data] as Session[];
    s.forEach((session) => {
      session.status = getStatusEnumFromString(session.status as unknown as string);
    })
    return s;
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createSession = async (sessionDetails: SessionRequestBody): Promise<Session> => {
  console.log(sessionDetails)
  const startDate = sessionDetails.startDate ? sessionDetails.startDate : new Date().toISOString();
  try {
    const response = await axios.post(SESSION_ENDPOINT, {...sessionDetails, startDate});
    const s = response.data as Session;
    s.status = getStatusEnumFromString(response.data.status as unknown as string);
    return s;
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const confirmSession = async (sessionId: string): Promise<Session> => {
  try {
    const response = await axios.put(SESSION_ENDPOINT + `${sessionId}/confirm`);
    console.log("session confirmed")
    const s = response.data as Session;
    s.status = getStatusEnumFromString(response.data.status as unknown as string);
    return s;
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const cancelSession = async (sessionId: string): Promise<Session> => {
  try {
    const response = await axios.put(SESSION_ENDPOINT + `${sessionId}/cancel`);
    const s = response.data as Session;
    s.status = getStatusEnumFromString(response.data.status as unknown as string);
    return s;
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const declineSession = async (sessionId: string): Promise<Session> => {
  try {
    const response = await axios.put(SESSION_ENDPOINT + `${sessionId}/decline`);
    const s = response.data as Session;
    s.status = getStatusEnumFromString(response.data.status as unknown as string);
    return s;
  } catch (error) {
    console.log(error)
    throw error
  }
}