import { styled } from "~/ui/style/stitches.config";
import { CheckIcon, ArrowRightIcon } from "@radix-ui/react-icons";
import { useEffect, useState, useContext } from "react";
import {
  ChecklistSection,
  ChecklistState,
  getChecklistState,
  getIsUserBookable,
} from "~/api/bookableChecks";
import { AuthContext } from "~/utils/useAuth";
import { SimpleLoadingIndicator } from "~/pages/loading/LoadingPage";
import { motion } from "framer-motion";
import { GUIDELINES_LAST_UPDATED } from "~/assets/guidelines";
import { GuidelinesScrollContainer } from "../components/GuidelinesScrollContainer";
import { getCalendarAuthUrl } from "~/api/calendarApi";
import { MOONLIGHT_PAGE } from "~/pages/Home";
import { sendFriendlyToast } from "~/utils/handleToasts";

export const ChecklistItem = ({
  text,
  isChecked,
  isNext,
  onClick,
}: {
  text: string;
  isChecked: boolean;
  isNext?: boolean;
  onClick: Function;
}) => {
  return (
    <StyledChecklistItem
      isChecked={isChecked}
      isNext={isNext}
      onClick={() => onClick()}
    >
      {text}
      {isChecked ? (
        <img src="/images/more-menu/check.svg" className="icon" />
      ) : (
        <img src="/images/more-menu/arrow.svg" className="icon" />
      )}
    </StyledChecklistItem>
  );
};

const StyledChecklistItem = styled("div", {
  fontSize: "12px",
  width: "312px",
  padding: "10px 12px",
  marginBottom: "2px",
  borderRadius: "11px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "$gray100",
  },
  "& .icon": {
    width: "36px",
    height: "24px",
    padding: "2px",
    borderRadius: "9px",
  },
  variants: {
    isChecked: {
      true: {
        color: "$gray400",
        "& .icon": {},
      },
      false: {},
    },
    isNext: {
      true: {
        backgroundColor: "$gray100",
        "& .icon": {
          filter: "invert(1)",
          backgroundColor: "white",
        },
      },
      false: {},
    },
    hasBeenHovered: {
      true: {
        backgroundColor: "unset",
        "&:hover": {
          backgroundColor: "$gray100",
        },
      },
    },
  },
});

export const ProChecklist = ({
  setIsPayoutsOverlayOpen,
  checklistData,
}: {
  setIsPayoutsOverlayOpen: Function;
  checklistData: ChecklistState | null;
}) => {
  const { user } = useContext(AuthContext);
  const [firstUncheckedItem, setFirstUncheckedItem] =
    useState<ChecklistSection>();
  const [firstUncheckedItemIdx, setFirstUncheckedItemIdx] = useState<number>();
  const [showingGuidelines, setShowingGuidelines] = useState(false);
  const [calendarAuthUrl, setCalendarAuthUrl] = useState("");

  useEffect(() => {
    const getUrl = async () => {
      if (user?.uuid) {
        const url = await getCalendarAuthUrl(user.uuid);
        setCalendarAuthUrl(url);
      }
    };
    getUrl();
  }, []);

  useEffect(() => {
    if (!checklistData) return;

    const firstUncheckedItemKey = Object.keys(checklistData).find(
      (key) => !checklistData[key as keyof ChecklistState].checked
    );
    setFirstUncheckedItem(
      checklistData[firstUncheckedItemKey as keyof ChecklistState]
    );

    const firstUncheckedItemIdx = Object.keys(checklistData).indexOf(
      firstUncheckedItemKey as string
    );
    setFirstUncheckedItemIdx(firstUncheckedItemIdx);
  }, [checklistData]);

  const checklistOnclicks = {
    guidelines: () => {
      setShowingGuidelines(true);
    },
    profile: () => {
      // open link in a new tab
      window.open("/profile/" + user?.username);
    },
    calendar: () => {
      location.href = calendarAuthUrl;
    },
    services: () => {
      window.location.href = "/availability";
    },
    stripe: () => {
      setIsPayoutsOverlayOpen(true);
    },
  };

  return (
    <StyledProChecklist
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {showingGuidelines && (
        <GuidelinesOverlay setShowingGuidelines={setShowingGuidelines} />
      )}
      {checklistData ? (
        Object.keys(checklistData).map((key, idx) => {
          return (
            <ChecklistItem
              key={idx}
              text={
                idx + 1 + ". " + checklistData[key as keyof ChecklistState].desc
              }
              isChecked={checklistData[key as keyof ChecklistState].checked}
              isNext={idx === firstUncheckedItemIdx}
              onClick={checklistOnclicks[key as keyof typeof checklistOnclicks]}
            />
          );
        })
      ) : (
        <div
          style={{
            height: "90px",
            width: "100%",
            opacity: "0.5",
          }}
        >
          <SimpleLoadingIndicator />
        </div>
      )}
    </StyledProChecklist>
  );
};

const StyledProChecklist = styled("div", {
  marginTop: "16px",
});

export const ProChecklistSection = ({
  setIsPayoutsOverlayOpen,
  activePage,
}: {
  setIsPayoutsOverlayOpen: Function;
  activePage: MOONLIGHT_PAGE;
}) => {
  const { user } = useContext(AuthContext);
  const [checklistData, setChecklistData] = useState<ChecklistState | null>(
    null
  );

  const [shouldDisplayChecklist, setShouldDisplayChecklist] = useState(false);

  useEffect(() => {
    (async () => {
      if (user?.isProfessional && user?.proDetails?.approvedForServiceListing) {
        const data = await getChecklistState(user);
        setChecklistData(data);
        let isAnythingUnchecked = false;
        Object.keys(data).forEach((key) => {
          if (!data[key as keyof ChecklistState].checked) {
            isAnythingUnchecked = true;
          }
        });
        setShouldDisplayChecklist(isAnythingUnchecked);
      }
    })();
  }, [activePage, user]);

  return shouldDisplayChecklist ? (
    <div
      className="homepage-section"
      style={{ marginTop: "80px", marginBottom: "-100px" }}
    >
      <motion.div
        key={"subtitle"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 1 }}
        className="subtitle"
        style={{ zIndex: 1 }}
      >
        Just a few steps before your page is ready.
      </motion.div>
      <ProChecklist
        checklistData={checklistData}
        setIsPayoutsOverlayOpen={setIsPayoutsOverlayOpen}
      />
    </div>
  ) : (
    <></>
  );
};

const GuidelinesOverlay = ({
  setShowingGuidelines,
}: {
  setShowingGuidelines: Function;
}) => {
  const { updateUser } = useContext(AuthContext);
  return (
    <StyledGuidelinesOverlay
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setShowingGuidelines(false);
        }
      }}
    >
      <div className="guidelines-container">
        <div className="top-icons">
          <img
            className="icon"
            src={"/images/exit.svg"}
            onClick={() => setShowingGuidelines(false)}
          ></img>
        </div>
        <div className="desc">
          Please read and agree to our guidelines before participating in
          services.
        </div>
        <GuidelinesScrollContainer
          className="guidelines-scroll-container"
          onClickAgree={() => {
            updateUser({ acceptedTerms: GUIDELINES_LAST_UPDATED }).then(() => {
              sendFriendlyToast(1153);
              setShowingGuidelines(false);
            });
          }}
        />
      </div>
    </StyledGuidelinesOverlay>
  );
};

const StyledGuidelinesOverlay = styled("div", {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: "999999",
  display: "flex",
  justifyContent: "center",
  "& .guidelines-scroll-container": {
    marginTop: 0,
    width: "calc(100% - 100px)",
    marginLeft: "50px",
    height: "430px",
    borderRadius: "30px",
    "@mobile": {
      height: "calc(100% - 180px)",
    },
  },
  "& .guidelines-container": {
    width: "500px",
    marginTop: "100px",
    marginLeft: "88px",
    height: "600px",
    borderRadius: "33px",
    backgroundColor: "$wash",
    "& .desc": {
      marginTop: "30px",
      marginLeft: "50px",
      marginRight: "50px",
      fontSize: "13px",
      marginBottom: "22px",
    },
    "& .top-icons": {
      display: "flex",
      justifyContent: "flex-end",
      "& .icon": {
        width: "30px",
        height: "30px",
        marginTop: "20px",
        marginRight: "20px",
        cursor: "pointer",
      },
    },
  },
  "@mobile": {
    "& .guidelines-container": {
      width: "100%",
      height: "100%",
      marginTop: 0,
      marginLeft: 0,
      borderRadius: 0,
    },
  },
});
