import { useEffect, useRef } from "react";
import { useSnapshot } from "valtio";
import { CARD_SPREAD_SIZE, MOBILE_ZOOM_INDEX_MOD, PAN_BASE, ZOOM_BASE } from "~/utils/consts";
import { withPadding } from "../ui/room/Spread";
import { localState } from "~/state/state";
import { useSelf, useStorage } from "../state/liveblocks.config";
import { addV2d, divV2d, mulV2d, subV2d } from "./math";

export type Vec2d = [number, number];

export function useMousePosition(
  // whether to track mouse position
  // if false, will not call any event handlers
  isTracking: boolean,
  {
    start,
    move,
    stop,
    leave,
  }: {
    // event handlers
    start?: (xy: Vec2d) => void;
    move: (xy: Vec2d, delta: Vec2d, velocity: Vec2d, window: Vec2d) => void;
    stop?: () => void;
    leave?: () => void;
  }
) {
  const spread = useStorage((root) => root.spread);
  const deck = useStorage((root) => root.deck);

  const startPos = useRef<Vec2d>([-Infinity, -Infinity]);
  const current = useRef<Vec2d>([-Infinity, -Infinity]);
  const { pan, zoomIndex, isOnMobile } = useSnapshot(localState);


  // handle startup
  useEffect(() => {
    // if not tracking mouse, do nothing
    if (!isTracking) return;
    // call start handler
    start?.(current.current);
    // set start position to current position
    startPos.current = [...current.current];
  }, [isTracking, start]);

 
  useEffect(() => {
    // Invert zoom scale to match mouse movements to zoom scale
    const zoomIndexMod = isOnMobile ? MOBILE_ZOOM_INDEX_MOD : 0;
    const zoomVector: Vec2d = [
      1 / ZOOM_BASE ** (zoomIndex + zoomIndexMod),
      1 / ZOOM_BASE ** (zoomIndex + zoomIndexMod),
    ];
  
    // takes screen coordinates and projects to spread coordinates
    function screenToSpread(xy: Vec2d): Vec2d {
      
       // projectToCenter
       const centerXY = subV2d(xy, [
        window.innerWidth / 2,
        window.innerHeight / 2,
      ]);
      // scale by zoom
      const scaledXY = mulV2d(centerXY, zoomVector);
      // projectToTopLeft
      const topLeftXY = addV2d(scaledXY, [
        window.innerWidth / 2,
        window.innerHeight / 2,
      ]);

      // add pan
      const pannedXY = subV2d(topLeftXY, mulV2d(pan as Vec2d, [PAN_BASE, PAN_BASE]));
      
      return [
        (withPadding(spread.size)[0] * deck.cardSize[0] - window.innerWidth) /
          2 +
          pannedXY[0],
        (withPadding(spread.size)[1] * deck.cardSize[1] - window.innerHeight) /
          2 +
          pannedXY[1],
      ];
    }

    function handlePointerMove(e: PointerEvent) {
      // project client coordinates to spread coordinates
      const pos = screenToSpread([e.clientX, e.clientY]);

      // calculate distance mouse has moved since last update
      const velocity: Vec2d = [
        pos[0] - current.current[0],
        pos[1] - current.current[1],
      ];

      // update current position
      current.current = pos;

      // if not tracking mouse, do nothing
      if (!isTracking) return;

      // call move handler
      move(
        pos,
        [pos[0] - startPos.current[0], pos[1] - startPos.current[1]],
        velocity,
        [e.clientX, e.clientY]
      );
    }

    function handlePointerUp() {
      // if not tracking mouse, do nothing
      if (!isTracking) return;
      // if there is a stop function, call it on pointer up
      stop?.();
    }
    function handlePointerLeave() {
      // if not tracking mouse, do nothing
      if (!isTracking) return;
      // if there is a leave function, call it on pointer leave
      leave?.();
    }
    window.addEventListener("pointermove", handlePointerMove);
    window.addEventListener("pointerup", handlePointerUp);
    window.addEventListener("pointerleave", handlePointerLeave);
    return () => {
      window.removeEventListener("pointermove", handlePointerMove);
      window.removeEventListener("pointerup", handlePointerUp);
      window.removeEventListener("pointerleave", handlePointerLeave);
    };
  }, [isTracking, move, stop, leave, zoomIndex, pan, isOnMobile, spread.size, deck.cardSize]);


  return { current } as const;
}
