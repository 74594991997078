const translations = {
    '1': 'one',
    '2': 'two',
    '3': 'three',
    '4': 'four',
    '5': 'five',
    '6': 'six',
    '7': 'seven',
    '8': 'eight',
    '9': 'nine',
    '10': 'ten',
    'one': '1',
    'two': '2',
    'three': '3',
    'four': '4',
    'five': '5',
    'six': '6',
    'seven': '7',
    'eight': '8',
    'nine': '9',
    'ten': '10',
    'coins': 'pentacles',
    'coin': 'pentacle',
    'pentacles': 'coins',
    'pentacle': 'coin',
    'heirophant': 'hierophant',
    'preistess': 'priestess',
    "emperess": 'empress',
    'temperence': 'temperance',
    'temprance': 'temperance',
    'temprence': 'temperance'
}

const ignoredWords = ['', 'arcana', 'the', 'for', 'of', 'de', "le", "la"]

export const isCardSearched = (cardName: string, cardSearches: string[], deckTranslations: string[], searchTerm: string) => {
    let splitCardName = cardName.toLowerCase().split(' ').filter((word) => !ignoredWords.includes(word));

    let splitSearch = searchTerm.toLowerCase().split(' ').filter((word) => !ignoredWords.includes(word))

    let allCardSearchTerms = [...splitCardName, ...cardSearches];
    allCardSearchTerms.forEach(word => {
        if (word.charAt(word.length - 1) !== "s") allCardSearchTerms.push(word + "s");
        if (word.charAt(word.length - 1) === "s") allCardSearchTerms.push(word.substring(0, word.length - 1));
    })


    let isCardSearched = true;

    splitSearch.forEach((word) => {
        let translatedWord = null;

        const wordWithS = word + "s";
        const wordWithoutS = (word.charAt(word.length - 1) === "s") ? word.substring(0, word.length - 1) : "";

        //@ts-ignore
        if (translations[word]) translatedWord = translations[word];
        //@ts-ignore
        else if (translations[wordWithS]) translatedWord = translations[wordWithS];
        //@ts-ignore
        else if (translations[wordWithoutS]) translatedWord = translations[wordWithoutS];
        //@ts-ignore
        else if (deckTranslations[word]) translatedWord = deckTranslations[word];
        //@ts-ignore
        else if (deckTranslations[wordWithS]) translatedWord = deckTranslations[wordWithS];
         //@ts-ignore
        else if (deckTranslations[wordWithoutS]) translatedWord = deckTranslations[wordWithoutS];


        if (
            !allCardSearchTerms.includes(word) 
            && !(translatedWord && allCardSearchTerms.includes(translatedWord))
        ){
            isCardSearched = false;
        }
    })

    return isCardSearched;
}