import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "wouter";

import { AxiosError } from "axios";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { StyledForm } from "./SignUp";
import { Button } from "~/ui/components/Button";
import { styled } from "@stitches/react";
import { Message } from "~/ui/components/Message";
import { localState } from "~/state/state";
import { AuthContext } from "~/utils/useAuth";
import { AuthFormState, StyledClickableText } from "~/pages/login/Auth";
import {
  sendServerErrorToastWithFallbackCode,
  sendErrorToast,
} from "~/utils/handleToasts";

type State = {
  email: string;
  captcha: string | null;
  isSubmitting: boolean;
};

const initialState = {
  email: "",
  captcha: "",
  isSubmitting: false,
};

export const ForgotPasswordForm = ({
  setAuthState,
}: {
  setAuthState: Function;
}) => {
  const [data, setData] = useState<State>(initialState);
  const { forgotPassword } = useContext(AuthContext);

  const handleRecaptchaChange = (token: string | null) => {
    setData({
      ...data,
      captcha: token,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    let query = window.location.search;
    let urlQueryParams = new URLSearchParams(query);
    let invalid = urlQueryParams.get("invalid");
    if (invalid) {
      // Invalid frontend validation link
      sendErrorToast(1147);
    }
  }, []);

  const handleForgotPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!data.captcha) {
      // Didn't complete the captcha
      sendErrorToast(1029);
      return;
    }

    setData({
      ...data,
      isSubmitting: true,
    });
    try {
      await forgotPassword(data.email, data.captcha);
    } catch (error) {
      // Error in forgot password flow
      sendServerErrorToastWithFallbackCode(error, 1030);
      setData({
        ...data,
        isSubmitting: false,
      });
    }
  };

  return (
    <>
      <StyledForm className="auth-form-container">
        <Message />
        <img
          src="/images/room/menu-close.png"
          className="back-button"
          onClick={() => setAuthState(AuthFormState.LOGIN)}
        />
        <h3 className="title">Let's reset your password</h3>
        <form onSubmit={handleForgotPassword}>
          <div className="input-container">
            <input
              type="email"
              name="email"
              placeholder="Email address"
              onInvalid={(e) => e.preventDefault()}
              aria-describedby="inputGroupPrepend"
              required
              value={data.email}
              onChange={handleInputChange}
            />
            <p className="error-message">Invalid email. Check for typos</p>
          </div>
          <ReCAPTCHA
            style={{ transform: "scale(0.895)", transformOrigin: "0 0" }}
            onChange={handleRecaptchaChange}
            sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
          />
          <Button type="submit" formButton disabled={data.isSubmitting}>
            Email me a link
          </Button>
        </form>
      </StyledForm>
      <StyledClickableText
        onClick={() => setAuthState(AuthFormState.REGISTER)}
        lowerText
      >
        Sign up instead
      </StyledClickableText>
    </>
  );
};
