import { useState } from "react";
import { styled } from "../style/stitches.config";

export const CopyButton = ({
  id,
  buttonText,
  copiedText,
  copyContent,
  style,
}: {
  buttonText: string;
  copiedText: string;
  copyContent: string;
  style?: Object;
  id?: string;
}) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  return (
    <StyledCopyButton
      id={id}
      style={style}
      onClick={() => {
        navigator.clipboard.writeText(copyContent).then(() => {
          setIsLinkCopied(true);
          setTimeout(() => {
            setIsLinkCopied(false);
          }, 5000);
        });
      }}
    >
      {!isLinkCopied && (
        <img style={{ filter: "invert(1)" }} src="/images/room/copy.svg" />
      )}
      {isLinkCopied ? copiedText : buttonText}
    </StyledCopyButton>
  );
};

const StyledCopyButton = styled("div", {
  display: "flex",
  marginTop: "-3px",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: "$gray200",
  fontSize: "10px",
  borderRadius: "14px",
  width: "100px",
  flexShrink: "0",
  transition: "all 0.2s",
  justifyContent: "center",
  height: "24px",
  position: "relative",
  "&:hover": {
    backgroundColor: "$gray300",
  },
});
