import { AvailabilityRequest, ServiceAvailability } from "~/utils/datetime.js";
import axios from "./axiosConfig.js";
import { Session, SessionRequestBody } from "~/types/sessions/types.js";

const CAL_ENDPOINT = "cal/";

export interface Service {
  id: string;
  name: string;
  description: string;
  price: number;
  duration: number;
  offered: boolean;
}

const getCalendarByUuid = async (uuid : string) : Promise<string> => {
    const response = await axios.get(CAL_ENDPOINT + uuid)
    if (response.status === 200) {
      return JSON.stringify(response.data)
    } else {
      throw new Error(response.statusText)
    }
  }

export interface ServiceResponse {
  id: string
  name: string
  description: string
  duration: number
  order: number
}
const getAllMoonlightServices = async(): Promise<ServiceResponse[]> => {
  const response = await axios.get(CAL_ENDPOINT + "services/")
  if (response.status === 200) {
    return response.data
  }
  else {
    throw new Error(response.statusText)
  }
}

/**
 * @description Get the availability of a pro user for a given service.
 * @param {string} proUserId - pro user's uuid
 * @param {string} timeZone - IANA Timezone
 */
const getMeetingAvailability = async (
  uuid: string,
  timeZone: string,
): Promise<ServiceAvailability> => {
  try {
    const params = {
      timeZone,
    }
    const response = await axios.get(CAL_ENDPOINT + `session/availability/${uuid}`, { params });
    return response.data as ServiceAvailability;
  } catch (error) {
    console.log(error);
    throw error
  }
}

const updateAvailabilityByUuid = async(uuid: string, availabilityData: AvailabilityRequest) => { 
  // axios request with availability data
  console.log(uuid, availabilityData);
  const response = await axios.put(CAL_ENDPOINT + "availability/" +  uuid, availabilityData)
    if (response.status !== 200) {
      throw new Error(response.statusText)
    }
}

const getAvailabilityByUuid = async(uuid: string) : Promise<AvailabilityRequest> => {
  const response = await axios.get(CAL_ENDPOINT + "availability/" + uuid)
  if (response.status === 200) {
    return response.data as AvailabilityRequest
  } else {
    throw new Error(response.statusText)
  }
}

const getCalendarAuthUrl = async (uuid: string): Promise<string> => {
  try {
    const response = await axios.get(CAL_ENDPOINT + `auth/${uuid}/connect`);
    return response.data.authorizationUrl;
  } catch (error) {
    console.log(error);
    throw error
  }
}

// Note: this update should be followed by a useContext(AuthContext).getUser() call
const disconnectCalendar = async (uuid: string): Promise<boolean> => {
  try {
    const response = await axios.put(CAL_ENDPOINT + `auth/${uuid}/disconnect`);
    return response.data.success
  } catch (error) {
    console.log(error);
    throw error
  }
}

interface CalendarAuthResponse {
  authorizationSucceeded: boolean,
  message?: string,
  error?: string,
}
// Note: this update should be followed by a useContext(AuthContext).getUser() call
const calendarAuthorized = async (uuid: string, code: string): Promise<CalendarAuthResponse> => {
  try {
    const response = await axios.post(CAL_ENDPOINT + `auth/${uuid}/callback`, { code })
    return response.data as CalendarAuthResponse
  } catch (error) {
    console.log(error);
    throw error
  }
}

export {
  getCalendarByUuid,
  getAllMoonlightServices,
  getMeetingAvailability,
  updateAvailabilityByUuid,
  getAvailabilityByUuid,
  getCalendarAuthUrl,
  calendarAuthorized,
  disconnectCalendar
}