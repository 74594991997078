import { useEffect, useState } from "react";
import { IANATimezones } from "~/utils/datetime";
import { styled } from "../style/stitches.config";

export const TimezoneMenu = ({
  timezone,
  setTimezone,
  style,
}: {
  timezone: string;
  setTimezone: Function;
  style?: Object;
}) => {
  return (
    <MoonlightDropdownMenu
      selected={timezone}
      setSelected={setTimezone}
      style={style}
      dropdownListContent={IANATimezones}
    ></MoonlightDropdownMenu>
  );
};

export const MoonlightDropdownMenu = ({
  selected,
  setSelected,
  style,
  dropdownListContent,
}: {
  selected: string;
  setSelected: Function;
  style?: Object;
  dropdownListContent: string[];
}) => {
  const [currentSearch, setCurrentSearch] = useState("");

  return (
    <StyledTimezoneMenu
      key="timezone-menu-container"
      id="tz-menu"
      className="menu"
      style={style}
      onClick={(e) => {
        (e.target as HTMLElement).classList.toggle("open");
        (
          (e.target as HTMLElement).children[0].children[0] as HTMLInputElement
        ).focus();
      }}
    >
      {selected}
      <div className="tz-input-container">
        <input
          type="text"
          key="tz-search"
          id="tz-search"
          autoFocus
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            setCurrentSearch(e.target.value.trim());
          }}
        ></input>
      </div>
      <div className="menu">
        <div className="inner-menu">
          {dropdownListContent
            .filter((tz) =>
              tz.toLowerCase().includes(currentSearch.toLowerCase())
            )
            .map((tz) => {
              return (
                <div
                  //@ts-ignore
                  key={"timezone-" + tz}
                  className="menu-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelected(tz);
                    (
                      e.target as HTMLElement
                    ).parentElement!.parentElement!.parentElement!.classList.remove(
                      "open"
                    );
                  }}
                >
                  {tz}
                </div>
              );
            })}
        </div>
      </div>
    </StyledTimezoneMenu>
  );
};

const StyledTimezoneMenu = styled("div", {
  fontSize: "12px",
  "@mobile": {
    fontSize: "13px !important",
  },
  border: "0.5px solid black",
  borderRadius: "18px",
  height: "30px !important",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  width: "230px",
  cursor: "pointer",
  position: "relative",
  justifyContent: "center",
  "& .menu, & .tz-input-container": {
    display: "none",
  },
  "&.open": {
    "& .tz-input-container": {
      display: "block",
      backgroundColor: "white",
      position: "absolute",
      width: "100%",
      zIndex: "99",
      top: "-9px",
      paddingTop: "10px",
      paddingBottom: "0 !important",
      left: 0,
      right: 0,
      borderRadius: "25px 25px 0 0",
    },
    "& input": {
      textAlign: "center",
      width: "230px",
      padding: "8px 16px",
      borderRadius: "25px !important",
      marginBottom: "4px",
      fontSize: "12px !important",
      "@mobile": {
        fontSize: "14px !important",
      },
      outline: "none",
      border: "none",
      height: "30px !important",
      "&:focus": {
        outline: "1px solid black",
        border: "none",
        "&::placeholder": {
          opacity: 0,
        },
      },
      "&::placeholder": {
        fontSize: "11px",
      },
      "&:not(:focus)": {
        outline: "0.5px solid $gray400",
      },
    },
    "& .menu": {
      display: "block",
      position: "absolute",
      zIndex: "10",
      height: "200px",
      overflow: "hidden",
      backgroundColor: "white",
      border: "1px solid black",
      left: "-16px",
      top: "-10px",
      width: "calc(100% + 30px)",
      borderRadius: "25px",
      paddingTop: "48px",
      "& .inner-menu": {
        overflowY: "scroll",
        height: "100%",
      },

      "& .menu-item": {
        fontSize: "10px",
        "@mobile": {
          fontSize: "13px",
        },

        marginTop: "2px",
        marginBottom: "2px",
        padding: "8px 16px",
        "&:hover": {
          backgroundColor: "$gray200",
        },
      },
    },
  },
});
