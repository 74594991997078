import { useEffect } from "react";
import { PRELOAD_TIMEOUT_MS } from "~/utils/consts";

export const ImagePreloader = ({
  filesToLoad,
  setIsLoaded,
}: {
  filesToLoad: string[];
  setIsLoaded: Function;
}) => {
  useEffect(() => {
    const loadedPics = [];
    filesToLoad.forEach((picture) => {
      const img = new Image();
      img.src = picture;
      img.onerror = () => {
        loadedPics.push(picture);
        if (loadedPics.length === filesToLoad.length) {
          setIsLoaded(true);
        }
      };
      img.onload = () => {
        loadedPics.push(picture);
        if (loadedPics.length === filesToLoad.length) {
          setIsLoaded(true);
        }
      };
    });
  }, []);
  useEffect(() => {
    window.setTimeout(() => setIsLoaded(true), PRELOAD_TIMEOUT_MS);
  }, []);
  return <></>;
};
