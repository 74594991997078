import { BookingPage } from "./BookingModule";

export const BookingButtonBar = ({
  page,
  setPage,
  skipTimeSelect,
  setIsAvailabilityVisible,
}: {
  page: number;
  setPage: Function;
  skipTimeSelect: boolean;
  setIsAvailabilityVisible: Function;
}) => {
  return (
    <div className="button-bar-container">
      <div className="button-bar">
        {page > 0 && (
          <img
            className="btn back"
            src="/images/back-button.svg"
            alt="back"
            onClick={() => {
              if (page - 1 === BookingPage.SignIn) {
                setPage(page - 2);
              } else if (page === BookingPage.ServiceInfo && skipTimeSelect) {
                setPage(page - 2);
              } else setPage(page - 1);
            }}
          />
        )}
        <img
          className="btn x"
          src="/images/exit.svg"
          alt="x"
          onClick={() => {
            setIsAvailabilityVisible(false);
          }}
        />
      </div>
    </div>
  );
};
