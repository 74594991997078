import { styled } from "@stitches/react";

export const StyledProfilePic = styled("img", {
  width: "$$size !important",
  aspectRatio: "1 / 1",
  borderRadius: "50%",

  "&:after": {
    content: "",
    width: "$$size",
    aspectRatio: "1 / 1",
    position: "absolute",
    top: "0",
    left: "$$padding",
    bottom: 0,
    margin: "auto",
    borderRadius: "50%",
    backgroundColor: "var(--colors-gray300)",
  },
});

export const StyledArchProfilePic = styled("img", {
  width: "$$size !important",
  aspectRatio: "1 / 1",
  borderRadius: "50% 50% 12px 12px",
  "&:after": {
    content: "",
    width: "$$size",
    height: "$$size",
    position: "absolute",
    top: "0",
    left: "0",
    borderRadius: "50% 50% 12px 12px",
    backgroundColor: "var(--colors-gray300)",
  },
});
