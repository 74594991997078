import { useEffect } from "react";
import { trackEvent } from "~/api/analyticsApi";
import { styled } from "~/ui/style/stitches.config";

const bottomScrollArea = 300;

export const FloatingButton = ({
  color,
  onClick,
}: {
  color: string;
  onClick: Function;
}) => {
  useEffect(() => {
    document.getElementById("profile-page")!.addEventListener("scroll", (e) => {
      if (!document.getElementById("floating-button")) return;
      if (
        document.getElementById("profile-page")!.scrollTop +
          window.innerHeight >
        document.getElementById("profile-page")!.scrollHeight - bottomScrollArea
      ) {
        document.getElementById("floating-button")!.style.opacity = "0";
        document.getElementById("floating-button")!.style.pointerEvents =
          "none";
      } else {
        document.getElementById("floating-button")!.style.opacity = "1";
        document.getElementById("floating-button")!.style.pointerEvents = "all";
      }
    });
  }, []);
  return (
    <StyledFloatingButton
      id="floating-button"
      style={{ backgroundColor: color }}
      onClick={() => {
        trackEvent("/profile/open_book_popup");
        onClick();
      }}
    >
      {"Book now"}
    </StyledFloatingButton>
  );
};

const StyledFloatingButton = styled("div", {
  color: "black",
  position: "fixed",
  bottom: "24px",
  zIndex: "10000",
  width: "180px",
  height: "55px",
  fontWeight: "500",
  borderRadius: "20px",
  display: "flex",
  justifyContent: "center",
  cursor: "pointer",
  alignItems: "center",
  fontSize: "14px",
  transition: "opacity 0.3s ease-in-out",
  border: "1px solid black",
  "&:hover": {
    filter: "brightness(0.95)",
  },
  "@notmobile": {
    display: "none",
  },
});
