import { AuthContext } from "../utils/useAuth.js";
import axios from "./axiosConfig.js";
import { useContext } from "react";
import { getUserByUsername } from "./userApi.js";

const QUESTIONS_ENDPOINT = "questions/";

export const createNewQuestion = async (userId: string, question: string, category?: string) => {
  const response = await axios.post(QUESTIONS_ENDPOINT, {
    user_id: userId,
    question,
    category
  })
}

interface Question {
    id: string;
    user_id: string;
    question: string;
    category?: string;
    timestamp: Date;
}

export const getLatestQuestion = async (userId: string) => {
    const response = await axios.get(QUESTIONS_ENDPOINT + "latest/" + userId);
    if (response.status === 200) {
        return response.data as Question;
    } else {
        throw new Error(response.statusText);
    }
}
