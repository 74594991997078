
export enum SessionStatus {
  REQUESTED,
  CONFIRMED,
  CANCELLED,
  COMPLETED,
  EXPIRED,
  ERROR,
};

export interface SessionUserContext {
  userId: string
  context: string
}

export interface SessionTime {
  timeZone: string // IANA
  timeStamp: string // ISO TimeStamp in their timeZone
  utcTimeStamp: string // ISO TimeStamp in UTC
  startTime: string // 9:30 AM
  endTime: string // 10:00 AM
  duration: number // minutes, e.g. 30
  month: string // January, February, etc.
  weekDay: string // Monday, Tuesday, Wednesday, etc.
  yyyyMMdd: string // yyyyMMdd format
}

export interface Session {
  id: string
  serviceId: string
  title: string
  sessionUserContext: SessionUserContext[]
  cost: number
  roomId: string
  roomName: string
  ownerId: string
  invitees: string[]
  status: SessionStatus
  sessionTime: SessionTime
  duration: number
  recording?: string
}


export interface SessionRequestBody {
  userIds: string[]
  ownerId: string
  title: string
  sessionUserContext: SessionUserContext[]
  cost: number
  startDate: string
  serviceId: string
  paymentIntentId?: string
}