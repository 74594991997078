import { styled } from "@stitches/react";
import { motion } from "framer-motion";
import {
  useMutateStorage,
  useOthers,
  useSelf,
  useStorage,
  useUpdateMyPresence,
} from "../../state/liveblocks.config";
import { useMousePosition, Vec2d } from "../../utils/useMousePosition";
import handNormal from "../../assets/hand-normal.svg?raw";

import { memo, useState, useEffect } from "react";
import { addV2d, divV2d, mulV2d, subV2d } from "~/utils/math";
import {
  CARD_SPREAD_SIZE,
  MOBILE_ZOOM_INDEX_MOD,
  PAN_BASE,
  ZOOM_BASE,
} from "~/utils/consts";
import { Shape } from "~/types/room/types";
import { localState } from "~/state/state";
import { useSnapshot } from "valtio";
import { withPadding } from "./Spread";
import { LiveList, shallow } from "@liveblocks/client";
import { ShapeItem, spreadToScreen } from "~/ui/room/Shapes";

export const CURSOR_COLORS = [
  "#8B90E8",
  "#5CB694",
  "#70B4C7",
  "#F8B0C1",
  "#F8DF00",
  "#F3AF2B",
];

const SHAPE_TIME_MS = 5000;

type recievedPositionsArr =
  | [{ connectionId: number; position: [number, number] }]
  | [];

export const Cursors = memo(function Cursors() {
  const others = useOthers();
  const self = useSelf();
  const [prevRecievedPositions, setPrevRecievedPositions] =
    useState<recievedPositionsArr>([]);
  const { isMouseDown, inDrawingMode, inTypingMode } = useSnapshot(localState);

  const [activeShape, setActiveShape] = useState<Shape | null>(null);
  const myColor = self.presence.color ? self.presence.color : CURSOR_COLORS[0];
  const shapes = useStorage((root) => root.shapes, shallow);

  const [idToRemove, setIdToRemove] = useState("");
  const updateStorage = useMutateStorage();
  const updateMyPresence = useUpdateMyPresence();

  const { pan, zoomIndex, isOnMobile } = useSnapshot(localState);
  const spread = useStorage((root) => root.spread, shallow);
  const zoomIndexMod = isOnMobile ? MOBILE_ZOOM_INDEX_MOD : 0;

  const zoomVector: Vec2d = [
    1 / ZOOM_BASE ** (zoomIndex + zoomIndexMod),
    1 / ZOOM_BASE ** (zoomIndex + zoomIndexMod),
  ];

  useEffect(() => {
    if (isMouseDown && inDrawingMode) startNewShape(myColor);
    else finishShape();
  }, [isMouseDown]);

  const startNewShape = (color: string) => {
    const newShape: Shape = {
      color: color,
      id: Math.random().toString(),
      positions: [],
    };
    setActiveShape(newShape);

    updateMyPresence({
      activeShape: newShape,
    });
  };

  useEffect(() => {
    updateStorage((storage) => {
      storage.set(
        "shapes",
        new LiveList([
          ...storage.get("shapes").filter((shape) => shape.id !== idToRemove),
        ])
      );
    });
  }, [idToRemove]);

  const finishShape = () => {
    if (activeShape) {
      updateStorage((storage) => {
        window.setTimeout(() => {
          setIdToRemove(activeShape.id);
        }, SHAPE_TIME_MS);

        storage.set(
          "shapes",
          new LiveList([...storage.get("shapes"), activeShape])
        );
        updateMyPresence({
          activeShape: null,
        });
      });
    }
    setActiveShape(null);
  };

  useEffect(() => {
    others.forEach(({ connectionId, presence }) => {
      if (presence.cursor) {
        const filteredReceivedPositions = prevRecievedPositions.filter(
          (obj) => obj.connectionId !== connectionId
        );
        const newRecievedPositions = [
          { connectionId: connectionId, position: presence.cursor },
          ...filteredReceivedPositions,
        ];
        setPrevRecievedPositions(newRecievedPositions as recievedPositionsArr);
      }
    });
  }, [others]);

  useMousePosition(true, {
    move: (worldXY, _d, _v, [x, y]) => {
      // take their world space values and convert to screen space
      updateMyPresence({
        // cursors update in world space
        cursor: worldXY,
      });
      if (activeShape && isMouseDown) {
        let newActiveShape = { ...activeShape };
        newActiveShape.positions = [worldXY, ...activeShape.positions];
        updateMyPresence({ activeShape: newActiveShape });
        setActiveShape(newActiveShape);
      }
    },
    leave: () => updateMyPresence({ cursor: null }),
  });

  return (
    <>
      {others.map(({ connectionId, presence }) => {
        const theirColor = presence.color;

        return presence.cursor && theirColor ? (
          <Cursor
            key={connectionId}
            color={theirColor}
            position={spreadToScreen(
              presence.cursor,
              spread,
              pan as Vec2d,
              zoomVector
            )}
          />
        ) : null;
      })}
      {
        <div id="trail-container">
          {shapes.map((shape, i) => (
            <ShapeItem
              key={shape.id}
              shapeIdx={i}
              vanishing={true}
              shape={shape}
            />
          ))}
          {others.map(
            ({ presence }) =>
              presence.activeShape && (
                <ShapeItem
                  key={"active-" + presence.activeShape.id}
                  shapeIdx={-2}
                  vanishing={false}
                  shape={presence.activeShape}
                />
              )
          )}
          {activeShape && (
            <ShapeItem
              key={"my-active-shape"}
              shapeIdx={-1}
              vanishing={false}
              shape={activeShape}
            />
          )}
        </div>
      }
    </>
  );
});

const Cursor = memo(function Cursor({
  position,
  color,
}: {
  position: Vec2d;
  color: string;
}) {
  return (
    <StyledCursor
      animate={{
        x: position[0],
        y: position[1], // keep other's cursor on screen when interacting with hand
      }}
      id={color}
      style={{
        color,
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: handNormal }} />
    </StyledCursor>
  );
});

const StyledCursor = styled(motion.div, {
  position: "fixed",
  top: "0",
  left: "0",
  color: "black",
  pointerEvents: "none",
});
