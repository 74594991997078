import { useEffect, useState } from "react";
import { getAvailabilityByUuid } from "./calendarApi";
import { UserProfile, getUser } from "./userApi";

export interface ChecklistSection {
    desc: string,
    checked: boolean,
}

export interface ChecklistState {
    guidelines: ChecklistSection,
    profile: ChecklistSection,
    calendar: ChecklistSection,
    services: ChecklistSection,
    stripe: ChecklistSection,
}

export const getChecklistState = async (user: UserProfile): Promise<ChecklistState> =>  {
    // if the user has accepted the terms, with any string in this field, 
    // they have read the guidelines
    const guidelinesRead = user.acceptedTerms !== "" && user.acceptedTerms !== " "

    // if the user has been published in our directory, they have designed their profile
    // if the user has tags, they've been published
    const profileDesigned = user.tags !== undefined && user.tags.length > 0
 
     // if the user has an active calendar connection, they have connected their calendar
     const calendarConnected = user.gcalIsAuthorized;
 
     // if the user has chosen any services and set their availability, they have chosen services
     const servicesChosen = await hasUserSelectedServiceAvailablility(user);

    // if the user has connected their stripe account, they have linked stripe
    const stripeLinked = user.acceptingPayments ? true : false;
    
    return {
        guidelines: {
            desc: "Read and agree to our guidelines",
            checked: guidelinesRead,  
        }
        ,
        profile: {
            desc: "Design your new profile page",
            checked: profileDesigned,
          
        },
        calendar: {
            desc: "Connect your calendar",
            checked: calendarConnected,
           
        },
        services: {
            desc: "Choose your services & availability",
            checked: servicesChosen,
       
        },
        stripe: {
            desc: "Link a Stripe account for payouts",
            checked: stripeLinked,
          
        }
    }
}

export  const hasUserSelectedServiceAvailablility =  async (user: UserProfile) => {
    // get user availability
    const availability = await getAvailabilityByUuid(user.uuid);

    
    // check for any weekly availability
    let hasAnyAvailability = false;
    if ((availability.availability.monday.available && availability.availability.monday.times.length > 0)
        || (availability.availability.tuesday.available && availability.availability.tuesday.times.length > 0)
        || (availability.availability.wednesday.available && availability.availability.wednesday.times.length > 0)
        || (availability.availability.thursday.available && availability.availability.thursday.times.length > 0)
        || (availability.availability.friday.available && availability.availability.friday.times.length > 0)
        || (availability.availability.saturday.available && availability.availability.saturday.times.length > 0)
        || (availability.availability.sunday.available && availability.availability.sunday.times.length > 0)
    ) {
        hasAnyAvailability = true;
    }


    // check for services enabled
    let hasAnyService = false;
    availability.services.forEach(service => {
        if (service.offered) {
            hasAnyService = true;
        }
    }
    )

    // both services and availability are needed
    return hasAnyAvailability && hasAnyService;
}

// if the user has chosen any services and has any availability, they are bookable
export const getIsUserBookable = async (user: UserProfile) => {
    const checklist = await getChecklistState(user);
    const isApprovedForServiceListings = user.proDetails?.approvedForServiceListing
    return (isApprovedForServiceListings && checklist.services.checked && checklist.calendar.checked && checklist.profile.checked && checklist.guidelines.checked && checklist.stripe.checked) ? true : false;
}