import { localState } from "~/state/state";
import { sendAnalyticsEvent } from "~/utils/analytics";
import axios from "./axiosConfig.js";
import { RoomEvent } from "~/types/room/types.js";
import { getUserByUsername } from "./userApi.js";
import { sendToast } from "~/utils/handleToasts";

export interface Room {
  name : string
  url : string
  isActive : boolean
  createdAt : Date
  owner: string
  numParticipants?: number
}

interface UpdateRoomRequest {
  roomName: string
  numParticipants: number
}


const ROOM_ENDPOINT = "rooms/"


const updateRoom = async (roomName : string, numParticipants: number) => {
  const updateRoomData : UpdateRoomRequest = {
   roomName,
   numParticipants
  }
  const response = await axios.put(ROOM_ENDPOINT, updateRoomData)
  if (response.status !== 200) {
    throw new Error(response.statusText)
  }
}
const handleJoinRoom = (roomName: string, extraUrl?: string) => {
  if (roomName) {
    sendAnalyticsEvent("joined_room", {
      room_name: roomName
    });
   
    window.location.href = `/room/${roomName}${extraUrl ? extraUrl : ""}`;
  }
};

export const handleCreateRoom = ( extraUrl?: string) => {
  localState.isConnecting = true;
  createRoom().then((res) => {
    handleJoinRoom(res.name, extraUrl);
  }).catch((_error) => {
    window.location.pathname = "/signup";
  });

}

const createRoom = async () : Promise<Room> => {
  const response = await axios.post(ROOM_ENDPOINT)
  if (response.status === 200) {
    sendAnalyticsEvent("created_room", {
      room_name: response.data.name
    });
    return response.data as Room
  } else {
    throw new Error(response.statusText)
  }
}

const getRoom = async (roomName : string) : Promise<Room | null> => {
  try {
    const response = await axios.get(ROOM_ENDPOINT + roomName)
    return response.data as Room
  } catch {
    return null
  }
}

const getMyRooms = async () : Promise<Room[]> => {
  const response = await axios.get(ROOM_ENDPOINT)
  if (response.status === 200) {
    return response.data as Room[]
  } else {
    throw new Error(response.statusText)
  }
}

const deleteRoom = async (roomName : string) => {
  const response = await axios.delete(ROOM_ENDPOINT + roomName)
  if (response.status === 200) {
    sendAnalyticsEvent("deleted_room", {
      room_name: roomName
    });
    return
  } else {
    throw new Error(response.statusText)
  }
}

const uploadBlob = async(blob: Blob, userIds: string[], sessionId: string) => {
  const filename = Math.random().toString(36).substring(7)

  const formData = new FormData();
formData.append("filename", filename);
formData.append("blob", blob);
formData.append("userIds", JSON.stringify(userIds)); // Convert arrays/objects to strings if necessary
formData.append("sessionId", sessionId);


const response = await axios.post(ROOM_ENDPOINT + "upload-video-blob", formData, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

}

export const generatePresignedAccessUrl = async(filename: string) => {
  const response = await axios.post(ROOM_ENDPOINT + "generate-presigned-access-url", {
    filename
  })
  if (response.status === 200) {
    console.log(response.data)
    return response.data.downloadURL
  } else {
    throw new Error(response.statusText)
  }
}


const storeRecording = async(filename: string, userNames: string[], sessionId: string) => {
  const userIds = await Promise.all(userNames.map(async (userName) => {
    const user = await getUserByUsername(userName)
    return user.uuid
  }))

  const response = await axios.post(ROOM_ENDPOINT + "store-recording", {
    filename,
    userIds, 
    sessionId
  })
  if (response.status === 200) {
    return response.data
  } else {
    sendToast(true, 1177)
  }
}

export { updateRoom, createRoom, getRoom, getMyRooms, deleteRoom, uploadBlob, storeRecording }
