import { Actions } from "../../ui/room/Actions";
import { Button } from "../../ui/components/Button";
import { Card } from "../../ui/room/Card";
import { CARD_SHUFFLE_SIZE } from "../../utils/consts";
import { AnimatePresence, motion, useMotionValue } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useMousePosition, Vec2d } from "../../utils/useMousePosition";
import { map } from "../../utils/lerp";
import { useSnapshot } from "valtio";
import {
  useMutateRoomState,
  useMutateStorage,
  useStorage,
} from "../../state/liveblocks.config";
import { RoomState } from "../../types/room/types";
import { localState } from "~/state/state";
import { shallow } from "@liveblocks/client";
import React from "react";
import { imageUrl } from "~/utils/imageurl";

const ROT_JITTER = 5;
const ROT_X = 10;
const SPIN_DURATION = 4;
const SHUFFLE_DURATION = 3;
const DELAY_OFFSET = 0.1;
const Y_SHIFT = 90;
const DIAMETER = 25;
const DIAMETER_JITTER = 1;
const ROT_Y_JITTER = 0.1;

export const Shuffle = React.memo(function Shuffle({}: {}) {
  const deck = useStorage((root) => root.deck, shallow);
  const theme = useStorage((root) => root.theme, shallow);

  const [canFinishShuffling, setCanFinishShuffling] = useState(true);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);
  const isShuffling = useStorage((root) => root.state === RoomState.Shuffle);
  const isFinishedShuffling = useStorage(
    (root) => root.state === RoomState.Draw
  );
  const setRoomState = useMutateRoomState();
  const updateStorage = useMutateStorage();
  const { isOnMobile, isMobileMessageActive } = useSnapshot(localState);

  const randomizedPositions = useMemo<Vec2d[]>(
    () =>
      deck.cards.map((_, i) =>
        i === deck.cards.length - 1
          ? [0.5, 0.5] // last one is always centered
          : [Math.random(), Math.random()]
      ),
    [deck]
  );

  useEffect(() => {
    updateStorage((storage) => {
      storage.set("searchTerm", "");
      storage.set("isCardSearchOpen", false);
    });
  }, []);

  useEffect(() => {
    if (isShuffling) window.setTimeout(() => setCanFinishShuffling(true), 1000);
    else setCanFinishShuffling(false);
  }, [isShuffling]);

  const sceneRotX = useMotionValue(0);
  const sceneRotY = useMotionValue(0);

  useMousePosition(true, {
    start: () => {},
    move: ([x, y]) => {
      sceneRotX.set(map(y, 0, window.innerHeight, 0, -10));
      sceneRotY.set(map(x, 0, window.innerWidth, -10, 10));
    },
    stop: () => {},
  });
  if (isFinishedShuffling && isOnMobile) return <></>;
  return (
    <>
      <motion.div
        onClick={() => {
          if (!isShuffling && !isFinishedShuffling) {
            setRoomState(RoomState.Shuffle);
            updateStorage((storage) => {
              const deck = storage.get("deck");
              storage.set("deck", {
                ...deck,
                cards: [...deck.cards].sort(() => Math.random() - 0.5),
              });
            });
          } else if (isShuffling && canFinishShuffling)
            setRoomState(RoomState.Draw);
        }}
        style={{
          transformStyle: "preserve-3d",
          rotateX: sceneRotX,
          rotateY: sceneRotY,
          marginTop: isMobileMessageActive ? "50px" : 0,
        }}
        animate={{}}
        exit={{
          y: window.innerHeight,
          transition: {
            type: "tween",
            ease: "easeIn",
            duration: 0.75,
          },
        }}
      >
        <motion.div
          style={{
            transformStyle: "preserve-3d",
            rotateX: ROT_X,
          }}
          variants={{
            spinning: {
              rotateY: 360,
              transition: {
                type: "tween",
                ease: "linear",
                repeat: Infinity,
                duration: SPIN_DURATION,
                delay: 1,
              },
            },
            stopped: {
              rotateY: 0,
              transition: {
                type: "tween",
                ease: "easeIn",
                duration: 1,
              },
            },
          }}
          animate={isShuffling ? "spinning" : "stopped"}
        >
          <AnimatePresence>
            {!isShuffling &&
              !isFinishedShuffling &&
              deck.cards.slice(0, 5).map((_, i) => (
                <motion.div
                  key={"shadow" + i}
                  style={{
                    position: "absolute",
                    pointerEvents: "none",
                    width: CARD_SHUFFLE_SIZE[0],
                    height: CARD_SHUFFLE_SIZE[1],
                    left: "50%",
                    top: "50%",
                    x: `-50%`,
                    y: `-50%`,
                    // z: DIAMETER + deck.cards.length * DIAMETER_JITTER,
                    background: "rgba(0, 0, 0, .2)",
                    borderRadius: 10,
                    rotateZ:
                      randomizedPositions[i][0] * ROT_JITTER - ROT_JITTER / 2,
                    filter: "blur(5px)",
                  }}
                  initial={{
                    rotateX: -25,
                  }}
                  animate={{
                    rotateX: 0,
                    transition: {
                      type: "tween",
                      ease: "easeOut",
                      duration: 1,
                      delay: 0.5,
                    },
                  }}
                  exit={{
                    opacity: 0,
                    z: -100,
                    filter: "blur(25px)",
                    transition: {
                      type: "tween",
                      ease: "easeOut",
                      duration: 1,
                    },
                  }}
                />
              ))}
          </AnimatePresence>
          {deck.cards.map((card, i) => (
            <motion.div
              key={card.filePath}
              style={{
                transformStyle: "preserve-3d",
                cursor:
                  isFinishedShuffling ||
                  isShuffling ||
                  (isShuffling && canFinishShuffling)
                    ? "pointer"
                    : "auto",
              }}
              animate={isShuffling ? "shuffling" : "stopped"}
              variants={{
                shuffling: {
                  rotateX: 360,
                  y: [0, -Y_SHIFT, Y_SHIFT, 0],
                  transition: {
                    rotateX: {
                      type: "tween",
                      repeat: Infinity,
                      duration: SHUFFLE_DURATION,
                      delay: (deck.cards.length - 1 - i) * DELAY_OFFSET,
                    },
                    y: {
                      type: "tween",
                      repeat: Infinity,
                      repeatType: "loop",
                      duration: SHUFFLE_DURATION,
                      delay: (deck.cards.length - 1 - i) * DELAY_OFFSET,
                    },
                  },
                },
                stopped: {
                  rotateX: 0,
                  y: 0,
                },
              }}
            >
              <motion.div
                style={{
                  transformStyle: "preserve-3d",
                  z: i * DIAMETER_JITTER, // deck depth
                }}
                initial={{
                  rotateX: -25,
                }}
                animate={{
                  z: (isShuffling ? DIAMETER : 0) + i * DIAMETER_JITTER, // deck depth
                  rotateX: 0,
                  rotateY: isShuffling ? i * ROT_Y_JITTER : 0, // tilt it a bit so they dont all look super thin from the side at the same time
                }}
                transition={{
                  type: "tween",
                  ease: "easeOut",
                  duration: 1,
                  delay: 0.5,
                }}
              >
                <Card
                  theme={theme}
                  noCardArt={true}
                  key={card.filePath}
                  size={deck.cardSize}
                  border={deck.border}
                  borderColor={deck.borderColor}
                  fallbackColor={deck.fallbackColor}
                  src={card.filePath}
                  backSrc={
                    deck.backArtwork
                      ? deck.backArtwork.includes(".mp4")
                        ? "/images" + deck.basePath + "/" + deck.backArtwork
                        : imageUrl(deck.basePath + "/" + deck.backArtwork)
                      : null
                  }
                  foilPath={
                    !isOnMobile && deck.foilPath
                      ? card.filePath.split(".jpeg").join(deck.foilPath)
                      : undefined
                  }
                  foilTopOffset={6}
                  foilHeightOffset={3}
                  style={{
                    width: CARD_SHUFFLE_SIZE[0],
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    x: "-50%",
                    y: "-50%",
                    z: i,
                    rotateZ:
                      (randomizedPositions[i][0] + randomizedPositions[i][1]) *
                        ROT_JITTER -
                      ROT_JITTER / 2,
                  }}
                  animate={isShuffling ? "shuffling" : "stopped"}
                  variants={{
                    shuffling: {
                      rotateX: -360, // reverse the rotation to keep it facing forward
                      transition: {
                        type: "tween",
                        repeat: Infinity,
                        duration: SHUFFLE_DURATION,
                        delay: (deck.cards.length - 1 - i) * DELAY_OFFSET,
                      },
                    },
                    stopped: {
                      rotateX: 0,
                    },
                  }}
                />
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
      <Actions>
        {isShuffling || isFinishedShuffling ? (
          <Button
            Theme={theme}
            style={{
              marginBottom: isOnMobile ? "80px" : "30px",
              opacity: canFinishShuffling ? 1 : 0,
              visibility: canFinishShuffling ? "visible" : "hidden",
              transition: "opacity 0.2s",
              pointerEvents: canFinishShuffling ? "auto" : "none",
            }}
            onClick={(e) => {
              setRoomState(RoomState.Draw);
              e.stopPropagation();
            }}
          >
            Done shuffling
          </Button>
        ) : (
          <>
            <Button
              Theme={theme}
              style={{ marginBottom: isOnMobile ? "80px" : "30px" }}
              onClick={(e) => {
                setRoomState(RoomState.Shuffle);
                e.stopPropagation();
                updateStorage((storage) => {
                  const deck = storage.get("deck");
                  storage.set("deck", {
                    ...deck,
                    cards: [...deck.cards].sort(() => Math.random() - 0.5),
                  });
                });
              }}
            >
              Ready to shuffle
            </Button>
          </>
        )}
      </Actions>
    </>
  );
});
