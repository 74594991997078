import React, { useEffect, useState, useContext } from "react";
import { useLocation, useRoute } from "wouter";

import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { StyledForm } from "./SignUp";
import { Button } from "~/ui/components/Button";
import { PASSWORD_VALIDATION_REGEX } from "~/utils/consts";
import { AuthContext } from "~/utils/useAuth";
import { LoadingIndicator } from "~/pages/loading/LoadingPage";
import { localState } from "~/state/state";
import { Message } from "../Message";

type State = {
  password: string;
  confirmPassword: string;
  isSubmitting: boolean;
  errorMessage: string | null;
};

const initialState: State = {
  password: "",
  confirmPassword: "",
  isSubmitting: false,
  errorMessage: null,
};

interface QueryParams {
  token: string;
}

export default function ResetPasswordPage() {
  const [data, setData] = useState(initialState);
  const { checkPasswordLink, resetPassword } = useContext(AuthContext);
  const [linkChecked, setLinkChecked] = useState(false);

  let [location, setLocation] = useLocation();
  let query = window.location.search;
  let urlQueryParams = new URLSearchParams(query);
  let token = urlQueryParams.get("token") || "";
  if (!token) {
    console.log("Invalid Reset Password Token");
    setLocation("/");
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (linkChecked) return;
    (async () => {
      try {
        await checkPasswordLink(token);
        setLinkChecked(true);
      } catch (error) {
        setLocation("/forgotPassword?invalid=true");
      }
    })();
  }, []);

  const handleResetPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    try {
      await resetPassword(data.password, token);
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: null,
      });
      window.location.href = "/signin?reset=true";
    } catch (error) {
      let errorMessage = null;
      if (error instanceof AxiosError) {
        errorMessage = error.message;
      }
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: errorMessage,
      });
      // setLocation("/signin");
    }
  };

  return (
    <>
      <Message />
      {linkChecked ? (
        <StyledForm>
          <>
            <img
              src="/images/room/menu-close.png"
              className="back-button"
              onClick={() =>
                setLocation("/forgotPassword" + window.location.search)
              }
            />
            <h3 className="title">Choose a new password</h3>

            <form onSubmit={handleResetPassword}>
              <div className="input-container">
                <input
                  className="sentry-mask"
                  type="password"
                  name="password"
                  required
                  placeholder="Password"
                  pattern={PASSWORD_VALIDATION_REGEX}
                  id="inputPasswordReset"
                  onInvalid={(e) => e.preventDefault()}
                  value={data.password}
                  onChange={handleInputChange}
                />
                <p className="help-message">Must be at least 6 characters</p>
                <p className="error-message">Must be at least 6 characters</p>
              </div>
              <div className="input-container">
                <input
                  className="sentry-mask"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm password"
                  required
                  id="inputConfirmPasswordReset"
                  value={data.confirmPassword}
                  onInvalid={(e) => e.preventDefault()}
                  onInput={(e) => {
                    //@ts-ignore
                    if (
                      //@ts-ignore
                      e.target.value !==
                      //@ts-ignore
                      document.getElementById("inputPasswordReset").value
                    ) {
                      //@ts-ignore
                      e.target.setCustomValidity("invalid");
                    } else {
                      //@ts-ignore

                      e.target.setCustomValidity("");
                    }
                  }}
                  onChange={handleInputChange}
                />
                <p className="error-message">Passwords must match</p>
              </div>

              <Button
                type="submit"
                formButton
                disabled={data.isSubmitting}
                style={{ width: "100%", marginTop: "1rem" }}
              >
                Reset Password
              </Button>
            </form>
          </>
        </StyledForm>
      ) : (
        <div className="loading">
          <LoadingIndicator noText />
        </div>
      )}
    </>
  );
}
