import { useContext, useEffect, useState } from "react";
import {
  getAvailabilityByUuid,
  getCalendarAuthUrl,
  updateAvailabilityByUuid,
  Service,
  disconnectCalendar,
} from "~/api/calendarApi";
import { styled } from "~/ui/style/stitches.config";
import { Button } from "~/ui/components/Button";
import { ToggleSlider } from "~/ui/components/ToggleSlider";
import { TimezoneMenu } from "~/ui/menus/TimezoneMenu";
import { TimeBubble, PriceBubble } from "~/ui/components/Availability";
import {
  DayAvailability,
  DaysOfTheWeek,
  Timeslot,
  AvailabilityRequest,
  emptyAvailabilityRequest,
  WeeklyAvailability,
  emptyWeeklyAvailability,
} from "~/utils/datetime";
import { AuthContext } from "~/utils/useAuth";
import { toPrice } from "~/utils/math";
import { StyledArchProfilePic } from "~/ui/components/ProfilePic";
import { SpinStar } from "../../ui/components/SpinStar";
import { QuestionHover } from "~/ui/components/Tooltip";
import { localState } from "~/state/state";

import { SimpleLoadingIndicator } from "../loading/LoadingPage";
import { Message } from "~/ui/components/Message";

import { MOONLIGHT_SESSION_FEE_PERCENTAGE } from "~/utils/consts";
import { MOONLIGHT_PAGE } from "../Home";
import {
  sendFriendlyToast,
  sendServerErrorToastWithFallbackCode,
} from "~/utils/handleToasts";
import { imageUrl } from "~/utils/imageurl";

const ServiceSelectorSession = ({
  serviceName,
  serviceDescription,
  isOffered,
  serviceDuration,
  price,
  maxPrice,
  minPrice,
  setService,
  savedService,
  isChangeable,
}: {
  serviceName: string;
  serviceDescription: string;
  price: number;
  isOffered: boolean;
  maxPrice: number;
  minPrice: number;
  serviceDuration: number;
  initialCurrency: string;
  numServicesAvailable: number;
  setService: Function;
  savedService: Service;
  isChangeable: boolean;
}) => {
  const durationMinutesToText = (duration: number) => {
    if (duration < 0) {
      return `15 min`;
    } else if (duration < 60) {
      return `${duration} min`;
    } else if (duration % 60 === 0) {
      return `${duration / 60} hour`;
    } else {
      return `${Math.floor(duration / 60)} hour ${duration % 60} min`;
    }
  };

  const setIsOffered = (isOffered: boolean) => {
    setService({
      ...savedService,
      offered: isOffered,
    });
  };

  const setPrice = (price: number) => {
    setService({
      ...savedService,
      price,
    });
  };

  return (
    <StyledDateSelectorSection className="page-section" serviceSelector>
      <div className="top-row">
        {serviceName}
        <ToggleSlider
          isOn={isOffered ? true : false}
          setIsOn={() => setIsOffered(!isOffered)}
        />
      </div>
      <div className="description">{serviceDescription}</div>
      <div className="next-row">
        <div className="host-tag">
          <img src="/images/video.svg"></img>
          {durationMinutesToText(serviceDuration)}
        </div>
      </div>

      {isOffered && (
        <>
          <div className="price-section">
            Session price (in USD)
            <div className="price-container">
              <PriceBubble
                isChangeable={isChangeable}
                price={price}
                maxPrice={maxPrice}
                minPrice={minPrice}
                setUpdatedPrice={(price: number) => {
                  setPrice(price);
                }}
              />
            </div>
          </div>
          <div
            className="bottom-line"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="price-bottom-line"
              style={{
                fontSize: "11px",
                textAlign: "right",
                marginTop: "-2px",
              }}
            >
              You receive&nbsp;
              <>
                {`$${toPrice(price * (1 - MOONLIGHT_SESSION_FEE_PERCENTAGE))}`}
                <QuestionHover
                  leftOnMobile
                  text={`<div>You receive<br/>${
                    (1 - MOONLIGHT_SESSION_FEE_PERCENTAGE) * 100
                  }% after fees.</div><div class='inside-button'><a href='https://help.moonlight.world/article/17-payouts-and-pricing' target='_blank'>Learn more</a></div>`}
                ></QuestionHover>
              </>
            </div>
          </div>
        </>
      )}
    </StyledDateSelectorSection>
  );
};

const DateSelectorSection = ({
  dateIsAvailable,
  dateName,
  dateTimeslots,
  setDateTimeslots,
}: {
  dateIsAvailable: boolean;
  dateName: string;
  dateTimeslots: Timeslot[];
  setDateTimeslots: (times: Timeslot[], available: boolean) => void;
}) => {
  const [defaultTimeslot, setDefaultTimeslot] = useState<Timeslot>({
    start: "1000",
    end: "1700",
  });

  const updateDefaultTimeslot = () => {
    if (dateTimeslots.length === 0) {
      setDefaultTimeslot({
        start: "1000",
        end: "1700",
      });
      return;
    }
    const lastEndTime = dateTimeslots[dateTimeslots.length - 1].end;
    if (parseInt(lastEndTime) >= 2300) {
      setDefaultTimeslot({
        start: "0800",
        end: "0900",
      });
      return;
    }

    let newStartTime = (parseInt(lastEndTime) + 100).toString();
    let newEndTime = (parseInt(newStartTime) + 100).toString();
    if (newStartTime.length < 4) {
      newStartTime = "0" + newStartTime;
    }
    if (newEndTime.length < 4) {
      newEndTime = "0" + newEndTime;
    }
    setDefaultTimeslot({
      start: newStartTime,
      end: newEndTime,
    });
  };

  useEffect(() => {
    updateDefaultTimeslot();
  }, [dateTimeslots]);

  const setTimeslots = (timeslots: Timeslot[]) => {
    setDateTimeslots(timeslots, dateIsAvailable);
  };

  const setIsAvailable = (isAvailable: boolean) => {
    setDateTimeslots(dateTimeslots, isAvailable);
  };

  useEffect(() => {
    if (dateIsAvailable && dateTimeslots.length === 0) {
      setTimeslots([defaultTimeslot]);
    } else {
      setTimeslots(dateTimeslots);
    }
  }, [dateIsAvailable]);

  useEffect(() => {
    if (dateTimeslots.length === 0) {
      setIsAvailable(false);
    }
  }, [dateTimeslots.length]);

  return (
    <StyledDateSelectorSection separator>
      <div className="top-row">
        {dateName + "s"}
        <ToggleSlider isOn={dateIsAvailable} setIsOn={setIsAvailable} />
      </div>
      <div className="schedule-container">
        {dateIsAvailable && (
          <div className="availabilities">
            {dateTimeslots.map((timeslot, i) => {
              return (
                <div
                  key={dateName + "availabilities-" + i}
                  className="time-range"
                >
                  <TimeBubble
                    time={timeslot.start}
                    timeMax={timeslot.end}
                    setTime={(newTime: string) => {
                      const newTimeslots = [...dateTimeslots];
                      newTimeslots[i].start = newTime;
                      setTimeslots(newTimeslots);
                    }}
                  />
                  <div className="time-separator">to</div>
                  <TimeBubble
                    time={timeslot.end}
                    timeMin={timeslot.start}
                    setTime={(newTime: string) => {
                      const newTimeslots = [...dateTimeslots];
                      newTimeslots[i].end = newTime;
                      setTimeslots(newTimeslots);
                    }}
                  />
                  <img
                    className="delete-button"
                    onClick={() => {
                      const newTimeslots = [...dateTimeslots];
                      newTimeslots.splice(i, 1);
                      setTimeslots(newTimeslots);
                    }}
                    src="./images/room/x-icon.png"
                  />
                </div>
              );
            })}
          </div>
        )}
        {dateIsAvailable && (
          <button
            onClick={() => {
              setTimeslots([...dateTimeslots, defaultTimeslot]);
            }}
          >
            <img
              style={{
                width: "20px",
                height: "20px",
              }}
              src={"./images/create.svg"}
            />
          </button>
        )}
      </div>
    </StyledDateSelectorSection>
  );
};

const StyledDateSelectorSection = styled("div", {
  width: "100%",
  padding: "8px",
  paddingLeft: "20px",

  "& .top-row": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "13px",
    fontWeight: "400",
  },
  "& img": {
    userSelect: "none",
  },
  "& .time-range": {
    marginTop: "4px",
    marginBottom: "1px",
    userSelect: "none",
    display: "flex",
    alignItems: "center",

    "& .delete-button": {
      visibility: "hidden",
      width: "18px",
      height: "18px",
      cursor: "pointer",
      marginLeft: "4px",
      opacity: "0.5",
      "&:hover": {
        opacity: "1",
      },
      filter: "invert(1)",
    },
    "&:hover": {
      "& .delete-button": {
        visibility: "visible",
      },
    },
    "& .time-separator": {
      marginLeft: "5px",
      marginRight: "5px",
      fontSize: "10px",
    },
  },
  "& .schedule-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: "4px",
    "& button": {
      backgroundColor: "white",

      display: "flex",
      cursor: "pointer",
      justifyContent: "center",
      alignItems: "center",
      border: "none",
      width: "18px",
      height: "18px",
      borderRadius: "50%",
      transition: "opacity 0.2s ease-in-out",

      "&:hover": {
        opacity: 0.4,
      },
    },
  },
  "& .availabilities": {
    display: "flex",
    flexDirection: "column",
  },
  "& .price-bottom-line": {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },

  "& .price-section": {
    backgroundColor: "$gray100",
    marginLeft: "-16px",
    width: "calc(100% + 32px)",
    marginTop: "10px",
    marginBottom: "10px",
    height: "42px",
    fontWeight: "500",
    fontSize: "11px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",

    "& .price-container": {
      display: "flex",
      alignItems: "center",
      fontWeight: "normal",

      "& > *": {
        marginTop: "0 !important",
        marginLeft: "3px",
        marginRight: "3px",
      },
    },
  },
  variants: {
    serviceSelector: {
      true: {
        padding: "24px 36px !important",
        marginBottom: "10px !important",
        "& .top-row": {
          fontSize: "14px",
          fontWeight: "500",
        },
        "& .description": {
          fontSize: "12px",
          maxWidth: "90%",
          lineHeight: "1.4",
          //italic
          // fontStyle: "italic",
          marginTop: "4px",
          marginBottom: "6px",
        },
        "& .next-row": {
          display: "flex",
          fontSize: "11px",
          alignItems: "center",
          marginTop: "6px",
          marginBottom: "8px",
          "& .time": {
            backgroundColor: "$gray100",
            width: "60px",
            height: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "7px",
            marginRight: "4px",
            paddingBottom: "1px",
          },
          "& .host-tag": {
            display: "flex",
            alignItems: "center",
            "& img": {
              width: "20px",
              height: "20px",
              marginRight: "4px",
            },
          },
        },
      },
    },
    separator: {
      true: {
        "&:not(:first-child)": {
          "&::before": {
            content: '""',
            display: "block",
            width: "calc(100% + 76px)",
            // padding: "20px 24px",
            marginLeft: "-44px",
            height: "1px",
            backgroundColor: "#e0e0e0",
            marginBottom: "16px",
          },
        },
      },
    },
  },
});

export const ProfessionalDashboard = ({
  activePage,
}: {
  activePage: MOONLIGHT_PAGE;
}) => {
  const { user, getUser } = useContext(AuthContext);

  const [hasLoaded, setHasLoaded] = useState(false);

  const [disconnectCalendarModal, setDisconnectCalendarModal] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [selectedGoogleId, setSelectedGoogleId] = useState<string>("");
  const [gcalAuthUrl, setGcalAuthUrl] = useState<string | null>(null);

  const [allAvailabilityData, setAllAvailabilityData] =
    useState<AvailabilityRequest>({ ...emptyAvailabilityRequest });

  const numServicesAvailable = allAvailabilityData?.services.filter(
    (sa) => sa?.offered
  ).length
    ? allAvailabilityData?.services.filter((sa) => sa?.offered).length
    : 0;

  const [originalAvailabilityData, setOriginalAvailabilityData] =
    useState<AvailabilityRequest>({ ...emptyAvailabilityRequest });

  useEffect(() => {
    // on startup, get all availability data
    (async () => {
      if (!user?.uuid) return;
      const data = await getAvailabilityByUuid(user?.uuid);
      if (!data) return;
      if (!data.timezoneIana) {
        data.timezoneIana = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      setSelectedGoogleId(user.selectedGoogleId?.trim() || "");
      if (!gcalAuthUrl) {
        const gcalAuthUrl = await getCalendarAuthUrl(user?.uuid || "");
        setGcalAuthUrl(gcalAuthUrl);
      }

      setAllAvailabilityData(JSON.parse(JSON.stringify(data)));
      setOriginalAvailabilityData(JSON.parse(JSON.stringify(data)));
      setHasLoaded(true);
    })();
  }, [user?.uuid, selectedGoogleId, activePage]);

  const closeAllMenus = (e: React.MouseEvent) => {
    const menus = document.querySelectorAll(".menu");
    menus.forEach((m) => {
      if (e.target !== m) {
        m.classList.remove("open");
      }
    });
  };

  const [disconnectingCalendar, setDisconnectingCalendar] = useState(false);

  return !user?.proDetails?.approvedForServiceListing ? (
    <></>
  ) : (
    <StyledProfessionalDashboard
      id="availability-pg"
      onClick={(e) => {
        closeAllMenus(e);
      }}
    >
      <Message inset />
      <div className="column first"></div>
      <div className="column middle">
        <div className="profile-arch" style={{ position: "relative" }}>
          <StyledArchProfilePic
            css={{ $$size: "100%" }}
            src={imageUrl("/profile-pics/" + user?.profilePic, 340)}
          ></StyledArchProfilePic>
          <div
            className="profile-link"
            onClick={() => {
              window.open("/profile/" + user?.username, "_blank");
            }}
          >
            <img src="/images/room/link-out.svg" /> View profile
          </div>
          <div style={{ transform: "scale(0.7)", transformOrigin: "right" }}>
            <SpinStar
              style={{
                position: "absolute",
                right: "-30px",
                bottom: "-30px",
              }}
              color={user?.accentColor}
              borderColor={"black"}
            />
          </div>
        </div>
        {!hasLoaded ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              transform: "translate(0px, 25px)",
              transformOrigin: "center",
              justifyContent: "center",
              opacity: "0.6",
            }}
            onClick={() => {
              if (gcalAuthUrl) {
                location.href = gcalAuthUrl;
              }
            }}
          >
            <SimpleLoadingIndicator />
          </div>
        ) : (
          <>
            <div className="page-title">Your offerings</div>
            {!user.gcalIsAuthorized ? (
              <div
                className="page-section"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "27px",
                }}
              >
                <Button
                  Theme={"dark"}
                  style={{
                    border: "none",
                    padding: "12px 18px",
                    borderRadius: "13px",
                    fontSize: "11px",
                    marginBottom: "12px",
                    marginTop: "20px",
                  }}
                  onClick={() => {
                    // open google auth url
                    if (gcalAuthUrl) window.location.href = gcalAuthUrl;
                  }}
                >
                  Connect Google Calendar
                </Button>
                <h2 style={{ marginBottom: "20px", maxWidth: "90%" }}>
                  Before setting your availability, sync your calendar to
                  automatically check for scheduling conflicts and receive
                  session invitations.
                </h2>
              </div>
            ) : (
              <>
                <h1>Select services & adjust your session pricing.</h1>
                <h2 style={{ display: "flex", alignItems: "center" }}>
                  Choose which offerings you'd like to list on your profile.
                </h2>

                <div className="availability-form">
                  {allAvailabilityData?.services &&
                    allAvailabilityData?.services.map((service) => (
                      <ServiceSelectorSession
                        savedService={service}
                        key={service.name}
                        isChangeable={service.duration > 0 ? true : false}
                        serviceName={service.name}
                        serviceDescription={service.description}
                        serviceDuration={service.duration}
                        price={
                          !!service.price
                            ? service.price
                            : service.duration === 30
                            ? 60
                            : service.duration < 0
                            ? 25
                            : 85
                        }
                        initialCurrency={"USD"}
                        maxPrice={service.duration < 0 ? 25 : 300}
                        minPrice={service.duration < 0 ? 25 : 50}
                        numServicesAvailable={numServicesAvailable}
                        isOffered={service.offered}
                        setService={(serviceAvailability: Service) => {
                          const newServiceAvailabilities = [
                            ...allAvailabilityData.services,
                          ];
                          const index = newServiceAvailabilities.findIndex(
                            (sa) => sa.id === serviceAvailability.id
                          );
                          if (!serviceAvailability.price) {
                            if (serviceAvailability.duration === 30) {
                              serviceAvailability.price = 60;
                            } else if (serviceAvailability.duration < 0) {
                              serviceAvailability.price = 25;
                            } else {
                              serviceAvailability.price = 85;
                            }
                          }
                          if (index > -1) {
                            newServiceAvailabilities[index] =
                              serviceAvailability;
                          } else {
                            newServiceAvailabilities.push(serviceAvailability);
                          }
                          const newAvailabilityData = {
                            ...allAvailabilityData,
                          };
                          newAvailabilityData.services =
                            newServiceAvailabilities;
                          setAllAvailabilityData(newAvailabilityData);
                        }}
                      />
                    ))}
                </div>

                <h1>Edit recurring days & times.</h1>
                <h2 style={{ display: "flex" }}>
                  {numServicesAvailable > 0 ? (
                    <>
                      Choose your availability windows for bookings.
                      <span>
                        {" "}
                        <QuestionHover
                          leftOnMobile={true}
                          text={
                            "We check your<br/>calendar to prevent<br/>overlapping events."
                          }
                        ></QuestionHover>
                      </span>
                    </>
                  ) : (
                    "You have no private sessions open."
                  )}
                </h2>
                {numServicesAvailable > 0 && (
                  <>
                    <div className="availability-form page-section">
                      {DaysOfTheWeek.map((day) => {
                        const dayAvail = {
                          ...(allAvailabilityData?.availability[
                            day.name.toLowerCase() as
                              | "monday"
                              | "tuesday"
                              | "wednesday"
                              | "thursday"
                              | "friday"
                              | "saturday"
                              | "sunday"
                          ] as DayAvailability),
                        };

                        return (
                          <DateSelectorSection
                            key={day.name}
                            dateName={day.name}
                            dateIsAvailable={dayAvail.available}
                            dateTimeslots={dayAvail.times as Timeslot[]}
                            setDateTimeslots={(
                              times: Timeslot[],
                              available: boolean
                            ) => {
                              if (!allAvailabilityData?.availability) return;
                              const newAvailabilities = {
                                ...allAvailabilityData?.availability,
                              };
                              newAvailabilities[
                                day.name.toLowerCase() as
                                  | "monday"
                                  | "tuesday"
                                  | "wednesday"
                                  | "thursday"
                                  | "friday"
                                  | "saturday"
                                  | "sunday"
                              ] = {
                                times: [...times],
                                available: available,
                              };
                              const newAvailabilityData = {
                                ...allAvailabilityData,
                              };
                              newAvailabilityData.availability =
                                newAvailabilities;
                              setAllAvailabilityData({
                                ...newAvailabilityData,
                              });
                            }}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
                <h1 style={{ marginBottom: "12px" }}>
                  Adjust your calendar settings.
                </h1>

                <div
                  className="page-section"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      fontWeight: "500",
                      marginBottom: "11px",
                    }}
                  >
                    Timezone
                  </div>
                  <TimezoneMenu
                    timezone={allAvailabilityData.timezoneIana}
                    setTimezone={(timezone: string) => {
                      const newAvailabilityData = { ...allAvailabilityData };
                      newAvailabilityData.timezoneIana = timezone;
                      setAllAvailabilityData(newAvailabilityData);
                    }}
                  />
                </div>

                <div
                  className="page-section"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="disconnect-calendar">
                    {!disconnectCalendarModal ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "500",
                            marginBottom: "11px",
                            pointerEvents: "none",
                          }}
                        >
                          Calendar
                        </div>
                        <TimezoneMenu
                          timezone={selectedGoogleId}
                          setTimezone={() => {}}
                          style={{ pointerEvents: "none" }}
                        />
                        <Button
                          Theme={"dark"}
                          style={{
                            fontSize: "11px",
                            width: "110px",
                            height: "40px",
                            padding: 0,
                            marginTop: "10px",
                          }}
                          onClick={() => setDisconnectCalendarModal(true)}
                        >
                          Disconnect
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            maxWidth: "350px",
                          }}
                        >
                          <div
                            style={{
                              marginBottom: "10px",
                              marginTop: "10px",
                              fontWeight: "500",
                            }}
                          >
                            Are you sure you want to disconnect your calendar?
                          </div>
                          <div>
                            This will prevent new bookings, but you can easily
                            reconnect a calendar anytime!
                          </div>
                        </div>
                        <div style={{ display: "flex", marginBottom: "20px" }}>
                          <Button
                            style={{
                              width: "80px",
                              padding: 0,
                              marginLeft: "4px",
                              marginRight: "4px",
                            }}
                            lightweight
                            onClick={() => setDisconnectCalendarModal(false)}
                          >
                            No
                          </Button>
                          <Button
                            lightweight
                            style={{
                              width: "80px",
                              padding: 0,
                              marginLeft: "4px",
                              marginRight: "4px",
                            }}
                            onClick={() => {
                              setDisconnectingCalendar(true);
                              disconnectCalendar(user.uuid)
                                .then(() => getUser())
                                .then(() => {
                                  window.location.reload();
                                });
                              setSelectedGoogleId("");
                            }}
                          >
                            {disconnectingCalendar ? (
                              <div
                                style={{
                                  position: "relative",
                                  transform: "scale(0.3)",
                                  marginTop: "4px",
                                }}
                              >
                                <SimpleLoadingIndicator />
                              </div>
                            ) : (
                              "Yes"
                            )}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="padding" style={{ padding: "100px" }}></div>
          </>
        )}
      </div>

      <div className="column"></div>

      <div
        className="cancel-save-buttons"
        style={{
          position: "fixed",
          top: "70px",
          right: "30px",
          visibility:
            JSON.stringify(allAvailabilityData) ===
            JSON.stringify(originalAvailabilityData)
              ? "hidden"
              : "visible",
        }}
      >
        {!isSaving && (
          <>
            <Button
              style={{
                height: "40px",
                width: "100px",
                paddingTop: "11px",
                marginRight: "4px",
              }}
              onClick={() => {
                setAllAvailabilityData(
                  JSON.parse(JSON.stringify(originalAvailabilityData))
                );
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ height: "42px", width: "100px", paddingTop: "12px" }}
              Theme={"dark"}
              onClick={() => {
                setIsSaving(true);
                // Set saving message
                sendFriendlyToast(1048);

                (async () => {
                  await updateAvailabilityByUuid(
                    user.uuid!,
                    allAvailabilityData
                  )
                    .then(() => {
                      setIsSaving(false);
                      // Availability published
                      sendFriendlyToast(1049);
                    })
                    .catch((error) => {
                      setIsSaving(false);
                      // Availability not published
                      sendServerErrorToastWithFallbackCode(error, 1050);
                    });
                  setOriginalAvailabilityData(
                    JSON.parse(JSON.stringify(allAvailabilityData))
                  );
                })();
              }}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </StyledProfessionalDashboard>
  );
};

export const StyledProfessionalDashboard = styled("div", {
  backgroundColor: "$wash",
  height: "100vh",
  width: "100vw",
  overflow: "scroll",
  position: "relative",
  paddingRight: 0,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  paddingTop: "126px",
  paddingLeft: "$navWidth",

  "& .page-title": {
    fontFamily: "GorgeousBaby",
    fontSize: "40px",
  },

  "& .page-section": {
    backgroundColor: "white",
    width: "100%",
    height: "auto",
    padding: "20px 24px",
    borderRadius: "31px",
    marginBottom: "10px",
    border: "0.5px solid rgba(0, 0, 0, 0.4)",
    boxShadow: "0px 2px 0px 0px #000",
  },

  "& .availability-form": {
    width: "100%",
  },

  "& .profile-arch": {
    width: "170px",
    marginBottom: "60px",
    "& img": {
      border: "1px solid black",
    },
    "& .profile-link": {
      fontSize: "11px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      position: "absolute",
      // backgroundColor: "black",
      left: 0,
      right: 0,
      margin: "auto",
      width: "100%",
      "& img": {
        border: "none",
      },
      "&:hover": {
        opacity: 0.6,
      },
    },
    "&:hover .show-on-hover": {
      opacity: 1,
      "& img": {
        border: "none",
      },
    },
  },

  ".column": {
    paddingBottom: "100px",

    width: "calc(50% - 250px)",
    "&.first": {
      // width: "calc(50% - 250px + $navWidth)",
    },
    "&.middle": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "500px",
      "& h1": {
        fontSize: "24px",
        fontFamily: "KeplerLightCondensed",
        fontWeight: "normal",
        textAlign: "center",
        marginTop: "40px",
      },
      "& h2": {
        fontSize: "12px",
        lineHeight: "1.2",
        fontWeight: "normal",
        marginTop: "6px",
        marginBottom: "20px",
        textAlign: "center",
      },
    },
  },

  "@mobile": {
    marginTop: "-32px",
    overflowX: "hidden",
    "& .page-title": {
      textAlign: "center",
    },
    "& h1": {
      marginTop: "12px !important",
    },
    paddingLeft: "6%",
    paddingRight: "6%",
  },
});
