import { styled } from "../style/stitches.config";

export const SecurePaymentsDisclaimer = ({
  text,
  style,
}: {
  text: string;
  style?: any;
}) => {
  return (
    <StyledDisclaimerContainer style={style}>
      <div className="disclaimer-info">
        <div className="line">
          <img src="/images/shield.svg" alt="shield" />

          {`Secure payments. ${text}`}
        </div>
      </div>
    </StyledDisclaimerContainer>
  );
};
export const DisclaimerContainer = ({ style }: { style?: any }) => {
  return (
    <StyledDisclaimerContainer style={style}>
      <div className="disclaimer-info">
        <div className="line">
          <img src="/images/shield.svg" alt="shield" />
          <span className="strong">Secure payments.</span>You're only charged
          after session is confirmed.
        </div>
        <div className="line">
          <img src="/images/shield.svg" alt="shield" />
          <span className="strong">Flexible refunds.</span>
          Get money back for cancellations.
        </div>
      </div>
    </StyledDisclaimerContainer>
  );
};

const StyledDisclaimerContainer = styled("div", {
  width: "100%",
  paddingTop: "10px",
  fontSize: "12px",

  "& .disclaimer-info": {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    marginTop: "0px",

    "& .line": {
      display: "flex",
      alignItems: "center",
      marginBottom: "8px",
      "& img": {
        marginRight: "6px",
        width: "20px",
        height: "20px",
      },
    },
  },
  "@mobile": {
    fontSize: "14px !important",
  },
});
