import axios from "./axiosConfig.js";

const PAYMENTS_ENDPOINT = "payments/";

interface StripeSecret {
  client_secret: string;
}

export interface PlusSubscription { 
  status: null | string;
  current_period_end?: string;
  cancel_at_period_end?: boolean;
}

export interface SessionPaymentIntentCreateParams {
  proId: string
  amount: number
  description: string
  metadata: Record<string, string>
}

export interface DecksPaymentIntentCreateParams {
  deckIds: string[]
  metadata: Record<string, string>
}

export interface OnboardConnectAccountParams {
  country: string
}

const createPortalSession = async (returnEndUrl: string) => {
  const host = window.location.origin;
  const returnUrl = host + returnEndUrl;
  const response = await axios.post(
    PAYMENTS_ENDPOINT + "create-portal-session/",
    { returnUrl }
  );
  if (response.status === 200) {
    // open in a new tab
    const url = response.data.url;
    window.open(url, "_blank");

  } else {
    throw new Error(response.statusText);
  }
};

const checkoutDeck = async(deckId:  string, deckName: string) => {

  const host = window.location.origin;
  const successUrl = host + "/decks?success=true&deck=" + deckName;
  const cancelUrl = host + "/decks";
  const response = await axios.post(
    PAYMENTS_ENDPOINT + "create-deck-checkout-session/",
    { successUrl, cancelUrl, deckId }
  );
  if (response.status === 200) {
    window.location.href = response.data.url;
  } else {
    throw new Error(response.statusText);
  }
}

const checkoutPlus = async () => {
  const host = window.location.origin;
  const successUrl = host + "/success";
  const cancelUrl = host + "/plans";
  const response = await axios.post(
    PAYMENTS_ENDPOINT + "create-plus-checkout-session/",
    { successUrl, cancelUrl }
  );
  if (response.status === 200) {
    window.location.href = response.data.url;
  } else {
    throw new Error(response.statusText);
  }
};

const getPlusSubscription = async (): Promise<PlusSubscription> => {
  const response = await axios.get(PAYMENTS_ENDPOINT + "plus-subscription/");
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(response.statusText);
  }
};

const getDeckSubscription = async(deckId: string): Promise<PlusSubscription> => {
  const response = await axios.get(PAYMENTS_ENDPOINT + "deck-subscription/" + deckId + "/");
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(response.statusText);
  }
};

const createSessionPaymentIntent = async (params: SessionPaymentIntentCreateParams): Promise<{proId: string, paymentIntentId: string, clientSecret: string}> =>  {
  const response = await axios.post(PAYMENTS_ENDPOINT + "create-session-payment-intent", params);
  if (response.status === 200) {
    const { proId, paymentIntentId, clientSecret } = response.data;
    return { proId, paymentIntentId, clientSecret }
  } else {
    throw new Error(response.statusText);
  }
}

const createDecksPaymentIntent = async (params: DecksPaymentIntentCreateParams): Promise<{paymentIntentId: string, clientSecret: string, paymentGroupId: string, deckIds: string[]}> => {
  const response = await axios.post(PAYMENTS_ENDPOINT + "create-decks-payment-intent", params);
  if (response.status === 200) {
    const {paymentIntentId, clientSecret, paymentGroupId, deckIds} = response.data;
    return {paymentIntentId, clientSecret, paymentGroupId, deckIds}
  } else {
    throw new Error(response.statusText);
  }
}

const onboardConnectAccount = async (params: OnboardConnectAccountParams): Promise<void> => {
  const response = await axios.post(PAYMENTS_ENDPOINT + "onboard-connect-account", params);
  if (response.status === 200) {
    const {onboardingLink} = response.data

    window.location.href = onboardingLink;
  } else {
    console.error('Unexpected response:',response);
    throw new Error(response.statusText);
  }
}

const unlinkConnectAccount = async () => {
  const response = await axios.post(PAYMENTS_ENDPOINT + "unlink-connect-account");
  if (response.status !== 200) {
    console.error('Unexpected response:',response);
  }
}

export {  getDeckSubscription, checkoutDeck, checkoutPlus, createPortalSession, getPlusSubscription, createSessionPaymentIntent, createDecksPaymentIntent, onboardConnectAccount, unlinkConnectAccount };
