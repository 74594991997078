import { keyframes, styled } from "~/ui/style/stitches.config";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "wouter";
import {
  PlusSubscription,
  checkoutPlus,
  createPortalSession,
  getPlusSubscription,
} from "~/api/paymentsApi";
import { Button } from "~/ui/components/Button";
import { PlusBadge } from "~/ui/menus/BackgroundMenu";
import { AuthContext } from "~/utils/useAuth";
import { HelpButton } from "~/ui/components/HelpButton";
import { localState } from "~/state/state";
import { useSnapshot } from "valtio";
import { SimpleLoadingIndicator } from "../loading/LoadingPage";
import { stripeTimestampToReadable } from "~/utils/datetime";
import { trackEvent } from "~/api/analyticsApi";

export const Success = () => {
  return (
    <StyledSuccessPage>
      <div className="success-container">
        <h1>You're now on plus!</h1>
        <p>
          Thanks for joining! To use enhanced tools, create a new tarot room or
          refresh your current one.
        </p>
        <Button
          onClick={() => {
            window.location.href = "/";
          }}
          className="continue-button"
          style={{
            width: "200px",
            height: "45px",
            color: "white",
            padding: "none",
            display: "flex",
            backgroundColor: "black",
            border: "none",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "400",
            fontSize: "13px",
          }}
        >
          Return home
        </Button>
      </div>
    </StyledSuccessPage>
  );
};

const StyledSuccessPage = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100vh",
  backgroundColor: "$wash",
  "& .continue-button": {
    transition: "all 0.2s ease-in-out",
    boxShadow: "rgb(213, 181, 253) 0px 0px 10px 1px",
    "&:hover": {
      boxShadow: "rgb(213, 181, 253) 0px 0px 15px 3px",
      backgroundColor: "$gray800 !important",
    },
  },
  "& .success-container": {
    marginTop: "-16px",
    backgroundColor: "white",
    boxShadow: "0px 3px 0px #000000",
    border: "1px solid black",
    borderRadius: "36px",
    width: "450px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "270px",
    "& h1": {
      width: "321px",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "500",
    },
    "& p": {
      marginBottom: "42px",
      marginTop: "18px",
      width: "321px",
      textAlign: "center",
      fontSize: "13px",
      lineHeight: "1.4",
    },
  },
});

const Feature = ({
  text,
  img,
  isPlus,
}: {
  text: string;
  img: string;
  isPlus?: boolean;
}) => {
  return (
    <div className="feature">
      <div className="feature-line">
        <img src={img} /> {text}
      </div>
      {isPlus ? (
        <PlusBadge
          style={{
            boxShadow: "none",
            paddingTop: "1px",
            color: "white",
            backgroundColor: "#535353",
          }}
          className="plus"
        >
          PLUS
        </PlusBadge>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const PlanInfo = ({
  name,
  description,
  price,
  isPro,
}: {
  name: string;
  price: string;
  description: string;
  isPro?: boolean;
}) => (
  <div className="plan-info">
    <h1>{name}</h1>

    <h2
      style={{
        textDecoration: isPro ? "line-through" : "unset",
        textDecorationThickness: isPro ? "1px" : "unset",
      }}
    >
      {price}
    </h2>

    <p
      dangerouslySetInnerHTML={{
        __html: isPro ? "free for pros" : description,
      }}
    ></p>
  </div>
);

export const BillingPage = () => {
  const { user } = useContext(AuthContext);
  const [_, setLocation] = useLocation();

  const handleCreateCheckout = async () => {
    checkoutPlus();
  };

  const handleCreatePortal = async () => {
    createPortalSession("/plans");
  };

  const { isOnMobile } = useSnapshot(localState);

  const [isOnPlus, setIsOnPlus] = useState(false);
  const [isPro, setIsPro] = useState(false);

  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const [plusSubscription, setPlusSubscription] = useState<PlusSubscription>({
    status: null,
  });
  const [hasLoadedPlanData, setHasLoadedPlanData] = useState(false);

  const [subscriptionString, setSubscriptionString] = useState<string>("");

  useEffect(() => {
    const readableTimestamp = stripeTimestampToReadable(
      plusSubscription.current_period_end
    );
    if (plusSubscription.status === "active") {
      if (plusSubscription.cancel_at_period_end) {
        setSubscriptionString("per month, expires " + readableTimestamp);
      } else {
        setSubscriptionString("per month, renews " + readableTimestamp);
      }
    } else {
      setSubscriptionString("per month");
    }
  }, [plusSubscription]);

  useEffect(() => {
    trackEvent("/plans/visited_page");
    (async function () {
      if (user) {
        const subscriptionData = await getPlusSubscription();
        setHasLoadedPlanData(true);
        setPlusSubscription(subscriptionData);
        setIsOnPlus(user.isPlus);
        setIsPro(user.isProfessional);
      } else {
        // if no data after 1 second, show page anyways
        window.setTimeout(() => {
          setHasLoadedPlanData(true);
        }, 1000);
      }
    })();
  }, [user]);

  return (
    <StyledBillingPage>
      {!hasLoadedPlanData ? (
        <SimpleLoadingIndicator />
      ) : (
        <>
          <Button
            onClick={() => setLocation("/")}
            transparent
            className="home-button"
            style={{
              border: "none",
              position: "fixed",
              padding: "0 16px 0 14px",
              height: "40px",
              top: "67px",
              left: "46px",
              transition: "all 0.2s ease-in-out",
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
              borderRadius: "1rem",

              zIndex: 999,
            }}
          >
            <span
              style={{
                marginRight: "8px",
                fontSize: "16px",
                fontWeight: "200",
              }}
              className="arrow"
            >
              &larr;
            </span>
            <span className="text">Home</span>
          </Button>

          <div className="plan-details">
            <h1>Choose a plan</h1>
            <h2>
              Customize your tarot experience with enhanced tools, built for the
              seasoned and new.
            </h2>
          </div>

          <div className="all-plans">
            <div className={`plan plus`} style={{ position: "relative" }}>
              <div className="badge">
                <div className="plus-info" style={{ fontStyle: "italic" }}>
                  {isOnPlus || isPro ? "Your plan" : "Hot!"}
                </div>
              </div>
              <PlanInfo
                name="Plus"
                price="$9"
                description={subscriptionString}
                isPro={isPro}
              />
              <Button
                style={{ fontSize: "13px", position: "relative" }}
                className="continue-button"
                onClick={(e) => {
                  //if you're not signed in, redirect to sign in page which will then redirect back to plans page after you're done
                  if (!user) {
                    setLocation("/signup?dest=/plans");
                    return;
                  }
                  if (isPro) setLocation("/");
                  else if (isOnPlus) {
                    if (isLoadingStripe) return;
                    setIsLoadingStripe(true);
                    handleCreatePortal();
                  } else {
                    if (isLoadingStripe) return;
                    trackEvent("/plans/pressed_subscribe");
                    setIsLoadingStripe(true);
                    handleCreateCheckout();
                  }
                }}
              >
                {isLoadingStripe ? (
                  <SimpleLoadingIndicator
                    style={{
                      top: 0,
                      width: "30px",
                      height: "30px",
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                    }}
                  />
                ) : isPro ? (
                  "Continue"
                ) : isOnPlus ? (
                  "Manage plan"
                ) : (
                  "Subscribe"
                )}
              </Button>
              <div className="features">
                <Feature text="host up to 6 people" img="/images/friends.svg" />
                <Feature
                  text="explore beautiful decks"
                  img="/images/decks.svg"
                />
                <Feature
                  text="realtime video & audio"
                  img="/images/video.svg"
                />
                <span
                  className="plus-features"
                  style={{ position: "relative" }}
                >
                  <Feature
                    text="search & filter cards"
                    img="/images/room/search.svg"
                    isPlus
                  />

                  <Feature
                    text="more custom themes"
                    img="/images/room/theme-tool.svg"
                    isPlus
                  />
                </span>
              </div>
            </div>
            <div className="plan free">
              <PlanInfo
                name="Free"
                price="$0"
                description="core features on us!"
              />
              <Button
                className="continue-button"
                onClick={() => {
                  setLocation("/");
                }}
                style={{
                  fontSize: "13px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Continue
              </Button>
              <div className="features">
                <Feature text="host up to 6 people" img="/images/friends.svg" />
                <Feature
                  text="explore beautiful decks"
                  img="/images/decks.svg"
                />
                <Feature
                  text="realtime video & audio"
                  img="/images/video.svg"
                />
                <Feature
                  text="add notes & spreads"
                  img="/images/room/type.svg"
                />
              </div>
            </div>
          </div>
        </>
      )}
      <HelpButton isOnBillingPage text={"Contact us"} />
    </StyledBillingPage>
  );
};

const spin = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
});

const StyledBillingPage = styled("div", {
  overflow: "scroll",
  backgroundColor: "$wash",
  backgroundSize: "cover",
  paddingTop: "120px",
  paddingBottom: "140px",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",

  "@mobile": {
    paddingTop: "100px",
    paddingLeft: "36px",
    paddingRight: "36px",
    paddingBottom: "100px",
  },

  height: "100vh",
  display: "flex",
  flexDirection: "column",

  "& .all-plans": {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    marginTop: "40px",
    "@mobile": {
      flexDirection: "column",
    },
  },

  "& .plan-cursor img": {
    animation: `${spin} 10s linear infinite`,
  },

  "& .plan-details": {
    width: "100%",
    textAlign: "center",
    borderRadius: "32px",

    "& h1": {
      fontSize: "18px",
      fontWeight: "normal",
      "& .i": {
        fontStyle: "italic",
      },
    },
    "& h2": {
      fontSize: "12px",
      fontWeight: "normal",
      marginTop: "8px",
      lineHeight: "1.4",
    },
  },
  "& .features": {
    marginTop: "25px",
    fontSize: "11px",
    width: "100%",
    marginLeft: "16px",

    "& .feature": {
      display: "flex",
      width: "100%",
      alignItems: "center",
      marginBottom: "12px",

      "& .feature-line": {
        display: "flex",
        alignItems: "center",
        width: "180px",
      },
      "& img": {
        marginRight: "12px",
      },
      "& .plus": {
        borderRadius: "4px",
        backgroundColor: "$lightPurple",
        color: "black",
        border: "none",
      },
    },
  },

  "& .home-button": {
    backgroundColor: "$wash",
    height: "32px !important",
    padding: "6px 8px !important",
    borderRadius: "10px !important",
    "@mobile": {
      left: "4px !important",
      top: "51px !important",
    },
  },

  "& .plan": {
    width: "300px",
    "& button": {
      width: "200px",
      marginTop: "12px",
      transition: "all 0.2s ease-in-out",
    },

    marginLeft: "8px",
    marginRight: "8px",
    "@mobile": {
      width: "100%",
      marginLeft: 0,
      marginRight: 0,
    },
    height: "485px",
    border: "1px solid black",
    borderRadius: "32px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    "&.plus": {
      backgroundColor: "black",
      color: "white",
      "& img": {
        filter: "invert(1)",
      },
      "& button": {
        color: "black",
        backgroundColor: "#EFC1FF",
        border: "1px solid #EFC1FF",
        "&:hover": {
          opacity: 0.85,
        },
      },
      transition: "all 0.2s ease-in-out",
      marginBottom: "12px",
      "& .badge": {
        position: "absolute",
        right: 0,
        top: "-14px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "& .plus-info": {
          backgroundColor: "white",
          color: "black",
          border: "1px solid black",
          padding: "6px 12px",
          borderRadius: "9px",
          fontWeight: "500",
          fontSize: "11px",
        },
      },
    },

    alignItems: "center",
    // padding: "0 50px",
    paddingTop: "50px",
    paddingLeft: "30px",
    paddingRight: "30px",

    "& .continue-button": {
      height: "50px !important",
    },

    "& .plan-info": {
      width: "100%",

      paddingLeft: "16px",
      paddingRight: "16px",
      marginBottom: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      "& h1": {
        fontSize: "45px",
        fontFamily: "GorgeousBaby",
        fontWeight: "normal",
        lineHeight: "1",
        display: "inline-flex",
      },
      "& h2": {
        fontSize: "27px",
        alignItems: "center",
        fontWeight: "300",
      },
      "& p": {
        fontSize: "11px",
        marginTop: "8px",
      },
    },
  },
});
