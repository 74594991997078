import { DayPicker, SelectSingleEventHandler } from "react-day-picker";
import { Service } from "~/api/calendarApi";
import { TimezoneMenu } from "~/ui/menus/TimezoneMenu";
import { ServiceAvailability, yyyyMMddToDate } from "~/utils/datetime";
import * as luxon from "luxon";

export const DatePickerPage = ({
  selectedService,
  name,
  selected,
  setSelected,
  onClick,
  timezone,
  setTimezone,
  setTimeSlot,
  meetingAvailability,
  setTimeSlotTimestamp,
}: {
  selectedService: Service | undefined;
  name: string;
  selected: Date | undefined;
  setSelected: Function;
  timezone: string;
  setTimezone: Function;
  onClick: Function;
  setTimeSlot: Function;
  setTimeSlotTimestamp: Function;
  meetingAvailability: ServiceAvailability | undefined;
}) => {
  return (
    <div
      className="inset-page"
      onClick={(e) => {
        // check if event target was outside tz-menu id
        if ((e.target as HTMLElement).id !== "tz-menu") {
          document.getElementById("tz-menu")!.classList.remove("open");
        }
      }}
    >
      <div className="service-info">
        <div className="name line">
          <img src="/images/moon.svg" alt="moon" />
          {`${selectedService?.name} ⸱ $${selectedService?.price}`}
        </div>
        <div className="person line">
          <img src="/images/profile.svg" alt="smiley" />
          {name}
        </div>
        <div className="duration line">
          <img src="/images/video.svg" alt="video" />
          {selectedService?.duration === 30 ? "30 min" : "1 hour"}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TimezoneMenu timezone={timezone} setTimezone={setTimezone} />
      </div>
      <div
        className="calendar"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "11",
          color: "lightgray",
        }}
      >
        {meetingAvailability && (
          <DayPicker
            mode="single"
            selected={selected}
            fromDate={yyyyMMddToDate(meetingAvailability.today)}
            defaultMonth={selected}
            toDate={yyyyMMddToDate(meetingAvailability.endDate)}
            onSelect={setSelected as SelectSingleEventHandler}
            weekStartsOn={1}
            modifiers={{
              today: [yyyyMMddToDate(meetingAvailability.today)],
              unbookable: [
                {
                  before: yyyyMMddToDate(meetingAvailability.today),
                },
                ...meetingAvailability.unavailableDates.map((d) =>
                  yyyyMMddToDate(d)
                ),
              ],
            }}
            modifiersStyles={{
              "booked-out": {
                backgroundImage: 'url("/images/slash.svg")',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              },
              unbookable: {
                backgroundImage: "none !important",
                backgroundColor: "transparent",
                opacity: 0.2,
                pointerEvents: "none",
              },
              today: {
                // color: "blue",
              },
            }}
          />
        )}
      </div>
      <div className="time-slots">
        {selected ? (
          <>
            <div className="date">
              {selected &&
                luxon.DateTime.fromJSDate(selected).toFormat("cccc, LLLL d")}
            </div>

            {meetingAvailability &&
              selectedService &&
              meetingAvailability.timeSlots[
                luxon.DateTime.fromJSDate(selected).toFormat("yyyyMMdd")
              ] &&
              meetingAvailability.timeSlots[
                luxon.DateTime.fromJSDate(selected).toFormat("yyyyMMdd")
              ]
                .filter((timeSlot) =>
                  timeSlot.serviceDurations.includes(selectedService?.duration)
                )
                .map((timeSlot, i) => {
                  return (
                    <div
                      key={`${timeSlot.readableTime}`}
                      className="time-slot black-button"
                      onClick={() => {
                        onClick();
                        setTimeSlotTimestamp(timeSlot.startTime);
                        setTimeSlot(
                          ` ${timeSlot.readableTime} - ${
                            timeSlot.readableEndTimes[selectedService?.duration]
                          }`
                        );
                      }}
                    >
                      {`${timeSlot.readableTime}`}
                    </div>
                  );
                })}
          </>
        ) : (
          <div className="select-message">
            Select a day to see available times.
          </div>
        )}
      </div>
    </div>
  );
};
