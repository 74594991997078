import { useContext, useState } from "react";
import { Service } from "~/api/calendarApi";
import { UserProfile } from "~/api/userApi";
import { DisclaimerContainer } from "~/ui/components/DisclaimerContainer";
import { StripeCheckout } from "~/ui/components/StripeCheckout";
import { styled } from "~/ui/style/stitches.config";
import { AuthContext } from "~/utils/useAuth";

export const PaymentPage = ({
  selectedService,
  sessionStartTime,
  pro,
  userUuid,
  useremail,
  stripeConnectId,
  onSuccess,
  accentColor,
}: {
  selectedService: Service | undefined;
  sessionStartTime: string,
  pro: UserProfile;
  userUuid: string;
  stripeConnectId: string;
  onSuccess: (paymentId: string) => void;
  accentColor: string;
  useremail: string;
}) => {
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const { user } = useContext(AuthContext)

  return (
    <StyledSubmittingPaymentPage className="inset-page">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div className="top-container">
          <div className="title">{selectedService?.name}</div>
          <div className="subtitle">
            You won't be charged until your booking request is accepted.
          </div>
          <div className="total-line">
            <div className="total-label">Total</div>
            <div className="total-price">${selectedService?.price} USD</div>
          </div>
        </div>

        <StripeCheckout
          pro={pro}
          useremail={useremail}
          amount={selectedService?.price! * 100} // Service price in cents
          stripeConnectId={stripeConnectId}
          description={`${selectedService?.name} with ${pro.name}`}
          metadata={{
            serviceId: selectedService?.id || "",
            hostUserId: pro.uuid,
            hostUsername: pro.username,
            requesterId: userUuid,
            requesterUsername: user?.username || "",
            startTime: sessionStartTime,
          }}
          buttonStyle={{
            backgroundColor: accentColor,
            fontSize: "14px",
            height: "52px",
            marginTop: "24px",
          }}
          isSubmittingPayment={isSubmittingPayment}
          setIsSubmittingPayment={setIsSubmittingPayment}
          onSuccess={onSuccess}
        />
      </div>
      <DisclaimerContainer
        style={{
          fontSize: "11px",
          textWrap: "nowrap",
          marginTop: "30px",
        }}
      />
    </StyledSubmittingPaymentPage>
  );
};

const StyledSubmittingPaymentPage = styled("div", {
  "& .top-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    width: "100%",
    "& .title": {
      fontSize: "15px",
      "@mobile": {
        fontSize: "16px",
      },
      fontWeight: "500",
    },
    "& .subtitle": {
      fontSize: "13px",
      "@mobile": {
        fontSize: "14px",
      },
      marginTop: "3px",
      width: "101%",
    },
    "& .total-line": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginTop: "20px",
      marginBottom: "30px",
      fontSize: "18px",

      "& .total-price": {
        fontWeight: "500",
      },
    },
  },
});
