import { keyframes, styled } from "~/ui/style/stitches.config";

export const CardLoadingSvg = () => {
  return (
    <StyledLoadingSvg>
      <rect
        className="card-load-gradient-bg"
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill={"rgba(180, 180, 180, 0.2)"}
      />
      <rect
        className="card-load-gradient"
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="rgba(180, 180, 180, 0.3)"
      />
    </StyledLoadingSvg>
  );
};

const fadeinout = keyframes({
  "0%": {
    opacity: 0,
  },
  "50%": {
    opacity: 1,
  },
  "100%": {
    opacity: 0,
  },
});

const StyledLoadingSvg = styled("svg", {
  ".card-load-gradient": {
    animation: `${fadeinout} 2s linear infinite`,
  },
});
