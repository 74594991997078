import { useContext } from "react";
import { GUIDELINES_LAST_UPDATED } from "~/assets/guidelines";
import { GuidelinesScrollContainer } from "~/ui/components/GuidelinesScrollContainer";
import { sendFriendlyToast } from "~/utils/handleToasts";
import { AuthContext } from "~/utils/useAuth";

export const GuidelinesConfirmPage = ({
  isLoggedIn,
  onConfirm,
}: {
  isLoggedIn: boolean;
  onConfirm: Function;
}) => {
  const { updateUser } = useContext(AuthContext);
  return (
    <div className="inset-page">
      <div className="service-info guidelines">
        Please read and agree to our guidelines before participating in
        services.
      </div>
      <GuidelinesScrollContainer
        onClickAgree={() => {
          // if the user is not currently signed in, we can't save their updated guidelines
          if (isLoggedIn)
            updateUser({ acceptedTerms: GUIDELINES_LAST_UPDATED }).then(() => {
              sendFriendlyToast(1152);
              onConfirm();
            });
          else onConfirm();
        }}
      ></GuidelinesScrollContainer>
    </div>
  );
};
