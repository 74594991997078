import { useLocation } from "wouter";
import {
  useMutateRoomState,
  useMutateStorage,
  useStorage,
} from "../../state/liveblocks.config";
import { Button } from "../components/Button";
import {
  StyledMenuList,
  StyledSpreadSelectItem,
  ConfirmMenu,
} from "./StyledMenus";
import { LiveList } from "@liveblocks/client";
import { RoomState, RoomTheme } from "~/types/room/types";
import { localState } from "~/state/state";
import React, { useState } from "react";
import { styled } from "~/ui/style/stitches.config";

export function ActionMenuItem({
  text,
  theme,
  onClick,
}: {
  text: string;
  theme: RoomTheme;
  onClick: Function;
}) {
  return (
    <StyledSpreadSelectItem Theme={theme}>
      <Button
        Theme={theme}
        onClick={onClick as React.MouseEventHandler}
        transparent
        transparentHover
        menuOption
      >
        {text}
      </Button>
    </StyledSpreadSelectItem>
  );
}

function ActionSecondaryMenu({
  contents,
  onBack,
  theme,
}: {
  contents: JSX.Element;
  onBack: Function;
  theme: RoomTheme;
}) {
  return (
    <div>
      <Button
        Theme={theme}
        className="arrow-button"
        role="menuitem"
        style={{
          padding: "4px",
          margin: "4px",
          marginTop: "0",
          marginBottom: "-6px",
          width: "25px",
        }}
        transparent
        onClick={onBack as React.MouseEventHandler}
      >
        &larr;
      </Button>
      {contents}
    </div>
  );
}

export function ActionConfirmMenu({
  confirmMessage,
  onConfirm,
  onBack,
  theme,
}: {
  confirmMessage: string;
  onConfirm: Function;
  onBack: Function;
  theme: RoomTheme;
}) {
  return (
    <ActionSecondaryMenu
      contents={
        <ConfirmMenu
          padding={10}
          theme={theme}
          onConfirm={onConfirm}
          messageText={confirmMessage}
          confirmButtonText={"Continue"}
        ></ConfirmMenu>
      }
      onBack={onBack}
      theme={theme}
    />
  );
}

export function ActionsMenu() {
  const [, setLocation] = useLocation();
  const updateStorage = useMutateStorage();
  const setRoomState = useMutateRoomState();
  const theme = useStorage((root) => root.theme);
  const spread = useStorage((root) => root.spread);
  const [confirmMenu, setConfirmMenu] = useState<JSX.Element | null>(null);

  const clearTable = () => {
    localState.lastViewedCard = null;
    // Remove cards from spread slots
    let newSpread = { ...spread };
    newSpread.slots.forEach((slot) => {
      slot.cards = null;
    });

    // Remove cards from local active spread
    localState.activeSpread.slots.forEach((slot) => {
      slot.cards = null;
    });

    updateStorage((storage) => {
      // Remove card from cards in spread
      storage.set("cards", new LiveList([]));
    });

    updateStorage((storage) => {
      // Re-set spread card slots
      storage.set("spread", newSpread);
    });
  };

  const importSpreadMenu = (
    <StyledMenuList Theme={theme}>
      <StyledImporter Theme={theme}>
        <textarea id="import-json" placeholder="Paste JSON here..." />
        <Button
          Theme={theme}
          onClick={() => {
            updateStorage((storage) => {
              try {
                const json = document.getElementById(
                  "import-json"
                ) as HTMLTextAreaElement;
                const parsed = JSON.parse(json.value);
                storage.set("spread", parsed);
              } catch (e) {
                console.error(e);
              }
            });
          }}
        >
          Import
        </Button>
      </StyledImporter>
    </StyledMenuList>
  );

  return confirmMenu ? (
    <StyledMenuList Theme={theme}>{confirmMenu}</StyledMenuList>
  ) : (
    <StyledMenuList Theme={theme}>
      <ActionMenuItem
        text={"Clear table"}
        theme={theme}
        onClick={() =>
          setConfirmMenu(
            <ActionConfirmMenu
              confirmMessage={"Move all cards back to the deck?"}
              theme={theme}
              onConfirm={() => {
                clearTable();
                setConfirmMenu(null);
              }}
              onBack={() => setConfirmMenu(null)}
            ></ActionConfirmMenu>
          )
        }
      />
      <ActionMenuItem
        text={"Restart room"}
        theme={theme}
        onClick={() =>
          setConfirmMenu(
            <ActionConfirmMenu
              confirmMessage={"Clear all cards and reset the deck?"}
              theme={theme}
              onConfirm={() => {
                clearTable();
                setRoomState(RoomState.Ready);
                setConfirmMenu(null);
              }}
              onBack={() => setConfirmMenu(null)}
            ></ActionConfirmMenu>
          )
        }
      />
      <ActionMenuItem
        text={"Import spread (beta)"}
        theme={theme}
        onClick={() =>
          setConfirmMenu(
            <ActionSecondaryMenu
              contents={importSpreadMenu}
              onBack={() => setConfirmMenu(null)}
              theme={theme}
            ></ActionSecondaryMenu>
          )
        }
      />
      <ActionMenuItem
        text={"→ Leave"}
        theme={theme}
        onClick={() =>
          setConfirmMenu(
            <ActionConfirmMenu
              theme={theme}
              confirmMessage={
                "End connection? This won’t affect other participants."
              }
              onConfirm={() => {
                setLocation("/");
                setConfirmMenu(null);
              }}
              onBack={() => setConfirmMenu(null)}
            ></ActionConfirmMenu>
          )
        }
      />
    </StyledMenuList>
  );
}

const StyledImporter = styled("div", {
  backgroundColor: "$wash",
  borderRadius: "$4",
  padding: "$2",
  display: "grid",
  gap: "$2",
  "& label": {
    fontSize: "$m",
  },
  variants: {
    Theme: {
      light: {
        backgroundColor: "$wash",
        color: "black",
      },
      dark: {
        backgroundColor: "$gray800",
        color: "white",
      },
    },
  },
});
