import {
  animate,
  motion,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { styled } from "~/ui/style/stitches.config";
import { Button } from "~/ui/components/Button";
import { addV2d, divV2d, subV2d } from "~/utils/math";
import { Vec2d } from "../../utils/useMousePosition";
import { useRequireAuth } from "~/utils/useRequireAuth";

interface SpreadSlot {
  id: number;
  name: string;
  bounds?: Vec2d[];
  description: string;
  position: Vec2d;
  rotated?: boolean;
}
interface Spread {
  name: string;
  description: string;
  slots: SpreadSlot[];
}

const CARD_SIZE: Vec2d = [150, 275];
const DEFAULT_SPREAD_SIZE_UNITS: Vec2d = [10, 10];
const GRID_PADDING = 20;

export function Spreaditor() {
  const _auth = useRequireAuth();
  const [spread, setSpread] = useState<Spread>({
    name: "New spread",
    description: "My spread description",
    slots: [],
  });
  const [selectedSlotIndex, setSelectedSlotIndex] = useState<number | null>(
    null
  );
  const selectedSlot = spread.slots.find((s) => s.id === selectedSlotIndex);
  function updateSlot(index: number, setter: (slot: SpreadSlot) => SpreadSlot) {
    setSpread({
      ...spread,
      slots: spread.slots.map((s) => (s.id === index ? setter(s) : s)),
    });
  }
  const deleteSelectedSlot = useCallback((i: number) => {
    setSpread((prev) => ({
      ...prev,
      slots: prev.slots.filter((s) => i !== s.id),
    }));
    setSelectedSlotIndex(null);
  }, []);
  useEffect(() => {
    window.scrollTo(
      (DEFAULT_SPREAD_SIZE_UNITS[0] * CARD_SIZE[0]) / 2 - window.innerWidth / 2,
      (DEFAULT_SPREAD_SIZE_UNITS[1] * CARD_SIZE[1]) / 2 - window.innerHeight / 2
    );
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === "Backspace") {
        if (
          document.activeElement?.tagName !== "INPUT" &&
          document.activeElement?.tagName !== "TEXTAREA" &&
          selectedSlotIndex !== null
        ) {
          deleteSelectedSlot(selectedSlotIndex);
        }
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);
  // Calculate minimum and maximum bounds of spread.slots
  const topLeftMostSlot = spread.slots.reduce(
    (acc, slot) => ({
      x: Math.min(acc.x, slot.position[0]),
      y: Math.min(acc.y, slot.position[1]),
    }),
    { x: Infinity, y: Infinity }
  );
  const bottomRightMostSlot = spread.slots.reduce(
    (acc, slot) => ({
      x: Math.max(acc.x, slot.position[0]),
      y: Math.max(acc.y, slot.position[1]),
    }),
    { x: -Infinity, y: -Infinity }
  );
  const bounds: Vec2d[] = [
    [topLeftMostSlot.x, topLeftMostSlot.y],
    [bottomRightMostSlot.x + 1, bottomRightMostSlot.y + 1],
  ];
  return (
    <>
      <StyledCanvas
        onPointerDown={(e) =>
          e.target === e.currentTarget && setSelectedSlotIndex(null)
        }
      >
        <StyledPlus />
        {spread.slots.map((slot) => (
          <DraggableSlot
            slot={slot}
            slots={spread.slots}
            key={slot.id}
            index={slot.id}
            onChange={updateSlot}
            selected={slot.id === selectedSlotIndex}
            onSelect={() => {
              setSelectedSlotIndex(slot.id);
              // move selected slot to the end of the array so it's on top
              setSpread({
                ...spread,
                slots: spread.slots
                  .filter((s) => s.id !== slot.id)
                  .concat(slot),
              });
            }}
            onDelete={() => deleteSelectedSlot(slot.id)}
          />
        ))}
      </StyledCanvas>
      <StyledInspector>
        <div>
          <h3>Spread</h3>
          <StyledLabel>
            Name
            <StyledInput
              type="text"
              value={spread.name}
              onChange={(e) =>
                setSpread((s) => ({
                  ...s,
                  name: e.target.value,
                }))
              }
            />
          </StyledLabel>
          <StyledLabel>
            Description
            <StyledInput
              type="text"
              value={spread.description}
              onChange={(e) =>
                setSpread((s) => ({
                  ...s,
                  description: e.target.value,
                }))
              }
            />
          </StyledLabel>
        </div>
        {selectedSlot && selectedSlotIndex !== null && (
          <div>
            <h3>Slot</h3>
            <StyledLabel>
              Name
              <StyledInput
                type="text"
                value={selectedSlot.name}
                onChange={(e) =>
                  updateSlot(selectedSlotIndex, (s) => ({
                    ...s,
                    name: e.target.value,
                  }))
                }
              />
            </StyledLabel>
            <StyledLabel>
              Description
              <StyledInput
                type="text"
                value={selectedSlot.description}
                onChange={(e) =>
                  updateSlot(selectedSlotIndex, (s) => ({
                    ...s,
                    description: e.target.value,
                  }))
                }
              />
            </StyledLabel>

            <StyledLabel>
              X
              <StyledInput
                type="number"
                value={selectedSlot.position[0]}
                onChange={(e) =>
                  updateSlot(selectedSlotIndex, (s) => ({
                    ...s,
                    position: [Number(e.target.value), s.position[1]],
                  }))
                }
              />
            </StyledLabel>
            <StyledLabel>
              Y
              <StyledInput
                type="number"
                value={selectedSlot.position[1]}
                onChange={(e) =>
                  updateSlot(selectedSlotIndex, (s) => ({
                    ...s,
                    position: [s.position[0], Number(e.target.value)],
                  }))
                }
              />
            </StyledLabel>
            <StyledLabel horizontal>
              Rotated
              <input
                type="checkbox"
                style={{
                  appearance: "auto",
                }}
                checked={selectedSlot.rotated}
                onChange={(e) =>
                  updateSlot(selectedSlotIndex, (s) => ({
                    ...s,
                    rotated: e.target.checked,
                  }))
                }
              />
            </StyledLabel>
          </div>
        )}
        <div>
          <summary>
            <h3>Export JSON</h3>
          </summary>
          <textarea
            onClick={(e) => e.currentTarget.select()}
            readOnly
            style={{
              resize: "none",
              background: "$grayA100",
              border: "none",
              height: "200px",
              fontFamily: "SF Mono, monospace",
            }}
            value={JSON.stringify(
              {
                ...spread,
                slots: spread.slots.map(({ id, ...slot }) => ({
                  ...slot,
                  position: subV2d(slot.position, bounds[0]),
                })),
                size: subV2d(bounds[1], bounds[0]),
              },
              null,
              2
            )}
          ></textarea>
        </div>
      </StyledInspector>

      <StyledToolbar>
        <Button
          onClick={() => {
            const id = Math.round(Math.random() * 1000000);
            setSpread((s) => ({
              ...s,
              slots: [
                ...s.slots,
                {
                  id,
                  name: "Position title",
                  description: "A short description",
                  position: [
                    roundToHalf(
                      (window.scrollX +
                        window.innerWidth / 2 -
                        CARD_SIZE[0] / 2) /
                        CARD_SIZE[0]
                    ),
                    roundToHalf(
                      (window.scrollY +
                        window.innerHeight / 2 -
                        CARD_SIZE[1] / 2) /
                        CARD_SIZE[1]
                    ),
                  ],
                },
              ],
            }));
            setSelectedSlotIndex(id);
          }}
        >
          Add slot
        </Button>
        <Button
          onClick={() => {
            const v = prompt("JSON");
            if (!v) return;
            const imported = JSON.parse(v) as Spread & {
              size: Vec2d;
            };
            const offset = subV2d(
              divV2d(DEFAULT_SPREAD_SIZE_UNITS, [2, 2]),
              divV2d(imported.size, [2, 2])
            );
            setSpread({
              ...imported,
              slots: imported.slots.map((s) => ({
                ...s,
                id: Math.round(Math.random() * 1000000),
                position: addV2d(s.position, offset),
              })),
            } as Spread);
          }}
        >
          Import
        </Button>
      </StyledToolbar>
    </>
  );
}

function DraggableSlot({
  slot,
  index,
  onChange,
  onSelect,
  selected,
  slots,
  onDelete,
}: {
  slot: SpreadSlot;
  index: number;
  onChange: (i: number, setter: (slot: SpreadSlot) => SpreadSlot) => void;
  onSelect: () => void;
  selected: boolean;
  slots: SpreadSlot[];
  onDelete: () => void;
}) {
  const x = useMotionValue(slot.position[0]);
  const y = useMotionValue(slot.position[1]);
  const xPx = useTransform(x, (v) => v * CARD_SIZE[0]);
  const yPx = useTransform(y, (v) => v * CARD_SIZE[1]);
  const start = useRef<Vec2d>(slot.position);
  const xPxRounded = useTransform(x, (v) => roundToHalf(v) * CARD_SIZE[0]);
  const yPxRounded = useTransform(y, (v) => roundToHalf(v) * CARD_SIZE[1]);
  const [isDragging, setIsDragging] = useState(false);
  const [previewOverlap, setPreviewOverlap] = useState(false);
  const isOverlapping = isDragging ? previewOverlap : slot.rotated;

  return (
    <>
      <motion.div
        style={{
          pointerEvents: "none",
          x: xPxRounded,
          y: yPxRounded,
          position: "absolute",
          opacity: 0,
        }}
        animate={{
          opacity: isDragging ? 0.1 : 0,
        }}
      >
        <StyledShadow
          style={{
            width: CARD_SIZE[0] - 1,
            height: CARD_SIZE[1] - 1,
            marginTop: 1,
            marginLeft: 1,
            transform: `rotateZ(${isOverlapping ? 90 : 0}deg)`,
          }}
        ></StyledShadow>
      </motion.div>
      <motion.div
        onPointerDown={onSelect}
        onPanStart={(e, info) => {
          start.current = [x.get(), y.get()];
          setIsDragging(true);
        }}
        onPan={(e, info) => {
          const xUnit = start.current[0] + info.offset.x / CARD_SIZE[0];
          const yUnit = start.current[1] + info.offset.y / CARD_SIZE[1];
          x.set(xUnit);
          y.set(yUnit);

          // Preview rotation
          const previewRotation =
            slots.find(
              (s) =>
                s !== slot &&
                s.position[0] === roundToHalf(xUnit) &&
                s.position[1] === roundToHalf(yUnit)
            ) !== undefined;
          setPreviewOverlap(previewRotation);
        }}
        onPanEnd={(e, info) => {
          setIsDragging(false);
          const xRounded = roundToHalf(
            start.current[0] + info.offset.x / CARD_SIZE[0]
          );
          const yRounded = roundToHalf(
            start.current[1] + info.offset.y / CARD_SIZE[1]
          );
          animate(x, xRounded);
          animate(y, yRounded);
          onChange(index, (s) => ({
            ...s,
            position: [xRounded, yRounded],
            rotated: isOverlapping,
          }));
        }}
        style={{
          x: xPx,
          y: yPx,
          position: "absolute",
          z: isOverlapping ? 1 : 0,
        }}
        animate={{
          rotateZ: isOverlapping ? 90 : 0,
        }}
      >
        <StyledSlot
          style={{
            width: CARD_SIZE[0] - GRID_PADDING,
            height: CARD_SIZE[1] - GRID_PADDING,
            margin: GRID_PADDING / 2,
            position: "relative",
          }}
          selected={selected}
        >
          <StyledSlotContent
            style={{
              width: CARD_SIZE[isOverlapping ? 1 : 0] - GRID_PADDING,
              height: CARD_SIZE[isOverlapping ? 0 : 1] - GRID_PADDING,
              x: "-50%",
              y: "-50%",
              rotate: isOverlapping ? -90 : 0,
            }}
          >
            <StyledSlotLabel
              value={slot.name}
              onChange={(e) =>
                onChange(index, (s) => ({
                  ...s,
                  name: e.target.value,
                }))
              }
            />
            <StyledSlotDescription
              value={slot.description}
              onChange={(e) =>
                onChange(index, (s) => ({
                  ...s,
                  description: e.target.value,
                }))
              }
            />
            {selected && (
              <Button
                transparent
                small
                style={{
                  position: "absolute",
                  top: ".5rem",
                  right: ".5rem",
                }}
                onClick={onDelete}
              >
                &times;
              </Button>
            )}
          </StyledSlotContent>
        </StyledSlot>
      </motion.div>
    </>
  );
}

const StyledCanvas = styled("main", {
  backgroundColor: "$wash",
  background: `
  repeating-linear-gradient(transparent, transparent 50%, $grayA200 50%, $grayA200 calc(50% + 1px), transparent calc(50% + 1px)),
  repeating-linear-gradient(to right, transparent, transparent 50%, $grayA200 50%, $grayA200 calc(50% + 1px), transparent calc(50% + 1px))`,
  width: CARD_SIZE[0] * DEFAULT_SPREAD_SIZE_UNITS[0],
  height: CARD_SIZE[1] * DEFAULT_SPREAD_SIZE_UNITS[1],
  position: "relative",
});
const StyledPlus = styled("div", {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "$5",
  height: "$5",
  background: `repeating-linear-gradient(transparent, transparent 50%, black 50%, black calc(50% + 1px), transparent calc(50% + 1px)),
  repeating-linear-gradient(to right, transparent, transparent 50%, black 50%, black calc(50% + 1px), transparent calc(50% + 1px))`,
});

const StyledInspector = styled("aside", {
  position: "fixed",
  right: 0,
  top: 0,
  bottom: 0,
  padding: "$6",
  overflowY: "auto",
  display: "grid",
  gap: "$6",
  alignContent: "start",
  "& > *": {
    display: "grid",
    gap: "$2",
  },
  "& h3": {
    fontSize: "$sm",
    textTransform: "uppercase",
  },
});

const StyledLabel = styled("label", {
  display: "grid",
  gap: "$1",
  fontSize: "$sm",
  variants: {
    horizontal: {
      true: {
        gridTemplateColumns: "1fr auto",
      },
    },
  },
});
const StyledInput = styled("input", {
  padding: "$2",
  background: "white",
});

const StyledSlot = styled("div", {
  background: "white",
  borderRadius: CARD_SIZE[0] / 10,
  cursor: "grab",
  "&:active": {
    cursor: "grabbing",
  },
  variants: {
    selected: {
      true: {
        boxShadow: "0 0 0 2px black",
      },
      false: {
        boxShadow: "0 0 0 1px black",
      },
    },
  },
});
const StyledSlotContent = styled(motion.div, {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "$5 $1",
  display: "grid",
  alignContent: "end",
  placeItems: "center",
  textAlign: "center",
  userSelect: "none",
});
const StyledSlotLabel = styled("input", {
  width: "100%",
  textAlign: "center",
  fontFamily: "KeplerLightCondensed",
  fontStyle: "italic",
  fontSize: "$lg",
  letterSpacing: "-0.06ch",
  marginBottom: "-$1",
  background: "transparent",
  outline: "none",
});
const StyledSlotDescription = styled("input", {
  width: "100%",
  textAlign: "center",
  fontSize: "$xs",
  wordBreak: "break-word",
  background: "transparent",
  outline: "none",
});
const StyledShadow = styled("div", {
  background: "black",
  borderRadius: CARD_SIZE[0] / 10,
});
const StyledToolbar = styled("div", {
  position: "fixed",
  bottom: 0,
  left: "$2", // offset for scrollbar
  right: 0,
  padding: "$2",
  display: "flex",
  gap: "$2",
  placeContent: "center",
  pointerEvents: "none",
  "& > *": {
    pointerEvents: "auto",
  },
});

// Rounds a number to 0 or .5
function roundToHalf(n: number) {
  return Math.round(n * 2) / 2;
}
