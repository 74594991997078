import { sendAnalyticsEvent } from "../utils/analytics.js";
import axios from "./axiosConfig.js";

export interface UserProfile {
  username : string
  uuid: string
  name : string
  email : string
  bio: string
  profilePic: string
  favorites: string[]
  location: string
  tagline: string
  itemPic: string
  animalPic: string
  pronouns: string
  languages: string
  marqueeText: string
  twitter: string
  instagram: string
  facebook: string
  tiktok: string
  youtube: string
  twitch: string
  website: string
  recommendedLinks: string[]
  moreInfo: string[]
  backgroundColor: string
  accentColor: string
  isProfessional: boolean
  isPlus: boolean
  isMuted: boolean
  isCameraOff: boolean
  decks: string[]
  tags?: string[]
  calId: string
  acceptedTerms: string
  selectedGoogleId: string
  gcalIsAuthorized: boolean
  timeZone: string
  bookingHeader: string
  proDetails?: ProDetails
  acceptingPayments?: boolean
  stripeRequiredActions?: string[]
}

interface ProDetails {
  id: string
  userId: string
  stripeConnectId: string | null
  approvedForServiceListing: boolean
  hasVideo: boolean | null
}

const USER_ENDPOINT = "users/"

// This should only be called from within the auth context flow
const getCurrentUser = async () : Promise<UserProfile> => {
  const response = await axios.get(USER_ENDPOINT)
  if (response.status === 200) {
    return response.data as UserProfile
  } else {
    throw new Error(response.statusText)
  }
}

const getProUsers = async() : Promise<UserProfile[]> => {
  const response = await axios.get(USER_ENDPOINT + "pros/")
  return (response.data as UserProfile[]).sort(() => Math.random() - 0.5);
}

const getUser = async (uuid : string) : Promise<UserProfile> => {
  const response = await axios.get(USER_ENDPOINT + "id/" + uuid)
  if (response.status === 200) {
    return response.data as UserProfile
  } else {
    throw new Error(response.statusText)
  }
}

const getUserByUsername = async (username : string) : Promise<UserProfile> => {
  const response = await axios.get(USER_ENDPOINT + username)
  if (response.status === 200) {
    return response.data as UserProfile
  } else {
    throw new Error(response.statusText)
  }
}

// Note: this update should only be called via the auth flow.
const updateUser = async (updateUserData: Partial<UserProfile>): Promise<UserProfile> => {
  const response = await axios.put(USER_ENDPOINT, updateUserData)
  if (response.status === 200) {
    sendAnalyticsEvent('edited_profile');
  }
  if (response.status !== 200) {
    throw new Error(response.statusText)
  }
  return response.data
}

// Note: this update should only be called via the auth flow.
const processDeckPayment = async (deckIds: string[], paymentIntentId: string, paymentGroupId: string): Promise<UserProfile> => {
  const response = await axios.post(USER_ENDPOINT + "decks", {
    deckIds,
    paymentIntentId,
    paymentGroupId
  })
  if (response.status !== 200) {
    throw new Error(response.statusText)
  }
  return response.data
}

export { getProUsers, getCurrentUser, getUser, getUserByUsername, updateUser, processDeckPayment }