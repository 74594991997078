import React, {
  useState,
  useEffect,
  FunctionComponent,
  useContext,
} from "react";
import { useLocation } from "wouter";
import { AxiosError } from "axios";
import { UserProfile, getUserByUsername } from "~/api/userApi";
import { BIO_CHAR_LIMIT, DISPLAY_NAME_CHAR_LIMIT } from "~/utils/consts";
import { AuthContext } from "~/utils/useAuth";
import { localState } from "~/state/state";
import { Message } from "~/ui/components/Message";
import { ProfilePicChooser } from "~/ui/components/ProfilePicChooser";
import { SimpleLoadingIndicator } from "../loading/LoadingPage";
import { keyframes, styled } from "~/ui/style/stitches.config";
import { ProfileEditButtons } from "../../ui/components/ProfileEditButtons";
import { StyledFormPage } from "../login/Auth";
import { StyledForm } from "~/ui/components/auth/SignUp";
import { clearToast, sendFriendlyToast } from "~/utils/handleToasts";
import { imageUrl } from "~/utils/imageurl";

type State = {
  uuid: string;
  username: string;
  name: string;
  bio: string;
  profilePic: string;
  isSubmitting: boolean;
  errorMessage: string | null;
  favorites: string[];
  location: string;
};

const initialState: State = {
  uuid: "",
  username: "",
  name: "",
  bio: "",
  profilePic: "",
  isSubmitting: false,
  errorMessage: "",
  favorites: [],
  location: "",
};

interface ProfileProps {
  username?: string;
}

const defaultProps: ProfileProps = {
  username: "",
};

const UserPage = ({ pageUser }: { pageUser: UserProfile }) => {
  const [data, setData] = useState(initialState);
  const { user, updateUser } = useContext(AuthContext);
  let [, setLocation] = useLocation();
  const [savedData, setSavedData] = useState(initialState);
  const [editingProfile, setEditingProfile] = useState(false);
  const [myProfile, setMyProfile] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [tempProfilePic, setTempProfilePic] = useState("");
  const [favoritesUpdated, setFavoritesUpdated] = useState(false);
  const [favorites, setFavorites] = useState<string[]>([]);

  const [profileLoaded, setProfileLoaded] = useState(false);

  useEffect(() => {
    if (editingProfile) clearToast();
  }, [editingProfile]);

  useEffect(() => {
    setMyProfile(pageUser.username === user?.username);
  }, [pageUser.username, user?.uuid]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const resetForm = () => {
    setData(savedData);
  };

  const handleUpdateProfile = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });

    try {
      await updateUser({
        name: data.name,
        bio: data.bio,
        location: data.location ? data.location : " ",
        profilePic: tempProfilePic,
      });
      setEditingProfile(false);
      setProfilePic(tempProfilePic);
      localState.editedProfilePic = true;
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: null,
      });
      setSavedData({
        ...data,
        isSubmitting: false,
        errorMessage: null,
      });
      // Profile updated successfully
      sendFriendlyToast(1133);
    } catch (error) {
      let errorMessage = null;
      if (error instanceof AxiosError) {
        errorMessage = error.message;
      }
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (!pageUser.profilePic) setProfileLoaded(true);
    setData({
      ...data,
      uuid: pageUser.uuid,
      username: pageUser.username,
      name: pageUser.name,
      bio: pageUser.bio,
      profilePic: pageUser.profilePic,
      favorites: pageUser.favorites,
      location: pageUser.location,
    });
    setSavedData({
      ...data,
      uuid: pageUser.uuid,
      username: pageUser.username,
      name: pageUser.name,
      bio: pageUser.bio,
      profilePic: pageUser.profilePic,
      favorites: pageUser.favorites,
      location: pageUser.location,
    });
    setProfilePic(pageUser.profilePic);
    setTempProfilePic(pageUser.profilePic);

    setFavorites(pageUser.favorites);
    if (favoritesUpdated) setFavoritesUpdated(false);
    setProfileLoaded(true);
  }, [pageUser.username, favoritesUpdated]);

  const [linkCopied, setLinkCopied] = useState(false);
  useEffect(() => {
    if (linkCopied) window.setTimeout(() => setLinkCopied(false), 3000);
  }, [linkCopied]);

  return (
    <>
      {!profileLoaded && (
        <div className="ldg">
          <SimpleLoadingIndicator />{" "}
        </div>
      )}
      <StyledProfilePage
        loaded={profileLoaded}
        myProfile={myProfile}
        editing={editingProfile}
      >
        <div
          className="home-button"
          style={{
            backgroundColor: "transparent",
            border: "1px solid black",
            position: "absolute",
            top: "70px",
            left: "5%",
            padding: "0 16px 0 14px",
            height: "40px",
            borderRadius: "1rem",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            setLocation("/");
          }}
        >
          <span
            className="arrow"
            style={{ marginRight: "8px", fontSize: "16px", fontWeight: "200" }}
          >
            &larr;
          </span>

          <span style={{ fontSize: "12px" }} className="text">
            Home
          </span>
        </div>
        {!user && (
          <StyledCTA
            className="cta"
            style={{ marginTop: "-90px", marginBottom: "40px", zIndex: 1 }}
          >
            <img className="star" src={imageUrl("/thestar.png", 72)} />
            <span>
              Moonlight is a new social space for tarot.
              <span
                className="cta-link"
                onClick={() => setLocation("/signup")}
                style={{
                  marginLeft: "4px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Join us!
              </span>
            </span>
          </StyledCTA>
        )}

        <StyledProfileForm>
          {user && <Message />}
          <form
            style={{ marginTop: "60px" }}
            spellCheck={false}
            onSubmit={handleUpdateProfile}
          >
            <ProfileEditButtons
              editingProfile={editingProfile}
              linkCopied={linkCopied}
              setLinkCopied={setLinkCopied}
              backgroundTheme={"light"}
              data={data}
              setFavoritesUpdated={setFavoritesUpdated}
              favorites={favorites}
              setEditingProfile={setEditingProfile}
              resetForm={resetForm}
            />
            {editingProfile && (
              <ProfilePicChooser
                isEditing={editingProfile}
                setProfilePic={setTempProfilePic}
                profilePic={profilePic}
                isMyProfile={myProfile}
                setProfileLoaded={setProfileLoaded}
                width={600}
              />
            )}
            {!editingProfile && (
              <ProfilePicChooser
                isEditing={editingProfile}
                setProfilePic={setTempProfilePic}
                profilePic={profilePic}
                isMyProfile={myProfile}
                setProfileLoaded={setProfileLoaded}
                width={600}
              />
            )}
            <div className="inputs">
              <div className="input-container">
                <div className="name text-name">{data.name}</div>
                <input
                  className="name show-display-error"
                  type="text"
                  name="name"
                  placeholder="Display name"
                  maxLength={DISPLAY_NAME_CHAR_LIMIT}
                  required
                  onInvalid={(e) => e.preventDefault()}
                  value={data.name}
                  onChange={handleInputChange}
                />
                <p
                  style={{ marginLeft: "4px", marginBottom: "12px" }}
                  className="error-message"
                >
                  Please enter a display name
                </p>
              </div>
              {(data.location && data.location !== " ") || editingProfile ? (
                <div className="input-container">
                  <input
                    id="location-input"
                    type="text"
                    name="location"
                    placeholder="Location"
                    maxLength={DISPLAY_NAME_CHAR_LIMIT}
                    onInvalid={(e) => e.preventDefault()}
                    value={data.location}
                    onChange={handleInputChange}
                  />
                </div>
              ) : (
                <div style={{ width: "100%", height: "10px" }}></div>
              )}
              <div id="bio-input" className="bio-input-text">
                {data.bio}
              </div>
              <textarea
                id="bio-input"
                name="bio"
                placeholder={myProfile ? "Tell us about yourself!" : ""}
                maxLength={BIO_CHAR_LIMIT}
                value={data.bio}
                onChange={handleInputChange}
              />
              <div className="help-text">
                This is your tarot profile page! Describe yourself in under 300
                characters.
              </div>
            </div>
          </form>
        </StyledProfileForm>
      </StyledProfilePage>
    </>
  );
};

export const StyledCTA = styled("div", {
  "@mobile": {
    flexDirection: "row",
    width: "300px",
    paddingTop: "70px",
    marginBottom: "-60px",
    position: "unset !important",
    textAlign: "center",
    marginLeft: "-40px",
  },
});

UserPage.defaultProps = defaultProps;

export default UserPage;

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "50%": { transform: "rotate(180deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const fadein = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const StyledProfileForm = styled(StyledForm, {
  "& > *": {
    transition: "opacity 1s",
  },

  width: "650px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "start",
});
const StyledProfilePage = styled(StyledFormPage, {
  "#fadein1": {
    animation: `${fadein} 0.6s ease-in 1`,
  },

  "& .cta": {
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    marginTop: "-6px",
    "& span": {
      transition: "all 0.2s",
    },
    "& .cta-link:hover": {
      opacity: 0.5,
    },
  },
  "& .star": {
    width: "36px",
    marginRight: "10px",
    animation: `${spin} 8s linear infinite`,
  },

  "& form": {
    width: "552px !important",
    display: "flex",
    flexDirection: "row !important",
  },
  "& .inputs": {
    width: "360px !important",
  },
  "& .name:not(:placeholder-shown)": {
    fontFamily: "GorgeousBaby",
    fontSize: "40px !important",
    "@mobile": {
      fontSize: "48px !important",
    },
  },
  "& input.name": {
    height: "60px !important",
    lineHeight: "60px",
    verticalAlign: "middle",
    paddingLeft: "0 !important",
    textIndent: "12px",
  },
  "& .text-name": {
    textAlign: "left",
    marginLeft: "16px",
    marginBottom: "5px",
    overflowWrap: "break-word",

    "@mobile": {
      textAlign: "center",
      marginLeft: "0",
    },
  },

  "& .help-text": {
    fontSize: "10px",
    "@mobile": {
      fontSize: "13px",
    },
    fontStyle: "italic",
    textAlign: "left",
    marginTop: "5px",
    opacity: 0,
    transition: "opacity 0.2s",
  },
  "& input, & textarea": {
    width: "100% !important",
    height: "100px",
    padding: "12px !important",
    border: "1px solid $gray300 !important",
    borderRadius: "10px",
    backgroundColor: "$gray100",
    transition: "backgroundColor 0.2s",
  },
  "& .bio-input-text": {
    fontSize: "13px",
    textAlign: "left",
    overflowWrap: "break-word",
    marginLeft: "16px",
    "@mobile": {
      fontSize: "15px",
    },
  },
  "& textarea": {
    fontSize: "13px",
    "@mobile": {
      fontSize: "15px",
    },
    resize: "none",
    height: "140px",
  },
  "& input:focus, & textarea:focus": {
    outline: "none !important",
    borderColor: "black !important",
  },
  "& input#location-input": {
    paddingLeft: "30px !important",
    backgroundImage: "url(/images/location.svg)",
    backgroundPosition: "8px calc(50% - 1px)",
    backgroundRepeat: "no-repeat",
    "@mobile": {
      fontSize: "15px",
    },
  },
  "& .edit-buttons": {
    position: "absolute",
    right: "24px",
    top: "24px",
    display: "flex",
  },

  "@mobile": {
    display: "flex",
    justifyContent: "start",
    overflowY: "scroll",
    paddingBottom: "30px",
    "& .extra": {
      display: "none",
    },

    "& form": {
      flexDirection: "column !important",
      alignItems: "center",
      width: "90%",
      "& .inputs": {
        maxWidth: "80%",
        // width: "0% !important",
      },
    },
  },

  variants: {
    loaded: {
      true: {
        "& .ldg": {
          display: "none",
        },
      },
      false: {
        ".form-container > *": {
          opacity: "0 !important",
        },
        ".form-container": {
          border: "none",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
        "& .ldg": {
          opacity: "0.3 !important",
          position: "absolute",
          left: 0,
          right: 0,
          margin: "auto",
          top: 0,
          bottom: 0,
        },
      },
    },
    editing: {
      true: {
        "& .help-text": {
          opacity: 1,
        },
        "& .text-name": {
          display: "none",
        },
        "& .bio-input-text": {
          display: "none",
        },
      },
      false: {
        "& input, & textarea": {
          pointerEvents: "none",
          borderColor: "transparent !important",
          backgroundColor: "transparent",
        },
        "& input#location-input": {
          marginTop: "-12px",
          marginBottom: 0,
        },
        "& textarea#bio-input": {
          display: "none",
        },
        "& input.name": {
          display: "none",
        },
      },
    },
    myProfile: {
      true: {
        "& .edit-buttons .not-myprofile": {
          display: "none !important",
        },
      },
      false: {
        "& .edit-buttons .myprofile": {
          display: "none !important",
        },
      },
    },
  },
});
