import { useContext, useEffect } from "react";
import { AuthContext } from "~/utils/useAuth";

export const SignoutPage = () => {
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    logout();
  }, []);

  return <div></div>;
};

export default SignoutPage;
