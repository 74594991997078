import { useEffect, useState } from "react";
import { styled } from "~/ui/style/stitches.config";
import { AvailableUserProfile } from "./ProDirectory";
import {
  Service,
  ServiceResponse,
  getAllMoonlightServices,
} from "~/api/calendarApi";
import { ChevronDownIcon } from "@radix-ui/react-icons";

export interface ProFilterOptions {
  label: string;
  filter: (pro: AvailableUserProfile) => boolean;
  isDefault?: boolean;
}

export const FilterDropdown = ({
  defaultLabel,
  smallLabel,
  options,
  currentOption,
  setCurrentOption,
}: {
  defaultLabel: string;
  smallLabel: string;
  options: ProFilterOptions[];
  currentOption: ProFilterOptions | null;
  setCurrentOption: (option: ProFilterOptions | null) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const id = `filter-dropdown${defaultLabel.split(" ").join("")}`;
  useEffect(() => {
    window.addEventListener("click", (e) => {
      // if the event target wasn't anywhere inside of the dropdown parent, close the menu
      if (!document.getElementById(id)?.contains(e.target as Node)) {
        setIsOpen(false);
      }
    });
  }, []);
  return (
    <StyledFilterDropdown isOpen={isOpen} id={id}>
      <div
        className={`label anyselected-${currentOption !== null && !isOpen}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className="big-label">
          {isOpen ? defaultLabel : currentOption?.label || defaultLabel}
        </span>
        {
          <span
            className={`small-label-selected selected-${
              currentOption !== null && !currentOption.isDefault
            }`}
          >
            •
          </span>
        }
        <span className="small-label">{smallLabel}</span>
        <ChevronDownIcon
          className="down-arrow"
          style={{
            transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "all 0.2s ease-in-out",
          }}
        />
      </div>
      <div className="dropdown-menu">
        {options.map((option, index) => (
          <div
            key={index}
            className={`option  selected-${
              option.label === currentOption?.label ||
              (currentOption === null && index === 0)
            }`}
            onClick={() => {
              setCurrentOption(option);
              setIsOpen(false);
            }}
          >
            {option.label}
            <img
              className="checkmark"
              src={`/images/more-menu/checkmark.svg`}
            ></img>
          </div>
        ))}
      </div>
    </StyledFilterDropdown>
  );
};

const StyledFilterDropdown = styled("div", {
  position: "relative",
  width: "240px",
  marginRight: "8px",
  fontSize: "11px",
  textTransform: "uppercase",
  "@bpfilter": {
    width: "90px",
    marginRight: "4px",
  },
  "& .label": {
    cursor: "pointer",
    position: "absolute",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    border: "0.5px solid rgba(0, 0, 0, 0.4)",
    borderRadius: "48px",
    backgroundColor: "white",
    "& .small-label-selected": {
      fontSize: "20px",
      marginRight: "4px",
      marginLeft: "-12px",

      "&.selected-false": {
        opacity: 0,
      },
    },

    "& .small-label, & .small-label-selected": {
      display: "none",
    },
    "@bpfilter": {
      "& .big-label": {
        display: "none",
      },
      "& .small-label, & .small-label-selected": {
        display: "block",
      },
    },
  },
  "& .down-arrow": {
    position: "absolute",
    right: "6px",
    "@bpfilter": {
      right: "7px",
    },
  },
  "& .dropdown-menu": {
    width: "240px",

    position: "absolute",
    top: "40px",
    backgroundColor: "white",
    border: "0.5px solid black",
    borderRadius: "18px",
    padding: "16px 8px 16px 8px",
    marginTop: "2px",
  },
  "& .option": {
    cursor: "pointer",
    marginTop: "2px",
    padding: "10px 8px",
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",

    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },

  "& .option.selected-false": {
    "& .checkmark": {
      opacity: 0,
    },
  },
  "& .option.selected-true": {
    "& .checkmark": {
      opacity: 1,
    },
  },
  variants: {
    isOpen: {
      true: {},
      false: {
        ".dropdown-menu": {
          display: "none",
        },
      },
    },
  },
});

export const ProSearchBar = ({
  currentSearch,
  setCurrentSearch,
}: {
  currentSearch: string | null;
  setCurrentSearch: (search: string | null) => void;
}) => {
  return (
    <StyledProSearchBar>
      <img src="/images/search-glass.svg" alt="search" />
      <input
        id={"pro-search-bar"}
        placeholder="Name / keyword"
        onChange={(e) => {
          setCurrentSearch(e.target.value);
        }}
      ></input>

      <img
        className={`x-icon visible-${
          currentSearch !== null && currentSearch !== ""
        }`}
        src="/images/room/x-icon.png"
        alt="clear"
        onClick={() => {
          setCurrentSearch(null);
          (
            document.getElementById("pro-search-bar") as HTMLInputElement
          ).value = "";
        }}
      />
    </StyledProSearchBar>
  );
};

const StyledProSearchBar = styled("div", {
  position: "relative",
  height: "36px",

  "& img": {
    position: "absolute",
    left: "8px",
    top: "calc(50% - 10px)",
    height: "20px",
    width: "20px",
    pointerEvents: "none",
    "&.x-icon": {
      left: "auto",
      right: "8px",
      filter: "invert(0.5)",
      pointerEvents: "auto",
      cursor: "pointer",
      "&.visible-false": {
        display: "none",
      },
    },
  },

  "& input": {
    width: "195px",
    "@bpfilter": {
      width: "100%",
    },
    height: "36px",
    border: "0.5px solid rgba(0, 0, 0, 0.4)",
    borderRadius: "48px",
    padding: "0px 16px",
    paddingLeft: "30px",
    paddingRight: "26px",
    fontSize: "12px",
    right: 0,

    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.4)",
    },
    "&:focus": {
      outline: "none",
      border: "0.5px solid black",
    },
  },
  "@mobile": {
    "& input": {
      fontSize: "13px",
    },
  },
});

export const ProFilters = ({
  setActiveProFilters,
}: {
  setActiveProFilters: (activeProFilters: ProFilterOptions[]) => void;
}) => {
  const [allServices, setAllServices] = useState<ServiceResponse[]>([]);

  useEffect(() => {
    getAllMoonlightServices().then((services) => {
      setAllServices(services.reverse());
    });
  }, []);

  const priceOptions: ProFilterOptions[] = [
    {
      label: "All prices",
      filter: (pro: AvailableUserProfile) => true,
      isDefault: true,
    },
    {
      label: "Under $50",
      filter: (pro: AvailableUserProfile) => pro.lowestPrice < 50,
    },
    {
      label: "$50 – 100",
      filter: (pro: AvailableUserProfile) =>
        pro.lowestPrice < 100 && pro.lowestPrice >= 50,
    },
    {
      label: "$100 - $300",
      filter: (pro: AvailableUserProfile) =>
        pro.lowestPrice >= 100 && pro.lowestPrice < 300,
    },
  ];
  const serviceOptions: ProFilterOptions[] = [
    {
      label: "All services",
      filter: (pro: AvailableUserProfile) => true,
      isDefault: true,
    },
    ...allServices.map((service) => ({
      label: service.name,
      filter: (pro: AvailableUserProfile) => {
        return (
          pro.availability.services
            .filter((proService) => proService.id === service.id)
            .filter((service) => service.offered).length > 0
        );
      },
    })),
  ];

  const [currentPriceOption, setCurrentPriceOption] =
    useState<ProFilterOptions | null>(null);
  const [currentServiceOption, setCurrentServiceOption] =
    useState<ProFilterOptions | null>(null);

  const [currentSearch, setCurrentSearch] = useState<string | null>(null);

  //TODO: This is hacky and should be fixed
  useEffect(() => {
    let query = window.location.search;
    let urlQueryParams = new URLSearchParams(query);
    let service = urlQueryParams.get("service");
    if (service === "15-min-async") {
      console.log("here");
      const asyncService = serviceOptions.find((option) =>
        option.label.includes("15")
      );
      console.log("async-service", asyncService);
      if (asyncService) setCurrentServiceOption(asyncService);
    }
  }, [serviceOptions]);

  useEffect(() => {
    const activeProFilters: ProFilterOptions[] = [];
    if (currentPriceOption) {
      activeProFilters.push(currentPriceOption);
    }
    if (currentServiceOption) {
      activeProFilters.push(currentServiceOption);
    }
    if (currentSearch) {
      activeProFilters.push({
        label: "Search",
        filter: (pro: AvailableUserProfile) => {
          return (
            pro.name.toLowerCase().includes(currentSearch.toLowerCase()) ||
            pro.bio.toLowerCase().includes(currentSearch.toLowerCase()) ||
            pro.tagline.toLowerCase().includes(currentSearch.toLowerCase()) ||
            pro.availability.services.filter((service) => {
              return (
                service.name
                  .toLowerCase()
                  .includes(currentSearch.toLowerCase()) && service.offered
              );
            }).length > 0 ||
            (pro.tags !== undefined &&
              pro.tags.filter((tag) =>
                tag.toLowerCase().includes(currentSearch.toLowerCase())
              ).length > 0)
          );
        },
      });
    }

    setActiveProFilters(activeProFilters);
  }, [currentPriceOption, currentServiceOption, currentSearch]);

  return (
    <StyledProFilters>
      <div
        className="filters-container"
        id="pro-filters"
        style={{ scrollMargin: "60px" }}
      >
        <FilterDropdown
          defaultLabel={"Price"}
          smallLabel={"Price"}
          options={priceOptions}
          currentOption={currentPriceOption}
          setCurrentOption={setCurrentPriceOption}
        />
        <FilterDropdown
          defaultLabel={"Service type"}
          smallLabel={"Type"}
          options={serviceOptions}
          currentOption={currentServiceOption}
          setCurrentOption={setCurrentServiceOption}
        />
      </div>
      <ProSearchBar
        currentSearch={currentSearch}
        setCurrentSearch={setCurrentSearch}
      />
    </StyledProFilters>
  );
};

const StyledProFilters = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  width: "calc(90% - 24px)",
  height: "fit-content",
  marginTop: "20px",
  marginBottom: "20px",
  zIndex: 1,
  "& .filters-container": {
    display: "flex",
  },
  "@bpfilter": {
    width: "90%",
  },
});
