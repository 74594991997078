import axios from "./axiosConfig.js";

const DECKS_ENDPOINT = "decks/";

export interface DeckResponse {
    name : string
    proIds: string[]
    additionalPros: string[]
    stripeId: string | null
    id: string
  }

  const getDeckById = async (deckId : string) : Promise<DeckResponse> => {
    const response = await axios.get(DECKS_ENDPOINT + deckId)
    if (response.status === 200) {
      return response.data as DeckResponse
    } else {
      throw new Error(response.statusText)
    }
  }


  export {getDeckById}