import { countriesToCodes } from "~/utils/countries";
import { MoonlightDropdownMenu, TimezoneMenu } from "../menus/TimezoneMenu";
import { useEffect, useState, useContext } from "react";
import { DropdownMenu } from "react-bootstrap";
import { Button } from "../components/Button";
import { localState } from "~/state/state";
import { Message } from "../components/Message";
import { styled } from "../style/stitches.config";
import { AuthContext } from "~/utils/useAuth";
import { onboardConnectAccount } from "~/api/paymentsApi";
import {
  LoadingIndicator,
  SimpleLoadingIndicator,
} from "~/pages/loading/LoadingPage";
import { getUser } from "~/api/userApi";
import { sendErrorToast } from "~/utils/handleToasts";

export const ProPayoutsOverlay = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Function;
}) => {
  const { user } = useContext(AuthContext);
  const [isStripeAccountConnected, setIsStripeAccountConnected] =
    useState(false);

  useEffect(() => {
    if (!user?.uuid) return;
    (async () => {
      const u = await getUser(user.uuid);
      setIsStripeAccountConnected(u?.acceptingPayments ?? false);
    })();
  }, [user?.uuid, isOpen]);

  const [countryCode, setCountryCode] = useState<string | null>(null);
  const [isConnectingToStripe, setIsConnectingToStripe] = useState(false);
  const [country, setCountry] = useState<string | null>(null);

  return (
    <>
      {isOpen && (
        <>
          <Message />

          <StyledProPayoutsOverlay
            onClick={(e) => {
              if (e.target === e.currentTarget) setIsOpen(false);
            }}
          >
            <div
              className="payout-info-container"
              onClick={(e) => {
                if (!(e.target as HTMLElement).classList.contains("menu"))
                  document.querySelector(".menu")?.classList.remove("open");
              }}
            >
              <img
                className="btn x"
                src="/images/exit.svg"
                alt="x"
                onClick={() => {
                  setIsOpen(false);
                }}
              />
              {isStripeAccountConnected ? (
                <>
                  <div className="title">
                    <img src="/images/shield.svg"></img>Your Stripe account is
                    connected
                  </div>
                  <div className="subtitle">
                    Your earnings will be automatically transferred to your bank
                    account according to your payout schedule.
                  </div>
                  <Button
                    style={{
                      border: "none",
                      padding: "14px 24px",
                      borderRadius: "12px",
                      fontSize: "12px",
                      marginTop: "10px",
                    }}
                    Theme={"dark"}
                    onClick={() => {
                      window.open(
                        "https://connect.stripe.com/express_login",
                        "_blank"
                      );
                    }}
                  >
                    Open dashboard
                  </Button>
                </>
              ) : (
                <>
                  <div className="title">Select your country for payouts.</div>
                  <div className="subtitle">
                    This ensures we can properly verify and link you or your
                    business for payouts according to local regulations.
                  </div>
                  <MoonlightDropdownMenu
                    selected={country ?? "Select a country"}
                    style={{ backgroundColor: "white" }}
                    setSelected={(c: string) => {
                      setCountry(c);
                      //@ts-ignore
                      const code = countriesToCodes[c] as string;
                      if (code) setCountryCode(code);
                    }}
                    dropdownListContent={Object.keys(countriesToCodes)}
                  ></MoonlightDropdownMenu>
                  <Button
                    style={{
                      border: "none",
                      padding: "14px 24px",
                      borderRadius: "12px",
                      fontSize: "12px",
                      marginTop: "24px",
                      width: "160px",
                      height: "44px",
                    }}
                    Theme={"dark"}
                    onClick={() => {
                      if (countryCode) {
                        setIsConnectingToStripe(true);
                        onboardConnectAccount({ country: countryCode });
                      } else {
                        // Didn't select a country
                        sendErrorToast(1149);
                      }
                    }}
                  >
                    {isConnectingToStripe ? (
                      <div
                        style={{ transform: "scale(0.4)", marginTop: "6px" }}
                      >
                        <SimpleLoadingIndicator></SimpleLoadingIndicator>
                      </div>
                    ) : (
                      "Continue to Stripe"
                    )}
                  </Button>
                </>
              )}
            </div>
          </StyledProPayoutsOverlay>
        </>
      )}
    </>
  );
};

export const StyledProPayoutsOverlay = styled("div", {
  zIndex: 10000000,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",

  "& .payout-info-container": {
    width: "500px",
    padding: "60px 50px",
    backgroundColor: "$wash",
    borderRadius: "33px",
    border: "1px solid black",
    boxShadow: "0px 3px 0px 0px #000",
    marginTop: "130px",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    position: "relative",
    "& .title": {
      fontSize: "15px",
      fontWeight: "500",
      display: "flex",
      marginBottom: "8px",
      alignItems: "center",
      "& img": {
        marginRight: "8px",
      },
    },
    "& .subtitle": {
      fontSize: "13px",
      marginTop: "8px",
      lineHeight: "1.3",
      marginBottom: "18px",
    },
    "& .x": {
      position: "absolute",
      top: "20px",
      right: "20px",
      cursor: "pointer",
    },
    "@mobile": {
      width: "100%",
      height: "100% !important",
      border: 0,
      borderRadius: 0,
      marginTop: 0,
      paddingTop: "90px",
    },
  },
});
