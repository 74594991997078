const GA_TRACKING_ID = "GTM-TXM9L32";

declare global {
  interface Window { dataLayer: any; }
}

const sendPageEvent = (location : string) => {
  // window.dataLayer.push({
  //   event: "pageview", 
  //   page: {
  //     path: location
  //   }
  // });
  // if (window.gtag) {
  //   window.gtag('config', GA_TRACKING_ID, {
  //     page_path: location
  //  });
  // }
}

const sendAnalyticsEvent = (event_name : string, params : { [key : string]: string | number} = {}) => {
  window.dataLayer.push({event: event_name, ...params});
}

export { sendPageEvent, sendAnalyticsEvent };