import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSnapshot } from "valtio";
import { useLocation, useParams } from "wouter";
import { localState } from "~/state/state";
import { routeToMeta, MetaTags } from "~/utils/metatags";

export const MetaTagHeaders = () => {
  const [location, setLocation] = useLocation();
  const [metaTags, setMetaTags] = useState<null | MetaTags>(null);

  useEffect(() => {
    (async () => {
      const metaTags = await routeToMeta(location);
      setMetaTags(metaTags);
    })();
  }, [location]);

  if (!metaTags) return <></>;
  return (
    <Helmet>
      <title>{metaTags.title}</title>
      <meta property="og:title" content={metaTags.title} />

      <meta name="description" content={metaTags.description} />
      <meta property="og:description" content={metaTags.description} />

      <link rel="canonical" href={metaTags.route}></link>
      <meta property="og:url" content={metaTags.route} />

      {import.meta.env.VITE_ENV === "production" && (
        <meta name="robots" content="index, follow" />
      )}
    </Helmet>
  );
};
