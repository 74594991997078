import { RoomTheme } from "~/types/room/types";
import { localState } from "~/state/state";

import { styled } from "~/ui/style/stitches.config";
import { Button } from "../components/Button";
import { useSnapshot } from "valtio";

export const StyledLeftMenu = styled("div", {
  backgroundColor: "$wash",
  borderRadius: "$4",
  marginRight: "$3",
  marginBottom: "$2",
  display: "grid",
  gap: "$2",
  border: "1px solid $darkwashA500",
  width: "178px",
  marginLeft: "0",
  marginTop: "8px",
  padding: "0 0 12px 0",

  variants: {
    outerDeck: {
      true: {
        width: "181px !important",
        "& .visit-shop-button": {
          width: "calc(100% - 30px)",
          marginTop: "-2px",
          marginLeft: "13px",
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "11px",
          backgroundColor: "black",
          color: "white",
          padding: 0,
          "&:hover": {
            backgroundColor: "$gray800",
          },
        },
        "& .thumbnail": {
          right: "-65px !important",
        },
        "& .section-header": {
          textAlign: "center",
          width: "150px",
          marginLeft: "calc(50% - 75px)",
          textTransform: "uppercase",
        },
      },
      false: {},
    },
    innerDeck: {
      true: {
        width: "160px",
        paddingTop: "14px",
        marginLeft: "9px",
        position: "relative",
        fontSize: "10px",
        display: "flex",
        marginTop: "8px",
        marginBottom: "8px",
        justifyContent: "center",
        flexDirection: "column",
        paddingBottom: "10px",
        border: "1px solid rgba(0, 0, 0, 0.5)",
        borderRadius: "15px",
        "& .section-header": {
          position: "absolute",
          zIndex: "1",
          textAlign: "center",
          top: "-12px",
          backgroundColor: "$wash",
          padding: "5px",
          marginLeft: "calc(50% - 35px)",
          width: "74px",
        },
        "& .thumbnail": {
          right: "-70px !important",
        },
      },
      false: {},
    },
    scrollable: {
      true: {
        paddingLeft: "60px",
        marginLeft: "-60px",
        width: "208px !important",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "6px",
          backgroundColor: "rgba(127, 127, 127, .5)",
        },
        "&::-webkit-scrollbar-track": {
          // backgroundColor: "$gray200",
        },
        "&::-webkit-scrollbar-corner": {
          backgroundColor: "transparent",
        },
      },
    },

    Theme: {
      light: {},
      dark: {
        backgroundColor: "$darkwash",
        border: "1px solid $washA500",
      },
    },
  },
  compoundVariants: [
    {
      Theme: "dark",
      innerDeck: true,
      css: {
        border: "1px solid $washA500",
      },
    },
    {
      Theme: "dark",
      outerDeck: true,
      css: {
        "& .section-header": {
          backgroundColor: "$darkwash",
          color: "white",
        },
        "& .visit-shop-button": {
          backgroundColor: "white",
          color: "black",
          "&:hover": {
            backgroundColor: "$gray200",
          },
        },
      },
    },
  ],
});

export const StyledMenuList = styled(StyledLeftMenu, {
  width: "100%",
  marginTop: "$2",
  paddingTop: "$3",
  paddingLeft: "$1",
  paddingRight: "$1",
  variants: {
    Theme: {
      light: {
        backgroundColor: "white",
      },
      dark: {
        border: "1px solid transparent",
      },
    },
  },
});

export const StyledLeftListItem = styled("div", {
  backgroundColor: "transparent",
  textAlign: "left",
  outline: "none",
  borderRadius: "8px",
  margin: "-2px 8px",
  padding: "2px 2px",
  "&:hover": {
    backgroundColor: "$grayA50",
    "& .preview": {
      opacity: "1 !important",
    },
  },
  "&:focus": {
    backgroundColor: "$grayA50",
  },
  variants: {
    isPlus: {
      true: {
        "&:hover": {
          backgroundColor: "#F0D8FF !important",
        },
      },
    },
    Theme: {
      light: {
        "& .preview": {
          border: "1px solid $darkwashA500",
        },
        "&:hover": {
          backgroundColor: "$grayA50",
        },
      },
      dark: {
        "& .preview": {
          border: "1px solid $washA500",
          filter: "none",
        },
        "&:hover": {
          backgroundColor: "$gray800 !important",
        },
        "&:focus": {
          backgroundColor: "$gray900",
        },
      },
    },
  },
});

export const StyledMenuListItem = styled("div", {
  fontSize: "11px",
  variants: {
    Theme: {
      light: {},
      dark: {},
    },
  },
});

export const StyledSpreadSelectItem = styled(StyledLeftListItem, {
  width: "calc(100% - $2)",
  fontSize: "11px",
  marginLeft: "3px",
  variants: {
    Theme: {
      light: {},
      dark: {},
    },
  },
});

export const StyledCheckboxItem = styled("div", {
  width: "12px",
  height: "12px",
  borderRadius: "2px",
  cursor: "pointer",
  "& .check-icon": {
    width: "15px",
    marginTop: "-3px",
    marginLeft: "-3px",
  },

  variants: {
    checked: {
      true: {},
      false: {
        "& .check-icon": {
          display: "none",
        },
      },
    },
    Theme: {
      light: {
        border: "1px solid black",
      },
      dark: {
        backgroundColor: "$gray900",
        border: "1px solid white",
      },
    },
  },
  compoundVariants: [
    {
      Theme: "dark",
      checked: true,
      css: {
        backgroundColor: "white",
        color: "black",
      },
    },
    {
      Theme: "light",
      checked: true,
      css: {
        backgroundColor: "black",
        color: "white",
      },
    },
  ],
});

export const StyledCheckboxContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "transparent !important",
  padding: "6px",
  cursor: "pointer",
  marginLeft: "2px",
});

export const StyledCheckboxLabel = styled("p", {
  marginRight: "8px",
});

export function ConfirmMenu({
  theme,
  onConfirm,
  messageText,
  confirmButtonText,
  padding,
}: {
  theme: RoomTheme;
  onConfirm: Function;
  messageText: string;
  confirmButtonText: string;
  padding: number;
}) {
  const { isOnMobile } = useSnapshot(localState);

  return (
    <div style={{ textAlign: "center" }}>
      <p
        style={{
          fontSize: isOnMobile ? "12px" : "11px",
          textAlign: "left",
          padding: "0 " + padding + "px",
          marginTop: "8px",
          fontFamily: "WorkSans",
          lineHeight: "1.4em",
        }}
      >
        {messageText}
      </p>
      <Button
        Theme={theme}
        lightweight
        style={{
          width: "calc(100% - 32px)",
          marginTop: "14px",
          marginBottom: "8px",
        }}
        onClick={onConfirm as React.MouseEventHandler}
      >
        {confirmButtonText}
      </Button>
    </div>
  );
}
