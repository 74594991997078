let debounceTimer: NodeJS.Timeout | null = null;

  export const debounce = (func: Function, delay: number) => {


    return (...args: any[]) => {
        if (debounceTimer) {
          clearTimeout(debounceTimer);
        }

    debounceTimer = setTimeout(() => {
        func(...args);
        debounceTimer = null;
      }, delay);
    };
  };