import { useState } from "react";
import { GUIDELINES_TEXT } from "~/assets/guidelines";
import { SimpleLoadingIndicator } from "~/pages/loading/LoadingPage";
import { styled } from "../style/stitches.config";

export const GuidelinesScrollContainer = ({
  className,
  onClickAgree,
  style,
}: {
  onClickAgree: Function;
  style?: any;
  className?: string;
}) => {
  const [isLoadingGuidelines, setIsLoadingGuidelines] = useState(false);
  return (
    <StyledGuidelinesScrollContainer
      className={"guidelines-container" + className ? className : ""}
      style={style}
    >
      <div className="guidelines-title">Moonlight's Community Guidelines</div>
      <div
        className="guidelines-text"
        dangerouslySetInnerHTML={{ __html: GUIDELINES_TEXT }}
      ></div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="black-button"
          style={{
            width: "200px",
            marginTop: "30px",
          }}
          onClick={() => {
            setIsLoadingGuidelines(true);
            onClickAgree();
          }}
        >
          {isLoadingGuidelines ? (
            <div style={{ transform: "scale(0.4)" }}>
              <SimpleLoadingIndicator
                style={{ position: "relative", marginTop: "40px" }}
              />
            </div>
          ) : (
            "I understand and agree"
          )}
        </div>
      </div>
    </StyledGuidelinesScrollContainer>
  );
};

const StyledGuidelinesScrollContainer = styled("div", {
  "& strong": {
    fontWeight: "500",
    marginRight: "4px",
  },

  width: "100%",
  backgroundColor: "white",
  borderRadius: "19px",
  border: "1px solid black",
  height: "400px",
  marginTop: "24px",
  overflowY: "scroll",
  padding: "30px",
  textAlign: "left",
  "&::-webkit-scrollbar": {
    webkitAppearance: "none",
    width: "9px",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "20px",
    backgroundColor: "rgba(0, 0, 0, .5)",
    border: "1px solid $wash",
  },
  "&::-webkit-scrollbar-track": {
    overflow: "hidden",
    marginTop: "20px",
    marginBottom: "20px",
    width: "8px",
  },

  "& .guidelines-title": {
    fontSize: "27px",
    fontFamily: "KeplerLightCondensed",
    marginBottom: "8px",
  },
  "& .guidelines-text": {
    fontSize: "12px",
    "@mobile": {
      fontSize: "13px",
    },
    textAlign: "left",
    "& br": {
      marginBottom: "8px",
    },
    "& li": {
      marginBottom: "8px",
    },
  },
  "& .black-button": {
    fontSize: "13px",
    "@mobile": {
      fontSize: "14px",
    },
    cursor: "pointer",
    width: "50%",
    color: "white",
    backgroundColor: "black",
    borderRadius: "18px",
    height: "52px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "6px",
    transition: "0.2s all",
    "&:hover": {
      backgroundColor: "$gray800",
    },
  },
});
