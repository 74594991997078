import { styled } from "@stitches/react";
import { motion } from "framer-motion";
import { createContext, useContext, useState } from "react";
import ReactDOM from "react-dom";

export function Actions({ children }: React.PropsWithChildren<{}>) {
  return (
    <ActionPortal>
      <StyledContainer
        style={{ x: 0 }}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0,
        }}
      >
        {children}
      </StyledContainer>
    </ActionPortal>
  );
}

const ActionContext = createContext<
  | [
      HTMLDivElement | null,
      React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
    ]
  | []
>([]);

export const ActionProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const actionState = useState<HTMLDivElement | null>(null);
  return (
    <ActionContext.Provider value={actionState}>
      {children}
    </ActionContext.Provider>
  );
};

export const ActionPortalTarget = () => {
  const [, setAction] = useContext(ActionContext);
  return <div ref={setAction} />;
};

export const ActionPortal = ({ children }: React.PropsWithChildren<{}>) => {
  const [action] = useContext(ActionContext);
  return action ? ReactDOM.createPortal(children, action) : null;
};

const StyledContainer = styled(motion.div, {
  position: "fixed",
  inset: 0,
  padding: "2rem",
  top: "auto",
  placeContent: "center",
  display: "flex",
  gap: "1rem",
});
