import { useContext, useEffect, useRef } from 'react'
import { AuthContext } from './useAuth'
import useLocation from 'wouter/use-location';

export function useRequireAuth(redirectUrl = '/signin') {
  const auth = useContext(AuthContext)
  let [location ,setLocation] = useLocation();
  const didRedirect = useRef(false)

  useEffect(() => {
    // Ensure that this only runs once, even in Strict Mode
    // Wait until the auth has loaded before redirecting
    if (didRedirect.current === false) {
      didRedirect.current = true

      // If auth.user is false that means we're not logged in and should redirect.
      if (!auth.user) {
        const desiredLocation = encodeURIComponent(location)
        const invitedParam = window.location.href.includes("i=") ? "&i=" + window.location.href.split("i=")[1] : ""
        setLocation("/signup?dest=" + desiredLocation + invitedParam)
      }
    }
  }, [auth, redirectUrl])

  return auth
}
