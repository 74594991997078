import { CheckIcon } from "@radix-ui/react-icons";
import { ChangeEventHandler, useEffect, useState } from "react";
import { DISPLAY_NAME_CHAR_LIMIT } from "~/utils/consts";
import { styled } from "~/ui/style/stitches.config";
import { Button } from "~/ui/components/Button";
import { StyledLeftListItem, StyledLeftMenu } from "~/ui/menus/StyledMenus";

const pronounsList = [
  "They / Them",
  "She / Her",
  "He / Him",
  "She / They",
  "He / They",
  "He / She / They",
  "Blank",
];
export const PronounInput = ({
  data,
  theme,
  handleInputChange,
}: {
  data: any;
  theme: "light" | "dark";
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
}) => {
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
  const [currPronouns, setCurrPronouns] = useState("");

  const pronounMenu = (
    <StyledLeftMenu id={"shuffle-deck"} Theme={theme}>
      <Button
        Theme={theme}
        role="menuitem"
        className="arrow-button"
        style={{ width: "24px", marginLeft: "6px", marginTop: "2px" }}
        transparent
        onClick={(e) => {
          setIsSelectMenuOpen(false);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        &larr;
      </Button>
      {pronounsList.map((pronouns, i) => (
        <StyledLeftListItem key={i} Theme={theme}>
          <Button
            Theme={theme}
            style={{ fontSize: "12px" }}
            transparent
            transparentHover
            menuOption
            onClick={(e) => {
              if (pronouns === "None") pronouns = "";
              setCurrPronouns(pronouns);
              data.pronouns = pronouns;
              setIsSelectMenuOpen(false);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {pronouns}
            <CheckIcon
              style={{
                opacity: pronouns === currPronouns ? 1 : 0,
                flexShrink: 0,
                marginRight: "-12px",
              }}
            />
          </Button>
        </StyledLeftListItem>
      ))}
    </StyledLeftMenu>
  );

  return (
    <StyledPronounInput
      onClick={() => setIsSelectMenuOpen(!isSelectMenuOpen)}
      className="input-container"
      style={{ position: "relative" }}
    >
      {isSelectMenuOpen && (
        <div
          className="pronoun-menu-container"
          style={{
            position: "absolute",
            zIndex: "3",
            right: "-200px",
            top: "-10px",
          }}
        >
          {pronounMenu}
        </div>
      )}
      <input
        id="input-pronouns"
        type="text"
        name="pronouns"
        placeholder="Pronouns"
        maxLength={DISPLAY_NAME_CHAR_LIMIT}
        onInvalid={(e) => e.preventDefault()}
        value={data.pronouns}
        onChange={handleInputChange}
      />
    </StyledPronounInput>
  );
};

const StyledPronounInput = styled("div", {
  cursor: "pointer",
  "& input": {
    pointerEvents: "none",
  },

  "@mobile": {
    "& input": {
      fontSize: "14px !important",
    },
  },
  "& .pronoun-menu-container": {
    "@mobile": {
      right: "-20px !important",
      "& button": {
        fontSize: "14px !important",
      },
    },
  },
});
