import { keyframes, styled } from "~/ui/style/stitches.config";

export const SpinStar = ({
  style,
  color,
  borderColor,
  dontSpin,
  useSpikyStar,
  spinSpeed,
}: {
  style: any;
  color: string;
  borderColor: string;
  dontSpin?: boolean;
  useSpikyStar?: boolean;
  spinSpeed?: number;
}) => {
  const spinStarSvg = (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0416 1.36719H31.3274V22.24L23.5083 2.88706L19.1374 4.653L27.1591 24.5073L12.0174 9.36563L8.68402 12.699L23.1966 27.2116L4.38938 18.9946L2.502 23.3144L22.4023 32.009H0.685547V36.7232H22.4023L2.50201 45.4178L4.38938 49.7376L23.1966 41.5206L8.68402 56.0332L12.0174 59.3664L27.1591 44.2248L19.1374 64.0793L23.5083 65.8451L31.3274 46.4922V67.365H36.0416V46.4922L43.8606 65.8451L48.2313 64.0793L40.21 44.2248L55.3515 59.3664L58.6847 56.0332L44.1722 41.5206L62.9796 49.7376L64.8668 45.4178L44.9668 36.7232H66.6833V32.009H44.9664L64.8668 23.3144L62.9796 18.9946L44.1722 27.2116L58.6847 12.699L55.3515 9.36563L40.21 24.5073L48.2313 4.653L43.8606 2.88706L36.0416 22.24V1.36719Z"
        fill={color}
        stroke={borderColor}
      />
    </svg>
  );

  const spikyStarSvg = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.63672 0.925781L10.5177 6.98918L14.1156 2.02972L12.0779 7.808L17.5684 5.08864L13.0788 9.25808L19.2041 9.40178L13.2912 11.0072L18.6481 13.9811L12.6663 12.6547L16.0277 17.7774L11.3475 13.8231L11.9432 19.9211L9.63672 14.2447L7.33027 19.9211L7.92595 13.8231L3.24574 17.7774L6.60709 12.6547L0.625317 13.9811L5.98229 11.0072L0.069293 9.40178L6.19467 9.25808L1.70505 5.08864L7.19558 7.808L5.15786 2.02972L8.75573 6.98918L9.63672 0.925781Z"
        fill={color}
        stroke={borderColor}
      />
    </svg>
  );

  const starSvg = useSpikyStar ? spikyStarSvg : spinStarSvg;

  return dontSpin ? (
    <div style={style}>{starSvg}</div>
  ) : (
    <StyledSpinStar css={{ $$speed: `${spinSpeed ?? 20}s` }} style={style}>
      {starSvg}
    </StyledSpinStar>
  );
};
const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "50%": { transform: "rotate(180deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const StyledSpinStar = styled("div", {
  animation: `${spin} $$speed linear infinite`,
  transformOrigin: "center",
  aspectRatio: "1/1",
});
