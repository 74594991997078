import { keyframes, styled } from "~/ui/style/stitches.config";

const fadein = keyframes({
  "0%": { opacity: 0 },
  "50%": { opacity: 0 },
  "100%": { opacity: 1 },
});

export const Button = styled("button", {
  background: "white",
  padding: "$4",
  border: "1px solid black",
  borderRadius: "1rem",
  boxShadow: "0 2px 0 1px $grayA200",
  cursor: "pointer",
  outline: "none",

  "@notmobile": {
    "&:hover": {
      background: "$gray200",
    },
  },
  "&[role=menuitem]": {
    padding: "8px",
    borderRadius: "8px",
  },
  "& .thumbnail": {
    opacity: 0,
    filter: "none",
  },
  "&:hover .thumbnail": {
    opacity: 1,
  },
  "&.arrow-button": {
    "@mobile": {
      fontSize: "18px",
    },
  },

  variants: {
    bookingButton: {
      true: {
        fontSize: "14px",
        padding: "16px 32px",
        borderRadius: "19px",
        boxShadow: "0px 4px 0px 0px #000",
        transition: "all 0.2s",
        "&:hover": {
          boxShadow: "0px 0px 0px 0px #000",
          transform: "translateY(4px)",
          opacity: 0.8,
        },
      },
    },

    mobileSz14: {
      true: {
        "@mobile": {
          fontSize: "14px !important",
        },
      },
    },

    mobileSz16: {
      true: {
        "@mobile": {
          fontSize: "16px",
        },
      },
    },
    purpleButton: {
      true: {
        backgroundColor: "$lightPurple !important",
        borderRadius: "18px",
        fontSize: "12px",
        padding: "10px 0",
        fontWeight: "500",
        boxShadow: "0px 3px 0px #000000",
        "&:hover": {
          backgroundColor: "$lightPurple",
          opacity: "0.7",
        },
      },
    },
    isPlus: {
      true: {
        "& .link-out-img": {
          opacity: 0,
        },
        "&:hover": {
          "& .link-out-img": {
            opacity: 1,
          },
        },
      },
    },
    bottomBarButton: {
      true: {
        padding: "$0 $4",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "32px",
        borderRadius: "10px",
        width: "60px",
      },
      false: {},
    },
    menuOption: {
      true: {
        fontSize: "11px",

        padding: " 6px 4px",
        marginRight: "-4px",
        textAlign: "left",
        width: "calc(100% - 8px)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      false: {},
    },
    Theme: {
      light: {
        color: "black",
      },
      dark: {
        background: "$gray900",
        color: "white",
        borderColor: "white",
        // boxShadow: "0 2px 0 1px black",
        "@notmobile": {
          "&:hover": {
            background: "$gray800",
          },
        },
      },
    },
    transparent: {
      true: {
        background: "transparent",
        border: "none",
        boxShadow: "none",
      },
    },
    modeButton: {
      true: {
        position: "relative",
        "& .hotkey": {
          fontSize: "8px",
          position: "absolute",
          right: "7px",
          bottom: "5px",
          opacity: "0.6",
          "@mobile": {
            display: "none",
          },
        },
        "& img": {
          transform: "rotate(90deg)",
        },
      },
      false: {},
    },
    formButton: {
      true: {
        width: "100%",
        backgroundColor: "black",
        color: "white",
        height: "45px",
        padding: 0,
        borderRadius: "14px",
        fontSize: "13px",
        border: "none",
        "@notmobile": {
          "&:hover": {
            backgroundColor: "$gray800",
          },
        },
      },
    },
    lightweight: {
      true: {
        fontSize: "11px",
        backgroundColor: "$grayA100",
        marginTop: "$4",
        width: "100%",
        height: "28px",
        padding: "0",
        border: 0,
        marginBottom: "-12px",
        boxShadow: "none",
        "@mobile": {
          fontSize: "13px",
        },
      },
    },
    transparentHover: {
      true: {
        "&:hover": {
          background: "transparent",
        },
      },
    },
    darkHover: {
      true: {
        color: "white",
        backgroundColor: "black",
        "@notmobile": {
          "&:hover": {
            backgroundColor: "$gray800",
          },
        },
      },
    },
    smallTransparent: {
      true: {
        background: "transparent",
        border: "none",
        boxShadow: "none",
        padding: "0",
        marginLeft: "4px",
        marginTop: "-6px",
        borderRadius: "16px",
        fontSize: "12px",
        "@notmobile": {
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    small: {
      true: {
        padding: "$2",
        borderRadius: "0.5rem",
      },
    },
    overlay: {
      true: {
        border: "none",
        backgroundColor: "$wash",
        padding: "0",
      },
    },
    animateIn: {
      true: {
        animation: `${fadein} 2s ease-in-out`,
      },
    },
    noBorder: {
      true: {
        border: "none",
        fontWeight: "normal !important",
      },
    },
  },
  compoundVariants: [
    {
      Theme: "dark",
      purpleButton: true,
      css: {
        color: "black !important",
        border: "1px solid white !important",
        boxShadow: "0px 3px 0px white !important",
      },
    },
    {
      Theme: "light",
      modeButton: true,
      css: {
        background: "$wash",
      },
    },
    {
      Theme: "dark",
      modeButton: true,
      css: {
        border: "1px solid rgba(255, 255, 255, 0.5)",
        boxShadow: "none",
        "& img": {
          filter: "invert(1)",
        },
      },
    },
    {
      Theme: "dark",
      overlay: true,
      css: {
        backgroundColor: "$darkwash",
      },
    },

    {
      Theme: "dark",
      transparent: true,
      css: {
        background: "transparent",
        border: "none",
        boxShadow: "none",
        "&:hover": {
          background: "transparent",
          "&[role=menuitem]": {
            background: "$gray950",
          },
        },
      },
    },
    {
      Theme: "dark",
      lightweight: true,
      css: {
        backgroundColor: "$gray800",
        boxShadow: "none",
        "@notmobile": {
          "&:hover": {
            background: "$gray900",
          },
        },
      },
    },
  ],
});
