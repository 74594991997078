import { useContext, useEffect, useRef } from "react";
import { useLocation } from "wouter";
import { AuthContext } from "~/utils/useAuth";

export const RedirectPageWithKnownRef = ({ href }: { href: string }) => {
  useEffect(() => {
    window.location.href = href;
  }, [href]);

  return <div></div>;
};

const RedirectPage = () => {
  const [location, setLocation] = useLocation();
  const { user } = useContext(AuthContext)

  const didRedirect = useRef(false);

  useEffect(() => {
    // Ensure that this only runs once, even in Strict Mode
    // Wait until the auth has loaded before redirecting
    if (didRedirect.current === false) {
      didRedirect.current = true;
      console.log("Redirecting from Unknown Location: " + location);
      setLocation("/404", { replace: true });
    }
  }, [user]);

  return <div></div>;
};

export default RedirectPage;
