import { LiveList, shallow } from "@liveblocks/client";

import { AnimatePresence, motion } from "framer-motion";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import { styled, theme } from "~/ui/style/stitches.config";

import { addV2d, mulV2d } from "~/utils/math";
import {
  CARD_SPREAD_SIZE,
  CARD_BASE_RADIUS,
  SPREAD_HEIGHT_TO_ZOOM_INDEX,
  MOBILE_ZOOM_INDEX_MOD,
} from "../../utils/consts";
import {
  useMutateStorage,
  useSelf,
  useStorage,
} from "../../state/liveblocks.config";
import {
  RoomState,
  type SpreadCard as SpreadCardType,
  type Spread as SpreadType,
} from "../../types/room/types";
import { localState } from "~/state/state";

import { Tooltip } from "../../ui/components/SpreadTooltip";
import { useMousePosition, Vec2d } from "../../utils/useMousePosition";
import { Card } from "./Card";
import { Perspective } from "./Perspective";
import { ANGLE_DRAW, PERSPECTIVE_DRAW } from "~/pages/room/Room";
import { imageUrl } from "~/utils/imageurl";

const ASKEW = 2;
export const PADDING_UNITS: Vec2d = [0.1, 0.2];
interface Props {}

export const Spread = memo(function Spread({}: Props) {
  const selfConnectionId = useSelf((self) => self.connectionId);
  // activeSpread tracks global state spread and triggers animations locally
  const { activeCard, zoomIndex, isOnMobile } = useSnapshot(localState);
  const deck = useStorage((root) => root.deck, shallow);

  const spread = useStorage((root) => root.spread, shallow);
  const shouldRenderSelf = useStorage(
    (root) => root.state === RoomState.Draw,
    shallow
  );

  const cardIds = useStorage((root) => {
    return root.cards.map((card) => card.filePath);
  }, shallow);

  const theme = useStorage((root) => root.theme);
  const updateStorage = useMutateStorage();

  // Track active card to mouse position
  const activeStart = useRef<Vec2d>([0, 0]);
  const hoveredSlot = useRef<SpreadType["slots"][number] | null>(null);

  useEffect(() => {
    // when a new spread is set,
    if (spread.name !== localState.activeSpread.name) {
      // clear the slots
      localState.activeSpread.slots.forEach((slot) => {
        slot.cards = null;
      });
      // set zoom level

      const newZoom = SPREAD_HEIGHT_TO_ZOOM_INDEX(spread.size[1]);
      localState.zoomIndex = newZoom;

      // reset pan
    }
    localState.activeSpread = spread;
  }, [spread.name]);

  const start = useCallback(
    ([x, y]: Vec2d) => {
      updateStorage((storage) => {
        const active = storage
          .get("cards")
          .find((c) => c.filePath === localState.activeCard);
        if (active) {
          activeStart.current = active.position as Vec2d;
          storage.set(
            "cards",
            new LiveList(
              storage.get("cards").map((card) => {
                if (card.filePath === localState.activeCard) {
                  return {
                    ...card,
                    activeUser: selfConnectionId,
                  };
                }
                return card;
              })
            )
          );
        } else {
          const position: Vec2d = [
            x - deck.cardSize[0] / 2,
            y - deck.cardSize[1] / 2,
          ];
          activeStart.current = position;
          if (localState.activeCard === null)
            throw new Error("No active card found, something's wrong");

          // Insert card if necessary
          storage.get("cards").push({
            filePath: localState.activeCard,
            size: deck.cardSize,
            border: deck.border,
            deckName: deck.name,
            fallbackColor: deck.fallbackColor,
            borderColor: deck.borderColor,
            keywords:
              deck.cards.find((card) => card.filePath === localState.activeCard)
                ?.keywords ?? [],
            keywordsReverse:
              deck.cards.find((card) => card.filePath === localState.activeCard)
                ?.keywordsReverse ?? [],
            searches:
              deck.cards.find((card) => card.filePath === localState.activeCard)
                ?.searches ?? [],
            backFilePath: deck.backArtwork
              ? deck.basePath + "/" + deck.backArtwork
              : null,
            foilPath: deck.foilPath
              ? localState.activeCard.split(".jpeg").join(deck.foilPath)
              : undefined,
            position,
            open: false,
            focused: false,
            rotation: 0,
            reversed: Math.random() < 0.5 ? true : false,
            activeUser: selfConnectionId,
          });
        }
      });
    },
    [deck]
  );

  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const move = useCallback(([_x, _y]: Vec2d, [dx, dy]: Vec2d) => {
    // if card has been moved out of the spread slots, remove it from cards list

    const cardToRemoveFromSlots = localState.activeCard;
    if (!hoveredSlot.current && cardToRemoveFromSlots) {
      localState.activeSpread!.slots.forEach((s) => {
        if (s.cards) {
          while (s.cards.indexOf(cardToRemoveFromSlots) >= 0) {
            s.cards.splice(s.cards.indexOf(cardToRemoveFromSlots), 1);
          }
        }
      });
      updateStorage((storage) => {
        storage.set("spread", localState.activeSpread);
      });
    }

    setCursorPos({ x: _x, y: _y });

    updateStorage((storage) => {
      const cardsList = new LiveList(
        storage.get("cards").map((card) => {
          if (card.filePath === localState.activeCard) {
            return {
              ...card,
              position: [
                // ensure the card is grabbed in a consistent place
                activeStart.current[0] + dx,
                activeStart.current[1] + dy,
              ],
            };
          }
          return card;
        })
      );
      if (!cardsList) {
        return;
      }
      storage.set("cards", cardsList as LiveList<SpreadCardType>);
    });
  }, []);

  const stop = useCallback(() => {
    if (!hoveredSlot) return;

    updateStorage((storage) => {
      const active = storage
        .get("cards")
        .find((c) => c.filePath === localState.activeCard) as
        | SpreadCardType
        | undefined;
      if (!active) throw new Error("No active card found, something's wrong");

      const cardList = new LiveList([
        ...storage
          .get("cards")
          .filter((card) => card.filePath !== localState.activeCard),
        // Put this card on top at the end
        {
          ...active,
          position: hoveredSlot.current
            ? mulV2d(
                withPadding(hoveredSlot.current.position) as Vec2d,
                CARD_SPREAD_SIZE as Vec2d
              )
            : active.position,
          rotation:
            (hoveredSlot.current?.rotated ? 90 : 0) +
            Math.random() * ASKEW -
            ASKEW / 2, // slightly place cards askew on drop
          activeUser: null,
        },
      ]);
      if (!cardList) {
        return;
      }
      storage.set("cards", cardList);
    });
    localState.activeCard = null;
  }, []);
  const { current } = useMousePosition(activeCard !== null, {
    start,
    move,
    stop,
  });

  if (!shouldRenderSelf) return <></>;
  return (
    <Perspective
      id="spread"
      key="spread"
      perspective={PERSPECTIVE_DRAW}
      angle={ANGLE_DRAW}
      exit={{ opacity: 0, transition: { duration: 0.6 } }}
    >
      <motion.div
        style={{
          transformStyle: "preserve-3d",
        }}
        initial={{
          opacity: 0,
          z: -40,
        }}
        animate={{
          opacity: 1,
          z: 0,
        }}
        transition={{
          type: "tween",
          ease: "easeOut",
          duration: 1,
          delay: 2,
        }}
      >
        <StyledSpreadGrid
          Theme={theme}
          activeCard={activeCard !== null}
          style={{
            width:
              (withPadding(spread.size)[0] - PADDING_UNITS[0]) *
              CARD_SPREAD_SIZE[0],
            height:
              (withPadding(spread.size)[1] - PADDING_UNITS[1]) *
              CARD_SPREAD_SIZE[1],
            transform: `translate(-50%, calc(-50% - ${
              80 // offset from bottom to prevent covering by hand
            }px))`,
          }}
        >
          {spread.slots.map((slot, i) => (
            <StyledSlotContainer
              key={slot.name + i}
              style={{
                transform: `translate(${
                  withPadding(slot.position)[0] * CARD_SPREAD_SIZE[0]
                }px, ${withPadding(slot.position)[1] * CARD_SPREAD_SIZE[1]}px)`,
                pointerEvents: "none",
              }}
            >
              <StyledSlot
                Theme={theme}
                onPointerUp={() => {
                  const cardToAdd = activeCard;
                  // Add card to current slot
                  if (!slot.cards) slot.cards = [];
                  if (slot.cards && cardToAdd) slot.cards.push(cardToAdd);
                  // Remove card from other slots
                  localState.activeSpread!.slots.forEach((s) => {
                    if (s !== slot && s.cards && cardToAdd) {
                      while (s.cards.indexOf(cardToAdd) >= 0) {
                        s.cards.splice(s.cards.indexOf(cardToAdd), 1);
                      }
                    }
                  });
                  updateStorage((storage) =>
                    storage.set("spread", localState.activeSpread)
                  );
                }}
                onPointerOver={() => {
                  hoveredSlot.current = { ...slot };
                }}
                onPointerOut={() => {
                  hoveredSlot.current = null;
                  // Remove card from slot
                  const cardToRemove = activeCard;
                  if (!slot.cards) slot.cards = [];
                  if (
                    slot.cards &&
                    cardToRemove &&
                    slot.cards.indexOf(cardToRemove) >= 0
                  ) {
                    while (slot.cards.indexOf(cardToRemove) >= 0) {
                      slot.cards.splice(slot.cards.indexOf(cardToRemove), 1);
                    }
                  }
                }}
                style={{
                  width: CARD_SPREAD_SIZE[0],
                  height: CARD_SPREAD_SIZE[1],
                  borderRadius:
                    (CARD_BASE_RADIUS * CARD_SPREAD_SIZE[0]) /
                    CARD_SPREAD_SIZE[0],
                  rotate: slot.rotated ? "90deg" : "none",
                  pointerEvents: "auto",
                  opacity: !slot.cards || slot.cards.length === 0 ? 1 : 0,
                }}
              />
              <div
                style={{
                  position: "relative",
                  display: "contents",
                }}
              >
                <Tooltip content={slot.description}>
                  <StyledSlotLabel
                    Theme={theme}
                    style={{
                      marginTop: slot.rotated ? -CARD_SPREAD_SIZE[1] * 0.3 : 0,
                      pointerEvents: activeCard ? "none" : "auto",
                      transform: `scale(${1 / 1.11 ** zoomIndex})`,
                      transformOrigin: "top center",
                      width: CARD_SPREAD_SIZE[0] * 1.11 ** zoomIndex,
                      lineHeight: 0.8,
                    }}
                  >
                    {slot.name}
                  </StyledSlotLabel>
                </Tooltip>
              </div>
            </StyledSlotContainer>
          ))}
          <AnimatePresence>
            {cardIds.map((card, i) => (
              <SpreadCard key={card} cardId={card} currentMouseRef={current} />
            ))}
          </AnimatePresence>
        </StyledSpreadGrid>
      </motion.div>
    </Perspective>
  );
});

const LIFT = 50;
const LIFT_ROT_X = 25;

const SpreadCard = memo(function SpreadCard({
  cardId,
  currentMouseRef,
}: {
  cardId: string;
  currentMouseRef: React.MutableRefObject<Vec2d>;
}) {
  const { activeCard, isOnMobile, lastViewedCard } = useSnapshot(localState);
  const reversesOn = useStorage((root) => root.reversesOn);
  const theme = useStorage((root) => root.theme);
  const card = useStorage(
    (root) => root.cards.find((card) => card.filePath === cardId),
    shallow
  ) as SpreadCardType;
  const updateStorage = useMutateStorage();
  const connectionId = useSelf((self) => self.connectionId);

  const [hasMovedIn_ms, setHasMovedIn_ms] = useState(false);
  const [currentTimer, setCurrentTimer] = useState<number | null>(null);

  const position = card ? card.position : null;

  const timerFn = () => setHasMovedIn_ms(false);
  // if card hasn't moved at all in >5s, release locked state
  useEffect(() => {
    setHasMovedIn_ms(true);
    if (currentTimer) {
      window.clearTimeout(currentTimer);
      setCurrentTimer(null);
    }
    const tmr = window.setTimeout(timerFn, 5000);
    setCurrentTimer(tmr);
  }, [position]);

  if (!card) return <></>;

  const {
    filePath,
    backFilePath,
    size,
    border,
    borderColor,
    open,
    focused,
    rotation,
    reversed,
    activeUser,
    fallbackColor,
    foilPath,
    keywords,
    slot,
  } = card;

  const isLocked =
    hasMovedIn_ms && activeUser !== connectionId && activeUser !== null;

  return (
    <>
      <motion.div
        className="card"
        style={{
          transformStyle: "preserve-3d",
          position: "absolute",
          left: 0,
          top: 0,
          pointerEvents: "none",
          x: position ? position[0] : 0,
          y: position ? position[1] : 0,
          z: focused ? "10px" : 0,
        }}
        animate={{
          rotateZ: focused ? 0 : rotation, // remove askew rotation when focused to prevent clipping into other cards
        }}
        exit={{
          y: window.innerHeight,
        }}
      >
        <motion.div
          className="card"
          style={{
            transformStyle: "preserve-3d",
            transformOrigin: "50% 100%",
            z: 1, // clipping into spread preventing clicks
          }}
          animate={{
            ...(focused ? { rotateX: -LIFT_ROT_X } : {}),
          }}
        >
          <Card
            shadow={true}
            theme={theme}
            src={filePath.includes(".mp4") ? filePath : imageUrl(filePath)}
            backSrc={backFilePath ? imageUrl(backFilePath) : null}
            size={size}
            border={border}
            borderColor={borderColor}
            fallbackColor={fallbackColor}
            reversed={reversesOn && reversed}
            foilPath={foilPath}
            foilTopOffset={14}
            foilHeightOffset={0}
            position={position}
            slot={undefined}
            onPanStart={() => {
              localState.activeCard = card.filePath;
              updateStorage((storage) =>
                storage.set(
                  "cards",
                  new LiveList(
                    storage.get("cards").map((card) => {
                      // Focus card
                      return {
                        ...card,
                        focused: false,
                      };
                    })
                  )
                )
              );
            }}
            onClick={() => {
              updateStorage((storage) =>
                storage.set(
                  "cards",
                  new LiveList(
                    storage.get("cards").map((card) => {
                      if (card.filePath === filePath) {
                        if (!card.open) {
                          localState.lastViewedCard = card;
                          return {
                            ...card,
                            open: !card.open,
                          };
                        } else {
                          // Focus card
                          localState.lastViewedCard = card;

                          return {
                            ...card,
                            focused: !card.focused,
                          };
                        }
                      }
                      return card;
                    })
                  )
                )
              );
            }}
            open={open}
            style={{
              width: size[0],
              height: size[1],
              pointerEvents: isLocked || activeCard !== null ? "none" : "auto",
              cursor: "pointer",
              zIndex: 1,
              rotateZ: reversesOn && reversed ? 180 : 0,
            }}
            animate={{
              z: activeCard === filePath ? LIFT : 1,
            }}
          />
        </motion.div>
      </motion.div>
    </>
  );
});

const StyledSlotContainer = styled("div", {
  position: "absolute",
  maxWidth: "100%",
  top: 0,
  left: 0,
  display: "grid",
  placeItems: "center",
  gap: "1rem",
  transition: "all 0.3s ease-in",
});

const StyledSlotLabel = styled("span", {
  fontFamily: "KeplerLightCondensed",
  fontSize: "1.9rem",
  letterSpacing: "-0.02ch",
  maxWidth: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",

  borderRadius: "12px",
  "@mobile": {
    fontSize: "4rem",
    lineHeight: "1.9",
    padding: "6px 20px 3px 20px ",
    borderRadius: "20px",
  },
  variants: {
    Theme: {
      light: {},
      dark: {
        "@mobile": {},
      },
    },
  },
});

const StyledSlot = styled("div", {
  "&::before": {
    content: "",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "$wash",
    borderRadius: "30px",
    opacity: "0.1",
  },
  position: "relative",
  background: "rgba(0, 0, 0, 0.03)",
  border: "1px solid rgba(0,0,0,.5)",
  transition: "all 0.3s ease-in",
  variants: {
    Theme: {
      light: {},
      dark: {
        border: "1px solid rgba(255,255,255,0.5)",
        background: "rgba(255,255,255,0.03)",
        "&::before": {
          backgroundColor: "$darkwash",
        },
      },
    },
  },
});

const StyledSpreadGrid = styled("div", {
  position: "absolute",
  top: "50%",
  left: "50%",
  transformStyle: "preserve-3d",

  variants: {
    Theme: {
      light: {},
      dark: {},
    },
    activeCard: {
      true: {
        cursor: "grabbing",
        [`& ${StyledSlot}:hover`]: {
          background: "rgba(0,0,0,.1)",
          boxShadow: "0 0 100px 0 white",
          borderColor: "rgba(0,0,0,.3)",
        },
      },
    },
  },
  compoundVariants: [
    {
      Theme: "dark",
      activeCard: true,
      css: {
        [`& ${StyledSlot}:hover`]: {
          background: "rgba(255,255,255,.1)",
          boxShadow: "0 0 100px 0 rgba(255,255,255,.15)",
          borderColor: "white",
        },
      },
    },
  ],
});

const StyledCardInfo = styled(motion.article, {
  background: "rgba(255,255,255,.8)",
  backdropFilter: "blur(10px)",
  width: "100%",
  position: "absolute",
  borderRadius: 20,
  border: "1px solid rgba(0,0,0,1)",
  pointerEvents: "auto",
  padding: "1rem",
  fontSize: ".75rem",
  display: "flex",
  flexDirection: "column",
  gap: ".5rem",
  variants: {
    Theme: {
      light: {},
      dark: {
        background: "$gray800",
      },
    },
  },
});

const StyledCardInfoDetails = styled("details", {
  "&[open] summary": {
    display: "none",
  },
  "& summary": {
    display: "flex",
    background: "rgba(0,0,0,.05)",
    padding: ".25rem",
    borderRadius: ".25rem",
    cursor: "pointer",
    placeContent: "center",
  },
  variants: {
    Theme: {
      light: {},
      dark: {
        "& summary": {
          background: "$gray900",
        },
      },
    },
  },
});

// Spread JSON doesn't have padding since the editor doesn't display slot titles the same way
export function withPadding(vec: Vec2d) {
  return addV2d(vec, mulV2d(vec, PADDING_UNITS));
}
