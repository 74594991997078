import { styled } from "@stitches/react";
import { motion } from "framer-motion";
import { useStorage } from "../../state/liveblocks.config";
import { Vec2d } from "../../utils/useMousePosition";

import { addV2d, divV2d, mulV2d, subV2d } from "~/utils/math";
import {
  CARD_SPREAD_SIZE,
  MOBILE_ZOOM_INDEX_MOD,
  PAN_BASE,
  ZOOM_BASE,
} from "~/utils/consts";
import { Shape, Spread } from "~/types/room/types";
import { localState } from "~/state/state";
import { useSnapshot } from "valtio";
import { keyframes } from "~/ui/style/stitches.config";
import { withPadding } from "../room/Spread";

export const ShapeItem = (props: {
  shape: Shape;
  shapeIdx: number;
  vanishing: boolean;
}) => {
  const spread = useStorage((root) => root.spread);
  const { pan, zoomIndex, isOnMobile } = useSnapshot(localState);
  const zoomIndexMod = isOnMobile ? MOBILE_ZOOM_INDEX_MOD : 0;

  const zoomVector: Vec2d = [
    1 / ZOOM_BASE ** (zoomIndex + zoomIndexMod),
    1 / ZOOM_BASE ** (zoomIndex + zoomIndexMod),
  ];

  return (
    <StyledShapeItem
      id={props.shape.id}
      key={props.shape.id}
      className={props.vanishing ? "vanishing" : ""}
    >
      {props.shape.positions.map((xy, i) => {
        if (i >= props.shape.positions.length) {
          return <div key={props.shape.id + i}></div>;
        }
        const xy2World = props.shape.positions[i + 1];
        if (!xy2World) return <div key={props.shape.id + i}></div>;

        const padding = 2.5;
        const offsetVec = [3, 28] as Vec2d;

        const xy1 = spreadToScreen(
          addV2d(xy, offsetVec),
          spread,
          pan as Vec2d,
          zoomVector
        );
        const xy2 = spreadToScreen(
          addV2d(xy2World, offsetVec),
          spread,
          pan as Vec2d,
          zoomVector
        );

        const o = xy2[1] - xy1[1];
        const a = xy2[0] - xy1[0];
        const h = Math.sqrt(
          Math.pow(xy2[0] - xy1[0], 2) + Math.pow(xy2[1] - xy1[1], 2)
        );
        if (o === 0 && a === 0 && h === 0) return;
        let theta = Math.asin(o / h);
        if (xy2[0] < xy1[0]) {
          theta = Math.acos(a / h);
          if (xy2[1] < xy1[1]) {
            theta = -theta;
          }
        }

        return (
          <motion.div
            key={props.shape.id + i}
            style={{
              marginLeft: xy1[0],
              marginTop: xy1[1],
              width: `${h + padding}px`,
              height: "4px",
              position: "fixed",
              boxShadow: `0px 0px 5px ${props.shape.color}`,
              backgroundColor: props.shape.color,
              left: "0",
              top: "0",
              borderRadius: "10px",
              transform: `rotate(${theta}rad)`,
              transformOrigin: "0 0",
              pointerEvents: "none",
            }}
          ></motion.div>
        );
      })}
    </StyledShapeItem>
  );
};

const fade = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const StyledShapeItem = styled(motion.div, {
  "&.vanishing": {
    animation: `${fade} 5000ms`,
    opacity: 0,
  },
});

export function spreadToScreen(
  xy: Vec2d,
  spread: Spread,
  pan: Vec2d,
  zoomVector: Vec2d
) {
  const W = [window.innerWidth / 2, window.innerHeight / 2] as Vec2d;

  const extras = [
    (withPadding(spread.size)[0] * CARD_SPREAD_SIZE[0] - window.innerWidth) / 2,
    (withPadding(spread.size)[1] * CARD_SPREAD_SIZE[1] - window.innerHeight) /
      2,
  ] as Vec2d;
  const panVec = mulV2d(pan as Vec2d, [PAN_BASE, PAN_BASE]) as Vec2d;

  const subtractedXY = subV2d(addV2d(subV2d(xy, W), panVec), extras);
  const divXY = divV2d(subtractedXY, zoomVector);
  const finalXY = addV2d(divXY, W);

  return finalXY;
}
