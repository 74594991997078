import { useState, useEffect, FunctionComponent } from "react";
import { UserProfile, getUserByUsername } from "~/api/userApi";
import { ProPage } from "./ProPage";
import UserPage from "./UserPage";
import { SimpleLoadingIndicator } from "../loading/LoadingPage";
import { getAvailabilityByUuid } from "~/api/calendarApi";

type State = {
  username: string;
  isProfessional: boolean;
  backgroundColor: string;
};

const ProfilePage = ({ username }: { username: string }) => {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [minPrice, setMinPrice] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (username) {
      getUserByUsername(username)
        .then((response) => {
          const user = response;
          setUser(user);
          if (user.isProfessional) {
            // get minPrice

            getAvailabilityByUuid(user.uuid).then((availabilityData) => {
              const offeredServices = availabilityData?.services
                ? availabilityData.services.filter((s) => s.offered)
                : [];
              const lowestPrice = Math.min(
                ...offeredServices.map((s) => s.price)
              );
              setMinPrice(lowestPrice);
            });
          }
        })
        .catch(() => {
          window.location.href = "/404";
        });
    }
  }, [username]);

  return user ? (
    user.isProfessional ? (
      <ProPage pageUser={user} minPrice={minPrice} />
    ) : (
      <UserPage pageUser={user} />
    )
  ) : (
    <SimpleLoadingIndicator />
  );
};

export default ProfilePage;
