import React, { useState } from "react";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "./Button";
import {
  createDecksPaymentIntent,
  createSessionPaymentIntent,
} from "~/api/paymentsApi";
import { SimpleLoadingIndicator } from "~/pages/loading/LoadingPage";
import { UserProfile } from "~/api/userApi";
import { localState } from "~/state/state";
import { ms } from "date-fns/locale";
import {
  sendErrorToast,
  sendServerErrorToastWithFallbackCode,
  sendToastMessageWithFallbackCode,
} from "~/utils/handleToasts";
import { keyframes, styled } from "../style/stitches.config";

export interface CheckoutProps {
  pro: UserProfile;
  useremail: string;
  amount: number; // amount in cents
  stripeConnectId: string;
  description: string;
  metadata: Record<string, string>;
  onSuccess: (paymentIntentId: string) => void; //
  buttonStyle?: any;
  setIsSubmittingPayment: Function;
  isSubmittingPayment: boolean;
}

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY!);

export const StripeCheckout: React.FC<CheckoutProps> = (props) => {
  if (props.amount < 500) {
    console.warn(`StripeCheckout: the amount ${props.amount} is less than 500 ($5.00), which is not supported by Stripe, \
        and indicates a likely error. Please make sure you are passing the amount in cents, not dollars.`);
  }
  const options: StripeElementsOptions = {
    mode: "payment",
    amount: props.amount,
    currency: "usd",

    // Fully customizable with appearance API.
    appearance: {
      rules: {
        ".Input": {
          boxShadow: "none",
          borderRadius: "11px",
        },
        ".Input:focus": {
          border: "1px solid black",
          outline: "none",
          boxShadow: "none",
        },
      },
      /*...*/
    },
    capture_method: "manual",
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        pro={props.pro}
        useremail={props.useremail}
        amount={props.amount}
        stripeConnectId={props.stripeConnectId}
        description={props.description}
        metadata={props.metadata}
        onSuccess={props.onSuccess}
        buttonStyle={props.buttonStyle}
        setIsSubmittingPayment={props.setIsSubmittingPayment}
        isSubmittingPayment={props.isSubmittingPayment}
      />
    </Elements>
  );
};

const CheckoutForm = ({
  pro,
  useremail,
  amount,
  description,
  metadata,
  onSuccess,
  buttonStyle,
  setIsSubmittingPayment,
  isSubmittingPayment,
}: CheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent) => {
    setIsSubmittingPayment(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (elements == null) {
      return;
    }

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        // If stripe provided a message, show that directly. Otherwise show our fallback error
        sendToastMessageWithFallbackCode(
          submitError.message || null,
          true,
          1144
        );
        setIsSubmittingPayment(false);
        return;
      }

      const { paymentIntentId, clientSecret } =
        await createSessionPaymentIntent({
          proId: pro.uuid,
          amount,
          description,
          metadata,
        });

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.origin + "/booking-success",
        },
      });

      if (error) {
        // If stripe provided a message, show that directly. Otherwise show our fallback error
        sendToastMessageWithFallbackCode(error.message || null, true, 1145);
        setIsSubmittingPayment(false);
        return;
      }

      onSuccess(paymentIntentId);
    } catch (error) {
      // Else it's our server error, show our fallback error if no code provided
      sendServerErrorToastWithFallbackCode(error, 1146);
      setIsSubmittingPayment(false);
    }
  };

  return (
    <>
      <PaymentElement
        className={"sentry-mask"}
        options={{
          defaultValues: {
            billingDetails: {
              email: useremail,
            },
          },
        }}
      />
      <Button
        bookingButton
        style={buttonStyle}
        onClick={handleSubmit}
        disabled={!stripe || !elements}
      >
        {isSubmittingPayment ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              opacity: 0.8,
              marginTop: "4px",
              transform: "scale(0.5)",
            }}
          >
            <SimpleLoadingIndicator />
          </div>
        ) : (
          "Request"
        )}
      </Button>
    </>
  );
};

/* DECKS */

export const StripeDeckCheckout: React.FC<DeckCheckoutProps> = (props) => {
  if (props.amount < 500) {
    console.warn(`StripeCheckout: the amount ${props.amount} is less than 500 ($5.00), which is not supported by Stripe, \
        and indicates a likely error. Please make sure you are passing the amount in cents, not dollars.`);
  }

  const options: StripeElementsOptions = {
    mode: "payment",
    amount: props.amount,
    currency: "usd",

    // Fully customizable with appearance API.
    appearance: {
      rules: {
        ".Input": {
          boxShadow: "none",
          borderRadius: "11px",
        },
        ".Input:focus": {
          border: "1px solid black",
          outline: "none",
          boxShadow: "none",
        },
      },
      /*...*/
    },
    capture_method: "manual",
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <DeckCheckoutForm
        deckIds={props.deckIds}
        amount={props.amount}
        useremail={props.useremail}
        metadata={props.metadata}
        onSuccess={props.onSuccess}
        buttonStyle={props.buttonStyle}
        setIsSubmittingPayment={props.setIsSubmittingPayment}
        isSubmittingPayment={props.isSubmittingPayment}
      />
    </Elements>
  );
};

export interface DeckCheckoutProps {
  deckIds: string[];
  amount: number;
  useremail: string;
  metadata: Record<string, string>;
  onSuccess: (
    eckIds: string[],
    paymentIntentId: string,
    paymentGroupId: string
  ) => Promise<void>;
  buttonStyle?: any;
  setIsSubmittingPayment: Function;
  isSubmittingPayment: boolean;
}

const DeckCheckoutForm = ({
  deckIds,
  amount,
  useremail,
  metadata,
  onSuccess,
  buttonStyle,
  setIsSubmittingPayment,
  isSubmittingPayment,
}: DeckCheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent) => {
    setIsSubmittingPayment(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (elements == null) {
      return;
    }

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        // If stripe provided a message, show that directly. Otherwise show our fallback error
        sendToastMessageWithFallbackCode(
          submitError.message || null,
          true,
          1144
        );
        setIsSubmittingPayment(false);
        return;
      }

      const { paymentIntentId, clientSecret, paymentGroupId } =
        await createDecksPaymentIntent({
          deckIds,
          metadata,
        });

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.origin + "/booking-success",
        },
      });

      if (error) {
        // If stripe provided a message, show that directly. Otherwise show our fallback error
        sendToastMessageWithFallbackCode(error.message || null, true, 1145);
        setIsSubmittingPayment(false);
        return;
      }

      await onSuccess(deckIds, paymentIntentId, paymentGroupId);
    } catch (error) {
      // Else it's our server error, show our fallback error if no code provided
      sendServerErrorToastWithFallbackCode(error, 1146);
      setIsSubmittingPayment(false);
    }
  };

  return (
    <>
      <PaymentElement
        className={"sentry-mask"}
        options={{
          defaultValues: {
            billingDetails: {
              email: useremail,
            },
          },
        }}
      />
      <Button
        bookingButton
        style={{
          ...buttonStyle,
          pointerEvents: isSubmittingPayment ? "none" : "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "56px",
        }}
        onClick={handleSubmit}
        disabled={!stripe || !elements}
      >
        {isSubmittingPayment ? (
          <>
            <div
              style={{
                position: "relative",
                width: "40px",
                height: "60px",
                transform: "scale(0.4)",
                opacity: 0.8,
                marginTop: "8px",
              }}
            >
              <SimpleLoadingIndicator />
            </div>
            <div>
              Processing
              <StyledDot className="dot1">.</StyledDot>
              <StyledDot className="dot2">.</StyledDot>
              <StyledDot className="dot3">.</StyledDot>
            </div>
          </>
        ) : (
          "Pay now"
        )}
      </Button>
    </>
  );
};

const dot1Animation = keyframes({
  "0%": { opacity: 0 },
  "60%": { opacity: 1 },
  "100%": { opacity: 1 },
});

const dot2Animation = keyframes({
  "20%": { opacity: 0 },
  "80%": { opacity: 1 },
  "100%": { opacity: 1 },
});

const dot3Animation = keyframes({
  "40%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const StyledDot = styled("span", {
  opacity: 0,
  marginLeft: "1px",
  "&.dot1": {
    animation: `${dot1Animation} 1.5s infinite`,
  },
  "&.dot2": {
    animation: `${dot2Animation} 1.5s infinite`,
  },
  "&.dot3": {
    animation: `${dot3Animation} 1.5s infinite`,
  },
});
