import { styled } from "@stitches/react";
import { useLocation } from "wouter";
import { Button } from "~/ui/components/Button";
import { StyledErrorPage } from "./PageNotFound";
import { createRoom, deleteRoom, getMyRooms } from "~/api/roomApi";
import { MAX_ROOM_ATTENDEES } from "~/utils/consts";
import { useEffect } from "react";
import { localState } from "~/state/state";

export const RoomFull = () => {
  let [_, setLocation] = useLocation();

  return (
    <StyledErrorPage>
      <div className="message-container">
        <div className="title">This tarot room is full!</div>
        <div className="subtitle">
          We allow space for {MAX_ROOM_ATTENDEES} people in each session.
          <br></br>
          Reach out to your host to find an opening.
        </div>
        <Button onClick={() => setLocation("/")}>Return home</Button>
      </div>
    </StyledErrorPage>
  );
};
function updateRoomList() {
  throw new Error("Function not implemented.");
}
