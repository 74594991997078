import { styled } from "~/ui/style/stitches.config";
import React from "react";

export const ThemedApp = React.memo(
  styled("div", {
    overflow: "hidden !important",
    position: "fixed",
    backgroundSize: "cover !important",
    backgroundColor: "$wash !important",
    backgroundPosition: "center !important",
    width: "100vw",
    height: "100vh",
    "&:focus": {
      outline: "none",
    },
    variants: {
      Theme: {
        light: {},
        dark: {
          backgroundColor: "$darkwash !important",
          color: "white",
        },
      },
      mode: {
        pen: {
          cursor: "url('../images/room/pen-tool.svg'), auto",
          "& .hand-card, & .card": {
            pointerEvents: "none !important",
          },
          "& #hand": {
            pointerEvents: "none !important",
          },
        },
        type: {
          cursor: "text",
          "& .hand-card, & .card": {
            pointerEvents: "none !important",
          },
          "& #hand": {
            pointerEvents: "none !important",
          },
        },
        auto: {},
      },
      isFocusedTyping: {
        true: {},
        false: {},
      },
    },
    compoundVariants: [
      {
        mode: "type",
        isFocusedTyping: true,
        css: {
          cursor: "auto !important",
        },
      },
    ],
  })
);
