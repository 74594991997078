import { Service } from "~/api/calendarApi";
import { Button } from "~/ui/components/Button";
import { DisclaimerContainer } from "~/ui/components/DisclaimerContainer";

export const ServiceSelectorPage = ({
  offeredServices,
  accentColor,
  onClick,
}: {
  offeredServices: Service[];
  accentColor: string;
  onClick: Function;
}) => {
  return (
    <>
      {offeredServices?.map((s) => {
        return (
          <div className="offering" key={s.name}>
            <div className="name">{s.name}</div>
            <div className="line-container">
              <div className="price">{`$${s.price} USD`}</div>
              <div className="duration">
                <img src="/images/video.svg" alt="video" />
                {s.duration < 0
                  ? "15 min"
                  : s.duration === 30
                  ? "30 min"
                  : "1 hour"}
              </div>
            </div>
            <div className="description">{s.description}</div>
            <Button
              Theme={"light"}
              bookingButton
              style={{
                backgroundColor: accentColor,
                marginTop: "26px",
              }}
              onClick={() => {
                onClick(s);
              }}
            >
              {s.duration < 0 ? "Book a video" : "Select a time"}
            </Button>
          </div>
        );
      })}
      <DisclaimerContainer />
    </>
  );
};
