export function lerp(a: number, b: number, t: number) {
  return a * (1 - t) + b * t;
}

export function iLerp(a: number, b: number, v: number) {
  if (b - a === 0) return v - a;
  return (v - a) / (b - a);
}

export function clamp(v: number, min: number, max: number) {
  return Math.min(Math.max(v, min), max);
}

// Like clamp but returns min if v is outside the range
export function clip(v: number, min: number, max: number) {
  return v < min ? min : v > max ? min : v;
}

export function map(v: number, a1: number, a2: number, b1: number, b2: number) {
  return lerp(b1, b2, iLerp(a1, a2, v));
}

// Interpolates a value within a range a1 to a2 with a parabola, with the middle of the a range mapped to b2, and a1 and a2 mapped to b1
export function arc(
  v: number,
  a1: number,
  a2: number,
  b1: number,
  b2: number,
  exp: number = 2
) {
  return lerp(b1, b2, iLerp((a2 - a1) / 2, a2, v) ** exp);
}
