import { Card } from "./Card";
import { arc, clip, lerp, map } from "../../utils/lerp";
import { startTransition, useEffect, useMemo, useState } from "react";
import { styled } from "~/ui/style/stitches.config";
import { AnimatePresence, motion } from "framer-motion";
import { CARD_HAND_WIDTH } from "../../utils/consts";
import {
  useMutateStorage,
  useSelf,
  useStorage,
} from "../../state/liveblocks.config";
import { LiveList, shallow } from "@liveblocks/client";
import { RoomState, DeckCard } from "../../types/room/types";
import { localState } from "~/state/state";
import { useSnapshot } from "valtio";
import React from "react";
import { CardStackIcon } from "@radix-ui/react-icons";
import { isCardSearched } from "~/utils/cardSearch";
import { debounce } from "~/utils/debounce";
import { imageUrl } from "~/utils/imageurl";

const DUCK = 70;
const UP = 50;
const WIDTH = 840;
const ROTATION = 14;
const ORIGIN = { x: 1, y: 1 };
const INC_Y = -0.004;
const ARC_Y = 53;
const NEIGHBOR_CT = 10;
const NEIGHBOR_HOVER = 3;
const HOVERING_Y = 50;
const HOVERING_SCALE = 1.2;

interface Props {}

export const Hand = React.memo(function Hand({}: {}) {
  const cardIds = useStorage(
    (root) => root.cards.map((card) => card.filePath),
    shallow
  );
  const shouldRenderSelf = useStorage(
    (root) => root.state === RoomState.Draw,
    shallow
  );

  const deck = useStorage((root) => root.deck, shallow);
  const cardHandHeight =
    (CARD_HAND_WIDTH * deck.cardSize[1]) / deck.cardSize[0];
  const theme = useStorage((root) => root.theme);

  const { activeCard, isOnMobile } = useSnapshot(localState);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const searchTerm = useStorage((root) => root.searchTerm);

  let hoverIndex = -99;
  // only track hover index if not on mobile
  if (!isOnMobile) hoverIndex = useStorage((root) => root.handHoverIndex);

  const updateStorage = useMutateStorage();

  const shuffledCards = useMemo(
    () => [...deck.cards].sort(() => Math.random() - 0.5),
    [deck]
  );

  const [filteredShuffledCards, setFilteredShuffledCards] = useState<
    DeckCard[]
  >([]);

  const filterCards = () => {
    let newFilteredCards = [
      ...shuffledCards.filter((card) =>
        isCardSearched(
          card.name ?? "",
          card.searches ?? [],
          deck.translations ?? [],
          searchTerm
        )
      ),
    ];
    if (newFilteredCards.length === 0) newFilteredCards = [...shuffledCards];
    setFilteredShuffledCards(newFilteredCards);
  };

  const debouncedFilterCards = debounce(filterCards, 600);

  useEffect(() => {
    debouncedFilterCards();
  }, [shuffledCards, activeCard, cardIds, searchTerm]);

  const [isUp, setIsUp] = useState(false);
  const [isHandVisible, setIsHandVisible] = useState(false);

  if (!shouldRenderSelf) return <></>;
  return (
    <>
      <StyledHitArea
        onPointerOver={() => {
          setIsUp(true);
        }}
        onPointerOut={() => {
          setIsUp(false);
        }}
        onPointerUp={() => {
          // don't put card back if on mobile
          if (localState.isOnMobile) return;
          // Put card back
          updateStorage((storage) => {
            if (localState.activeCard)
              // Remove card from cards in spread
              storage.set(
                "cards",
                new LiveList(
                  storage.get("cards").filter((c) => c.filePath !== activeCard)
                )
              );
            localState.activeCard = null;
          });
        }}
        id="hand"
        style={{
          pointerEvents: isHandVisible ? "auto" : "none",
        }}
      >
        <StyledHandContainer
          id="hand"
          animate={[isUp ? "up" : "duck"]}
          variants={{
            duck: {
              y: DUCK,
            },
            up: {
              y: UP,
            },
          }}
        >
          <motion.div
            initial={{
              y: window.innerHeight / 3,
            }}
            animate={{
              y: 0,
            }}
            onAnimationComplete={() => {
              setIsHandVisible(true);
            }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 60,
              delay: isFirstLoad ? 1 : 0,
            }}
          >
            <AnimatePresence>
              {filteredShuffledCards
                .filter(
                  (card) =>
                    activeCard !== card.filePath &&
                    cardIds.find((c) => c === card.filePath) === undefined
                )
                .map((card, i) => (
                  <StyledHandCard
                    key={card.filePath}
                    style={{
                      z: i, // Safari fix for position
                    }}
                    initial={{
                      y: cardHandHeight,
                    }}
                    animate={{
                      y: 0,
                    }}
                    onAnimationComplete={() => {
                      if (i === filteredShuffledCards.length - 1)
                        setIsFirstLoad(false);
                    }}
                    transition={{
                      delay: isFirstLoad ? 1 + i * 0.01 : 0,
                    }}
                  >
                    <Card
                      key={card.filePath}
                      theme={theme}
                      className={"hand-card"}
                      size={deck.cardSize}
                      border={deck.border}
                      borderColor={deck.borderColor}
                      fallbackColor={deck.fallbackColor}
                      src={imageUrl(card.filePath)}
                      backSrc={
                        deck.backArtwork
                          ? deck.backArtwork.includes(".mp4")
                            ? "/images" + deck.basePath + "/" + deck.backArtwork
                            : imageUrl(deck.basePath + "/" + deck.backArtwork)
                          : null
                      }
                      onPointerOver={(e) => {
                        e.stopPropagation();
                        updateStorage((storage) =>
                          storage.set("handHoverIndex", i)
                        );
                      }}
                      onPointerOut={(e) => {
                        e.stopPropagation();
                        updateStorage((storage) =>
                          storage.set("handHoverIndex", -99)
                        );
                      }}
                      onMouseMove={(e) => {
                        e.stopPropagation();
                      }}
                      onPanStart={() => {
                        localState.activeCard = card.filePath;
                        updateStorage((storage) => {
                          storage.set("handHoverIndex", -99);
                        });
                      }}
                      onPanEnd={() => {}}
                      whileTap={{
                        cursor: "grabbing",
                      }}
                      style={{
                        cursor: "grab",
                        left: "50%",
                        bottom: 0,
                        position: "absolute",
                        display: "inline-block",
                        width: CARD_HAND_WIDTH,
                        height: cardHandHeight,
                        transformOrigin: `${ORIGIN.x * 100}% ${
                          ORIGIN.y * 100
                        }%`,
                        pointerEvents:
                          isHandVisible && activeCard === null
                            ? "auto"
                            : "none",
                      }}
                      animate={{
                        x:
                          map(
                            i,
                            0,
                            filteredShuffledCards.length - 1,
                            (-WIDTH *
                              (filteredShuffledCards.length /
                                deck.cards.length)) /
                              2,
                            (WIDTH *
                              (filteredShuffledCards.length /
                                deck.cards.length)) /
                              2
                          ) -
                          CARD_HAND_WIDTH / 2 +
                          map(
                            ORIGIN.x,
                            0,
                            1,
                            CARD_HAND_WIDTH,
                            -CARD_HAND_WIDTH
                          ) -
                          (i === hoverIndex
                            ? map(
                                i,
                                0,
                                filteredShuffledCards.length - 1,
                                20,
                                -20
                              )
                            : 0),
                        y:
                          lerp(0, filteredShuffledCards.length - 1, i) *
                            INC_Y *
                            (deck.cards.length /
                              filteredShuffledCards.length) **
                              2.2 +
                          arc(
                            i,
                            0,
                            filteredShuffledCards.length - 1,
                            0,
                            ARC_Y *
                              (filteredShuffledCards.length / deck.cards.length)
                          ) -
                          ciscoBridgeCurve(
                            map(
                              clip(hoverIndex - i, -NEIGHBOR_CT, NEIGHBOR_CT),
                              -NEIGHBOR_CT,
                              NEIGHBOR_CT,
                              -1,
                              1
                            )
                          ) *
                            NEIGHBOR_HOVER -
                          (i === hoverIndex ? HOVERING_Y : 0),
                        scale:
                          activeCard === null
                            ? ciscoBridgeCurve(
                                map(
                                  clip(
                                    hoverIndex - i,
                                    -NEIGHBOR_CT,
                                    NEIGHBOR_CT
                                  ),
                                  -NEIGHBOR_CT,
                                  NEIGHBOR_CT,
                                  -1,
                                  1
                                ),
                                HOVERING_SCALE + 0.1
                              ) * HOVERING_SCALE
                            : 1,
                        rotateY: 180,
                        rotateZ: map(
                          i,
                          0,
                          (deck.cards.length - 1) *
                            (filteredShuffledCards.length / deck.cards.length),
                          ROTATION,
                          -ROTATION
                        ),
                      }}
                    />
                  </StyledHandCard>
                ))}
            </AnimatePresence>
          </motion.div>
        </StyledHandContainer>
      </StyledHitArea>
    </>
  );
});

const StyledHandCard = React.memo(
  styled(motion.div, {
    "&:active svg": {
      "@mobile": {
        transform: "translateY(-20px)",
        transition: "transform 0.2s",
      },
    },
  })
);

const StyledHitArea = styled("div", {
  position: "absolute",
  inset: 0,
  top: "auto",
  height: "100px",
});
const StyledHandContainer = React.memo(
  styled(motion.div, {
    position: "absolute",
    inset: 0,
    top: "auto",
    userSelect: "none",
  })
);

const coeff = 2;
function ciscoBridgeCurve(x: number, max?: number) {
  if (x === 0) return max || 1;
  return 1 / Math.abs(x) ** 0.2 / coeff + (1 - 1 / coeff);
}
