export const GUIDELINES_LAST_UPDATED = "October 27, 2023";

export const GUIDELINES_TEXT = `
We're committed to creating a welcoming, friendly, and collaborative environment. To enforce a safe and respectful space, we expect everyone on our platform to comply with the following:
<br/><br/>
    <ol type="1">
    <li><strong>Age requirements.</strong>All users must be at least 18 years old to participate in services. Anyone under 18 may only book sessions with parental or guardian permission.</li>
    <li><strong>No legal, medical, or financial advice.</strong> Conversations on Moonlight are not intended to replace or serve as a substitute for talking to a doctor, lawyer, or financial advisor.
    </li>
    <li><strong>No factual predictions.</strong> While tarot can offer reflections and insights, we don’t endorse tarot as absolute or definitive predictions of future events.
    </li>
    <li><strong>No therapy claims.</strong>Tarot is not intended to replace professional therapy. Even if it feels therapeutic, do not represent tarot as licensed mental health treatment.</li>
    <li><strong>No harassment.</strong>This includes no demeaning, discriminatory, or harassing behavior and speech, intimidation, stalking, unwanted attention, or incitement of violence or harm.
    </li>
    <li><strong>Be respectful and kind.</strong>Approach conversations with kindness and curiosity. We welcome a diversity of experiences.</li>
    <li><strong>No discrimination.</strong>We’re committed to building a welcoming space for all members, regardless of gender identity, sexual orientation, disability, appearance, race, or religion.
    </li>
    <li><strong>Maintain confidentiality.</strong>Do not share private details or specifics from readings without explicit consent. Ask before sharing identifying information.
    </li>
    <li><strong>No unauthorized solicitation.</strong>Unless you’re in our professional directory, promoting or marketing commercial products, services, events, or organizations is prohibited without prior approval.
    </li>
    <li><strong>No gatekeeping.</strong>There are many ways to practice tarot. Please do not shame or invalidate how others read cards.
    </li>
    </ol>
    <br/><br/>
    <strong>Consequences.</strong>If anyone engages behavior that goes against our guidelines, our platform may take any action we deem appropriate, including but not limited to banning you from Moonlight services and removing your profile page. If you're not sure if you're violating our Community Guidelines or why you received a violation, you can contact support@moonlight.world.
    <br/><br/>
    <strong>Reporting.</strong>If you notice any behavior that violates the Code of Conduct on our platform, please contact support@moonlight.world with details. We will respect your confidentiality. 
    <br/><br/>
    <strong>Feedback.</strong>We welcome feedback on these Community Guidelines and any other aspects of the Moonlight! Our goal is to make a friendly and respectful experience for all participants.
    <br/><br/>
    <strong>Last updated on ${GUIDELINES_LAST_UPDATED}</strong>
`;

