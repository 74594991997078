import * as RadixTooltip from "@radix-ui/react-tooltip";
import { styled } from "@stitches/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useSnapshot } from "valtio";
import { MOBILE_ZOOM_INDEX_MOD } from "~/utils/consts";
import { localState } from "~/state/state";
export function Tooltip({
  children,
  content,
}: React.PropsWithChildren<{ content: string }>) {
  const portalRef = useRef<HTMLDivElement | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { zoomIndex } = useSnapshot(localState);

  return (
    <>
      <div
        style={{
          display: "contents",
        }}
        ref={(r) => {
          portalRef.current = r;
          setIsLoaded(true);
        }}
      ></div>
      <RadixTooltip.Provider>
        <RadixTooltip.Root delayDuration={0.1}>
          <RadixTooltip.Trigger
            style={{
              display: "contents",
              fontFamily: "inherit",
              fontSize: "inherit",
              fontWeight: "inherit",
              fontStyle: "inherit",
              cursor: content ? "help" : "auto",
            }}
          >
            {children}
          </RadixTooltip.Trigger>
          {content && (
            <RadixTooltip.Content>
              <AnimatePresence>
                {isLoaded && (
                  // Wrapper to trigger AnimatePresence
                  <div key="content">
                    {ReactDOM.createPortal(
                      <StyledContent
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                        style={{
                          transform: `translate(-50%, 0) scale(${
                            1 / 1.15 ** zoomIndex
                          })`,
                          transformOrigin: "top center",
                        }}
                      >
                        <RadixTooltip.Arrow />
                        {content}
                      </StyledContent>,
                      portalRef.current ?? document.body
                    )}
                  </div>
                )}
              </AnimatePresence>
            </RadixTooltip.Content>
          )}
        </RadixTooltip.Root>
      </RadixTooltip.Provider>
    </>
  );
}

const StyledContent = styled(motion.div, {
  background: "rgba(0,0,0,.8)",
  backdropFilter: "blur(2px)",
  color: "white",
  padding: ".5rem",
  borderRadius: ".5rem",
  position: "absolute",
  top: "100%",
  left: "50%",
  fontSize: ".75rem",
  width: "100%",
  textAlign: "center",
});
