import { localState } from "~/state/state";

import toastMessages from "~/assets/toastMessages.json"

const DEFAULT_TOAST_MESSAGE = "Uh oh! Something went wrong..."

export const clearToast = () => {
    localState.currentMessage = {
      msg: null,
      isError: false,
      isPerpetual: false
    };
}

export const sendToast = (isError: boolean, toastCode: string | number) => {

    // Look up toast in toastMessages.json
    const toast = toastMessages.find(toast => toast.code.toString() === toastCode.toString())
    if (!toast) {
        console.error(`Could not find toast message for code ${toastCode}`)
        if (isError) localState.currentMessage = {
            msg: DEFAULT_TOAST_MESSAGE,
            isError: true,
            isPerpetual: false
        };
        return;
    }
   else if (toast.isInvisible) {
        return;
    }

    //Display toast
    const toastMessage = toast.toastText || `(${toastCode}) ${DEFAULT_TOAST_MESSAGE}`
    localState.currentMessage = {
        msg: toastMessage,
        isError: isError,
        isPerpetual: toast.isPerpetual ?? false
    };
}
  
  export const sendFriendlyToast = (toastCode: string | number) => {
    // TODO: handle toast messages in new way
    sendToast(false, toastCode)
  };
  
  export const sendToastMessageWithFallbackCode = ( message: string | null, isError: boolean, fallbackCode: number) => {
    if (!message) {
        sendToast(isError, fallbackCode)
    } else {
    localState.currentMessage = {
        msg: message,
        isError: isError,
        isPerpetual: false
    }
}
  }

  export const sendErrorToast = (toastCode: string | number) => {
    sendToast(true, toastCode)
  }
  
  export const sendServerErrorToastWithFallbackCode = (error: any, fallbackCode: number) => { 
    sendErrorToast((error as any)?.response?.data?.toastMessageCode || fallbackCode);
  }
  
  export const sendServerFriendlyToastWithFallbackCode = (json: any, fallbackCode: number) => {
    sendFriendlyToast((json as any)?.data?.toastMessageCode || fallbackCode);
  }
  