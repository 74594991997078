import { styled } from "~/ui/style/stitches.config";
import { CLIENT_STATIC_FILES_RUNTIME_POLYFILLS } from "next/dist/shared/lib/constants";
import { useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import { useLocation } from "wouter";
import { TOAST_TIME_MS } from "~/utils/consts";
import { localState } from "~/state/state";
import { clearToast } from "~/utils/handleToasts";

export const Message = ({ inset }: { inset?: boolean }) => {
  const { currentMessage } = useSnapshot(localState);
  let mounted = false;
  const [timer, setTimer] = useState<number | null>(null);
  const [lastMessage, setLastMessage] = useState({
    msg: "",
    isError: false,
  });
  useEffect(() => {
    if (mounted)
      return () => {
        clearToast();
      };
    else {
      mounted = true;
    }
  }, []);

  useEffect(() => {
    if (localState.currentMessage?.msg)
      setLastMessage({
        msg: localState.currentMessage.msg,
        isError: localState.currentMessage.isError,
      });
  }, [localState.currentMessage]);

  return (
    <StyledMessage
      inset={inset}
      isFading={!currentMessage?.msg}
      isError={lastMessage?.isError}
    >
      <div
        className="inner-message"
        dangerouslySetInnerHTML={{ __html: lastMessage.msg }}
      />
    </StyledMessage>
  );
};

export const StyledMessage = styled("div", {
  color: "black !important",
  height: "100px",
  fontSize: "12px",
  width: "425px",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  margin: "auto",
  textAlign: "center",
  marginTop: "10px",
  display: "flex",
  zIndex: "1000000000",
  "& .inner-message": {
    backgroundColor: "#F0F0F0",
    padding: "16px 50px",
    width: "100%",
    height: "auto",
    borderRadius: "40px",
    alignSelf: "flex-end",
    marginBottom: "12px",
    "@mobile": {
      padding: "16px 20px",
    },
  },

  "@mobile": {
    maxWidth: "90%",
  },

  variants: {
    inset: {
      true: {
        "@notmobile": {
          left: "$navWidth",
        },
      },
    },
    isFading: {
      true: {
        opacity: 0,
        transition: "opacity 0.5s",
        pointerEvents: "none",
      },
    },
    isError: {
      true: {
        "& .inner-message": {
          color: "$error",
        },
      },
    },
    hasPic: {
      true: {
        "& .inner-message": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "11px",

          "& .text": {
            width: "auto",
          },
          "& img": {
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            marginRight: "8px",
            marginLeft: "-25px",
          },
        },
      },
    },
  },
});
