import { useState, useEffect, useRef } from "react";
import { Button } from "../components/Button";
import { keyframes, styled } from "../style/stitches.config";
import { uploadBlob } from "~/api/roomApi";
import { MenuTooltip, QuestionHover } from "../components/Tooltip";
import { useSnapshot } from "valtio";
import { localState } from "~/state/state";
import { Message } from "../components/Message";

const TestMic = ({ audioLevel }: { audioLevel: number }) => {
  return (
    <div>
      <div style={{ marginTop: "10px" }}>
        <div
          style={{
            height: "17px",
            background: "#fff",
            borderRadius: "4px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "100%",
              background: "#E9B9FF",
              width: `${audioLevel * 100}%`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

enum RECORDING_STATE {
  LOADING,
  NOT_STARTED,
  AV_CHECK,
  COUNTDOWN,
  RECORDING,
  PROCESSING_RECORDING,
}

const RecordingIcon = ({
  src,
  tooltip,
  onClick,
  isHighlighted,
  Theme,
  text,
}: {
  src: string;
  tooltip: string;
  onClick: Function;
  isHighlighted: boolean;
  Theme: "dark" | "light";
  text: string;
}) => {
  const button = (
    <StyledRecordingButton
      Theme={Theme}
      isHighlighted={isHighlighted}
      onClick={() => onClick()}
      style={{ position: "relative" }}
    >
      <img className="recording-icon" src={src}></img>Stop & send
    </StyledRecordingButton>
  );
  return (
    <MenuTooltip
      display={button}
      text={tooltip}
      vertical
      verticalReverse
      tooltipStyle={{
        marginTop: "-90px",
        marginLeft: "52px",
      }}
      menuStyle={{
        paddingTop: 0,
        transformOrigin: "unset",
        width: "fit-content",
      }}
    ></MenuTooltip>
  );
};
export const RoomRecording = ({
  startRecording,
  stopRecording,
  startScreenshare,
  stopScreenshare,
  audioLevel,
}: {
  startRecording: Function;
  stopRecording: Function;
  startScreenshare: Function;
  audioLevel: number;
  stopScreenshare: Function;
}) => {
  const [recordingState, setRecordingState] = useState(RECORDING_STATE.LOADING);
  const [countdownSec, setCountdownSec] = useState(3);
  const [recordingSec, setRecordingSec] = useState(0);

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const { inDarkMode } = useSnapshot(localState);
  const beforeUnloadListenerRef = useRef<any>(null);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      // Standard across modern browsers
      event.preventDefault(); // Standard for most browsers

      const confirmationMessage = "Are you sure you want to leave?";
      event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    };
    beforeUnloadListenerRef.current = handleBeforeUnload;
    (async () => {
      // Attach the event listener
      window.addEventListener("beforeunload", beforeUnloadListenerRef.current);
      setRecordingState(RECORDING_STATE.NOT_STARTED);
    })();

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener(
        "beforeunload",
        beforeUnloadListenerRef.current
      );
    };
  }, []);

  useEffect(() => {
    if (recordingState === RECORDING_STATE.NOT_STARTED) {
      setRecordingSec(0);
      stopScreenshare();
    }

    if (recordingState === RECORDING_STATE.COUNTDOWN) {
      setCountdownSec(3);
      window.setTimeout(() => {
        setCountdownSec(2);
      }, 1000);
      window.setTimeout(() => {
        setCountdownSec(1);
      }, 2000);
      window.setTimeout(() => {
        setRecordingState(RECORDING_STATE.RECORDING);
        startRecording();
      }, 3000);
    }

    if (recordingState === RECORDING_STATE.RECORDING && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setRecordingSec((prevSec) => prevSec + 1);
      }, 1000);
    } else if (
      recordingState !== RECORDING_STATE.RECORDING &&
      intervalRef.current
    ) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    } else if (recordingState === RECORDING_STATE.PROCESSING_RECORDING) {
      window.removeEventListener(
        "beforeunload",
        beforeUnloadListenerRef.current
      );
    }
  }, [recordingState]);

  return (
    <StyledRoomRecording>
      <Message />
      {recordingState === RECORDING_STATE.NOT_STARTED && (
        <StyledRoomRecordingUI
          Theme={inDarkMode ? "dark" : "light"}
          onClick={async () => {
            await startScreenshare();
            setRecordingState(RECORDING_STATE.AV_CHECK);
          }}
        >
          Start recording<div className="recording-indicator"></div>
        </StyledRoomRecordingUI>
      )}
      {recordingState === RECORDING_STATE.AV_CHECK && (
        <StyledRoomRecordingModal Theme={inDarkMode ? "dark" : "light"}>
          <div className="modal">
            <img
              className="btn back"
              src="/images/back-button.svg"
              alt="back"
              onClick={() => {
                setRecordingState(RECORDING_STATE.NOT_STARTED);
              }}
            />
            <div className="title">Talk to check mic</div>
            <TestMic audioLevel={audioLevel} />
            <div style={{ fontSize: "11px", marginTop: "10px" }}>
              You should see a moving purple bar. If not, fix your audio
              settings.
            </div>

            <Button
              lightweight
              style={{
                height: "32px",
                fontSize: "12px",
              }}
              onClick={() => {
                setRecordingState(RECORDING_STATE.COUNTDOWN);
              }}
            >
              Ready to record!
            </Button>
          </div>
        </StyledRoomRecordingModal>
      )}
      {recordingState === RECORDING_STATE.COUNTDOWN && (
        <StyledRoomRecordingUI
          Theme={inDarkMode ? "dark" : "light"}
          noHover
        >{`Starting in ... ${countdownSec}`}</StyledRoomRecordingUI>
      )}
      {recordingState === RECORDING_STATE.RECORDING && (
        <StyledRoomRecordingUI
          Theme={inDarkMode ? "dark" : "light"}
          noHover
          className={`blinking-${recordingSec > 14 * 60 + 50}`}
        >
          <span style={{ width: "100px" }}>
            {`${Math.floor(recordingSec / 60) < 10 ? "0" : ""}${Math.floor(
              recordingSec / 60
            )}:${recordingSec % 60 < 10 ? "0" : ""}${recordingSec % 60}`}{" "}
          </span>
          <RecordingIcon
            Theme={inDarkMode ? "dark" : "light"}
            src="/images/recording-icons/stop-recording.svg"
            tooltip="End recording and send to client"
            text="Stop & send"
            isHighlighted={recordingState === RECORDING_STATE.RECORDING}
            onClick={() => {
              stopRecording();
              setRecordingState(RECORDING_STATE.PROCESSING_RECORDING);
            }}
          />
        </StyledRoomRecordingUI>
      )}

      {recordingState === RECORDING_STATE.PROCESSING_RECORDING && (
        <StyledVideoViewerModal Theme={inDarkMode ? "dark" : "light"}>
          <div
            className="modal"
            style={{
              width: "500px",
              height: "400px",
            }}
          >
            <div
              className="loading-title"
              style={{
                marginTop: 0,
                fontSize: "15px",
                fontWeight: "500",
                marginBottom: "8px",
              }}
            >
              <>
                Nice video! Sending to client...
                <br />
                We'll email you when it sends
              </>
            </div>
            <div
              className="loading-subtitle"
              style={{
                fontSize: "12px",
                marginTop: "8px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "280px",
                }}
              >
                This may take a moment, but you’re free to leave the room!
              </div>
            </div>
            {recordingState === RECORDING_STATE.PROCESSING_RECORDING && (
              <div>
                <div
                  className="support-subtitle"
                  style={{
                    fontSize: "10px",
                    marginTop: "40px",
                    marginBottom: "24px",
                  }}
                >
                  No email within 2 hours? Contact{" "}
                  <a href="mailto:support@moonlight.world">
                    support@moonlight.world
                  </a>
                </div>
                <Button
                  darkHover
                  onClick={() => {
                    window.location.pathname = "/sessions";
                  }}
                >
                  Return to dashboard
                </Button>
              </div>
            )}
          </div>
        </StyledVideoViewerModal>
      )}
    </StyledRoomRecording>
  );
};

const StyledRecordingButton = styled("div", {
  marginLeft: "12px",
  "&:hover": {
    opacity: "0.8",
  },
  borderRadius: "15px",
  overflow: "hidden",
  width: "fit-content",
  paddingRight: "18px",
  marginRight: "-12px",
  height: "23px",
  textWrap: "nowrap",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "black",

  variants: {
    isHighlighted: {
      true: {
        backgroundColor: "#FFD89A",
        color: "black",
        "& img": {
          filter: "invert(1)",
        },
      },
    },
    Theme: {
      dark: {},
      light: {},
    },
  },
  compoundVariants: [
    {
      Theme: "dark",
      isHighlighted: true,
      css: {
        filter: "none !important",
      },
    },
  ],
});

const StyledRoomRecordingModal = styled("div", {
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  "& .modal": {
    backgroundColor: "$wash",
    width: "200px",
    padding: "30px 20px",
    borderRadius: "13px",
    border: "1px solid black",
    height: "fit-content",
    position: "relative",

    "& .title": {
      fontSize: "12px",
      fontWeight: "500",
    },
    "& .subtitle": {
      fontSize: "11px",
      marginTop: "8px",
    },
    "& .btn.back": {
      width: "18px",
      position: "absolute",
      top: "10px",
      right: "10px",
      transform: "rotate(270deg)",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.8,
      },
    },
  },
  variants: {
    Theme: {
      dark: {
        color: "black",
      },
      light: {},
    },
  },
});

const StyledVideoViewerModal = styled(StyledRoomRecordingModal, {
  "& .modal": {
    marginTop: "60px",
    width: "500px",
    "& .video": {
      width: "457px",
      height: "336px",
      borderRadius: "24px",
      "& video": {
        maxWidth: "100%",
        maxHeight: "100%",
        borderRadius: "16px",
      },
    },
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    textAlign: "center",
    "& .title": {
      marginTop: "12px",
    },
    "& .subtitle": {
      marginBottom: "20px",
    },
    "& .button-container button": {
      fontSize: "12px",
      marginLeft: "3px",
      marginRight: "3px",
      padding: "12px 20px",
      borderRadius: "12px",
      width: "130px",
    },
  },
});

const blink = keyframes({
  "0%": {
    color: "transparent",
  },
  "50%": {
    color: "transparent",
  },
  "51%": {
    color: "red",
  },
  "100%": {
    color: "red",
  },
});

const StyledRoomRecordingUI = styled("div", {
  backgroundColor: "black",
  height: "36px",
  width: "200px",
  padding: "10px 20px",
  borderRadius: "30px",
  color: "white",
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .recording-indicator": {
    width: "9px",
    height: "9px",
    borderRadius: "50%",
    backgroundColor: "$lightPurple",
    marginLeft: "7px",
  },
  cursor: "pointer",
  "&:hover": {
    opacity: "0.8",
  },
  "&.blinking-true": {
    animation: `${blink} 1s infinite`,
  },
  variants: {
    noHover: {
      true: {
        "&:hover": {
          opacity: 1,
        },
      },
    },
    Theme: {
      dark: {
        backgroundColor: "white",
        color: "black",
      },
      light: {},
    },
  },
});
const StyledRoomRecording = styled("div", {
  display: "flex",
  justifyContent: "center",
  position: "fixed",
  bottom: "5px",
  left: 0,
  right: 0,
  margin: "auto",
  zIndex: 999,
  width: "fit-content",
  "@mobile": {
    display: "none",
  },
});
