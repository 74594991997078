import { useStorage } from "../../state/liveblocks.config";
import { Button } from "../components/Button";
import { styled } from "~/ui/style/stitches.config";
import { Deck, RoomTheme } from "~/types/room/types";
import { ConfirmMenuLabel } from "./BottomBar";
import { imageUrl } from "~/utils/imageurl";
import { deckNameToDeckPageId } from "~/pages/marketplace/DeckMarketplace";

function DeckInfoSection({
  name,
  creator,
  onClick,
  description,
  link,
  theme,
  img,
  confirmMenuOpen,
}: {
  name: string;
  creator: string;
  location: string;
  description: string;
  onClick: Function;
  link: string;
  activeDeck: Deck;
  theme: RoomTheme;
  img: string;
  confirmMenuOpen: ConfirmMenuLabel;
}) {
  return (
    <StyledDeckInfoSection Theme={theme} className={"open"}>
      {confirmMenuOpen === "Deck Info" && (
        <div className="info-container">
          <Button
            Theme={theme}
            className="arrow-button"
            role="menuitem"
            style={{
              padding: "2px",
              marginRight: "4px",
              marginTop: "9px",
              marginBottom: "-8px",
              width: "25px",
              marginLeft: "128px",
              transform: "rotate(-90deg)",
            }}
            transparent
            onClick={() => {
              onClick();
            }}
          >
            &larr;
          </Button>
          <h3 className="location">{`${name}`}</h3>
          <p className="description">{description}</p>
          <Button
            lightweight
            Theme={theme}
            onClick={() => {
              window.open(link, "_blank");
            }}
          >
            More details
          </Button>
        </div>
      )}
      <div className="name-container" onClick={() => onClick()}>
        <img className="preview" src={img} />
        <div className="text-container">
          <h1 className="name  noselect">{name}</h1>
          <h2 className="creator">{creator}</h2>
        </div>
      </div>
    </StyledDeckInfoSection>
  );
}

export function DeckInfoBox({
  onClick,
  confirmMenuOpen,
}: {
  onClick: Function;
  confirmMenuOpen: ConfirmMenuLabel;
}) {
  const theme = useStorage((root) => root.theme);
  const deck = useStorage((root) => root.deck);

  return (
    <StyledDeckInfoBox Theme={theme} id="deck-info-box">
      <DeckInfoSection
        name={deck.name}
        creator={deck.creator}
        location={deck.location}
        description={deck.description}
        link={
          window.location.protocol +
          "//" +
          window.location.host +
          "/decks/" +
          deckNameToDeckPageId(deck.name)
        }
        activeDeck={deck}
        theme={theme}
        img={imageUrl("/decks/deck-previews/" + deck.preview, 200)}
        onClick={onClick}
        confirmMenuOpen={confirmMenuOpen}
      />
    </StyledDeckInfoBox>
  );
}

const StyledDeckInfoBox = styled("div", {
  position: "absolute",
  left: "122px",
  "@mobile": {
    left: "78px",
  },
  bottom: "10px",
  zIndex: "9",

  "& h1": {
    fontFamily: "WorkSans",
    fontSize: "10px",
    "@mobile": {
      fontSize: "12px",
    },
    fontWeight: "500",
    paddingBottom: "$1",

    borderRadius: "$1",
  },
  "& .preview": {
    width: "33px",
    borderRadius: "6px",
    border: "1px solid black",
    marginRight: "6px",
    marginLeft: "-26px",
  },

  "& .section-title": {
    backgroundColor: "$washA800",
    padding: "2px $1",
    marginBottom: "$1",
    // width: "60px",
  },

  variants: {
    Theme: {
      light: {},
      dark: {
        "& .section-title": {
          backgroundColor: "$darkwashA700",
        },
      },
    },
  },
});

const StyledDeckInfoSection = styled("div", {
  marginBottom: "-$1",
  "& .name-container": {
    cursor: "pointer",
    display: "flex",
    verticalAlign: "middle",
    padding: "0 $1",
    borderRadius: "$1",
    "& .text-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },

    "& .down-icon": {
      display: "inline-block",
      marginTop: "3px",
      marginBottom: "-3px",
      marginLeft: "$1",
      transition: "all 0.1s ease-in-out",
    },
  },
  "& .info-container": {
    backgroundColor: "$wash",
    border: "1px solid $darkwashA500",
    borderRadius: "16px",
    padding: "16px",
    paddingBottom: "22px",
    paddingTop: "0px",
    width: "175px",
    marginLeft: "-23px",
    marginTop: "$2",
    marginBottom: "$4",
  },
  "& .name": {
    fontSize: "12px",
  },
  "& .creator": {
    fontSize: "10px",
    fontWeight: "400",
    marginTop: "-4px",
  },
  "& .location": {
    fontSize: "12px",
    fontWeight: "500",
  },
  "& .description": {
    fontSize: "11px",
    "@mobile": {
      fontSize: "12px",
    },
    marginTop: "$4",
  },

  "&.closed": {
    "& .down-icon": {
      transform: "rotate(180deg)",
    },
    "& .info-container": {
      height: 0,
      width: 0,
      margin: 0,
      padding: 0,
      border: 0,
      overflow: "hidden",
    },
  },
  variants: {
    Theme: {
      light: {},
      dark: {
        "& .info-container": {
          backgroundColor: "$darkwash",
          border: "1px solid $washA500",

          "& button:hover": {
            backgroundColor: "$gray900",
          },
        },
      },
    },
  },
});
