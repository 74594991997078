import { AuthContext } from "../utils/useAuth.js";
import axios from "./axiosConfig.js";
import { useContext } from "react";
import { getUserByUsername } from "./userApi.js";

const ANALYTICS_ENDPOINT = "analytics/";

function getBrowserInfo() {
    const userAgent = navigator.userAgent;
    if (!userAgent) {
      return {
        name: "Unknown",
        version: "Unknown",
        platform: "Unknown"
      };
    }
    let browserName = "Unknown";
    let browserVersion = "Unknown";
    let browserPlatform = "Unknown";

  
    let browserVersionArr : string[]= [];
    // Detect browser name and version
    if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Firefox";
      browserVersionArr = userAgent.match(/Firefox\/([0-9.]+)/) || [];
    
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Chrome";
      browserVersionArr = userAgent.match(/Chrome\/([0-9.]+)/) || [];
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Safari";
      browserVersionArr = userAgent.match(/Version\/([0-9.]+)/) || [];
     
    } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident/") > -1) {
      browserName = "Internet Explorer";
      browserVersionArr = userAgent.match(/(?:MSIE |rv:)(\d+(\.\d+)?)/) || []
    } else if (userAgent.indexOf("Edge") > -1) {
      browserName = "Edge";
      browserVersionArr = userAgent.match(/Edge\/([\d.]+)/) || [];
    }

    if (browserVersionArr.length > 1) {
      browserVersion = browserVersionArr[1];
    }
  
    // Detect browser platform (Windows, Mac, Linux, Android, iOS)
    if (userAgent.indexOf("Win") > -1) {
      browserPlatform = "Windows";
    } else if (userAgent.indexOf("Mac") > -1) {
      browserPlatform = "MacOS";
    } else if (userAgent.indexOf("Linux") > -1) {
      browserPlatform = "Linux";
    } else if (userAgent.indexOf("Android") > -1) {
      browserPlatform = "Android";
    } else if (userAgent.indexOf("iPhone") > -1 || userAgent.indexOf("iPad") > -1 || userAgent.indexOf("iPod") > -1) {
      browserPlatform = "iOS";
    }
  
    return {
      name: browserName,
      version: browserVersion,
      platform: browserPlatform
    };
  }

export const trackEvent = async(event: string,  properties?: any) => {
    let sessionId = localStorage?.getItem("sessionId")
    
    if (!sessionId) {
            sessionId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            localStorage?.setItem("sessionId", sessionId)
    } 

    const browserInfo = getBrowserInfo();
    const response = await axios.post(ANALYTICS_ENDPOINT + "track", {eventName: event, uuid: sessionId, properties: {
        city: Intl.DateTimeFormat().resolvedOptions().timeZone,
        os: browserInfo.platform,
        browser: browserInfo.name,
        version: browserInfo.version,
        ...properties
}})
    if (response.status !== 200) {
        throw new Error(response.statusText)
    }
}