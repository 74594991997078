import { link } from "fs";
import { useLocation } from "wouter";
import { localState } from "~/state/state";
import { styled } from "~/ui/style/stitches.config";
import { Button } from "~/ui/components/Button";
import { useSnapshot } from "valtio";
import { useContext } from "react";
import { AuthContext } from "~/utils/useAuth";

export const ProPageHeader = ({
  editingProfile,
  linkCopied,
  setLinkCopied,
  backgroundColor,
  backgroundTheme,
  data,
  setFavoritesUpdated,
  favorites,
  setEditingProfile,
  resetForm,
}: {
  editingProfile: boolean;
  linkCopied: boolean;
  setLinkCopied: Function;
  backgroundTheme: string;
  backgroundColor: string;
  data: any;
  setFavoritesUpdated: Function;
  favorites: any;
  setEditingProfile: Function;
  resetForm: Function;
}) => {
  const [location, setLocation] = useLocation();
  const { isOnMobile } = useSnapshot(localState);
  const { user } = useContext(AuthContext);

  return (
    <StyledProPageHeader
      noUser={!user}
      style={{
        backgroundColor: backgroundColor + "DD",
      }}
    >
      <div className="header-buttons">
        <div className="home-button-container">
          <div className="home-button-inner-container">
            <div
              className="home-button"
              style={{
                backgroundColor: backgroundColor,
                border:
                  backgroundTheme === "dark"
                    ? "1px solid white"
                    : "1px solid black",
              }}
              onClick={() => {
                setLocation("/book");
              }}
            >
              <span className="arrow">&larr;</span>
              <span className="text">Profiles</span>
            </div>
          </div>
        </div>
        <div></div>
        <div className="profile-button-container">
          <ProfileEditButtons
            editingProfile={editingProfile}
            linkCopied={linkCopied}
            setLinkCopied={setLinkCopied}
            backgroundTheme={backgroundTheme}
            backgroundColor={backgroundColor}
            data={data}
            setFavoritesUpdated={setFavoritesUpdated}
            favorites={favorites}
            setEditingProfile={setEditingProfile}
            resetForm={resetForm}
          />
        </div>
      </div>
    </StyledProPageHeader>
  );
};

const StyledProPageHeader = styled("div", {
  width: "100vw",
  position: "fixed",
  top: "$navHeight",
  padding: "12px 16px",
  display: "flex",
  zIndex: 10000,
  justifyContent: "center",
  "& .header-buttons": {
    justifyContent: "space-between",
    display: "flex",
    width: "100%",
    "@notmobile": {
      width: "100%",
      padding: "18px 0 0px 0",
      "& > div": {
        width: "33.33%",
        display: "flex",
        justifyContent: "center",
      },
      "& .home-button-inner-container": {
        width: "80%",
        display: "flex",
      },
      "& .home-button": {
        width: "auto !important",
        padding: "0 16px 0 14px",
        borderRadius: "1rem",
      },
      "& .home-button, button": {
        "&:hover": {
          filter: "brightness(0.95)",
        },
      },
    },
  },

  backgroundColor: "transparent !important",
  pointerEvents: "none",
  "& .home-button, button": {
    pointerEvents: "all",
  },

  "@mobile": {
    marginTop: "6px",
    "& .edit-buttons": {
      width: "100%",
    },
    "& .home-button": {
      border: "none !important",
      padding: "6px 8px",
      borderRadius: "10px",
    },

    "& .home-button-container": {
      display: "flex",
      alignItems: "center",
    },
  },

  "& .home-button": {
    cursor: "pointer",

    display: "flex",
    alignItems: "center",
    marginLeft: "-6px",
    "& .arrow": {
      marginRight: "8px",
      fontSize: "16px",
      fontWeight: "200",
    },
    "& .text": {
      fontSize: "12px",
    },
  },

  variants: {
    noUser: {
      true: {
        "@mobile": {
          // paddingTop: "60px",
        },
      },
      false: {},
    },
  },
});

export const ProfileEditButtons = ({
  editingProfile,
  linkCopied,
  setLinkCopied,
  backgroundTheme,
  data,
  setFavoritesUpdated,
  favorites,
  setEditingProfile,
  resetForm,
  backgroundColor,
}: {
  editingProfile: boolean;
  linkCopied: boolean;
  setLinkCopied: Function;
  backgroundTheme: string;
  data: any;
  setFavoritesUpdated: Function;
  favorites: any;
  setEditingProfile: Function;
  resetForm: Function;
  backgroundColor?: string;
}) => {
  const { user, addOrRemoveFavorite } = useContext(AuthContext);
  return (
    <div className="edit-buttons">
      {!editingProfile && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (!linkCopied)
              navigator.clipboard.writeText(window.location.href).then(() => {
                setLinkCopied(true);
              });
          }}
          style={{
            padding: "12px 16px",
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            marginRight: "8px",
            backgroundColor: backgroundColor
              ? backgroundColor
              : backgroundTheme === "dark"
              ? "#000"
              : "#fff",
            borderColor: backgroundTheme === "dark" ? "#fff" : "#000",
          }}
        >
          {linkCopied ? (
            "Link copied"
          ) : (
            <>
              <img
                style={{
                  width: "16px",
                  height: "16px",
                  marginRight: "5px",
                  filter: backgroundTheme === "dark" ? "invert(1)" : "none",
                }}
                className="icon"
                src="/images/room/copy-link-icon.svg"
              ></img>
              {`Copy `}
              <span
                style={{ marginLeft: "4px" }}
                className="extra"
              >{`URL`}</span>
            </>
          )}
        </Button>
      )}
      {user && (
        <Button
          noBorder
          className="fadein not-myprofile"
          onClick={(e) => {
            e.preventDefault();
            (async () => {
              if (user.uuid && data.uuid) {
                await addOrRemoveFavorite(data.uuid).then(() =>
                  setFavoritesUpdated(true)
                );
              }
            })();
          }}
          style={{
            padding: "12px",
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            backgroundColor: backgroundTheme === "dark" ? "white" : "black",
            color: backgroundTheme === "dark" ? "black" : "white",
          }}
        >
          {data.uuid &&
          user.favorites &&
          user.favorites.indexOf(data.uuid) >= 0 ? (
            <>
              <img
                style={{
                  marginRight: "5px",
                  width: "16px",
                  filter: backgroundTheme === "light" ? "invert(1)" : "none",
                }}
                className="icon"
                src="/images/room/heart-full.svg"
              ></img>
              Saved
            </>
          ) : (
            <>
              {" "}
              <img
                style={{
                  marginRight: "5px",
                  width: "16px",
                  filter: backgroundTheme === "light" ? "invert(1)" : "none",
                }}
                className="icon"
                src="/images/room/heart.svg"
              ></img>
              Save
            </>
          )}
        </Button>
      )}

      {!editingProfile ? (
        <>
          <div id="fadein1" style={{ display: "flex" }}>
            <Button
              noBorder
              className={"myprofile"}
              onClick={(e) => {
                e.preventDefault();
                setEditingProfile(true);
              }}
              style={{
                padding: "12px 16px",
                display: "flex",
                alignItems: "center",
                backgroundColor: backgroundTheme === "dark" ? "white" : "black",
                color: backgroundTheme === "dark" ? "black" : "white",
                fontSize: "12px",
              }}
            >
              <img
                style={{
                  width: "16px",
                  height: "16px",
                  marginRight: "5px",
                  filter: backgroundTheme === "dark" ? "invert(1)" : "none",
                }}
                className="icon"
                src="/images/edit.svg"
              ></img>
              Edit{" "}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div id="fadein2" style={{ display: "flex" }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setEditingProfile(false);
                resetForm();
              }}
              style={{
                padding: "12px 16px",
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                backgroundColor: backgroundColor
                  ? backgroundColor
                  : backgroundTheme === "dark"
                  ? "#000"
                  : "#fff",
                borderColor: backgroundTheme === "dark" ? "#fff" : "#000",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              noBorder
              disabled={data.isSubmitting}
              style={{
                padding: "12px 16px",
                display: "flex",
                alignItems: "center",
                marginLeft: "8px",
                border: "none !important",

                backgroundColor: backgroundTheme === "dark" ? "white" : "black",
                color: backgroundTheme === "dark" ? "black" : "white",
                fontSize: "12px",
              }}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
