import { styled } from "@stitches/react";
import { AuthFormState, AuthPage } from "~/pages/login/Auth";

export const BookingSignInPage = ({
  price,
  hostUserName,
  onSubmitSignIn,
}: {
  price: number | undefined;
  hostUserName: string;
  onSubmitSignIn: Function;
}) => {
  return (
    <StyledBookingSignInPage>
      <AuthPage
        state={AuthFormState.REGISTER}
        onlyForms={true}
        isInBookingFlow={true}
        price={price}
        hostUserName={hostUserName}
        onSubmitSignIn={onSubmitSignIn}
      />
    </StyledBookingSignInPage>
  );
};

const StyledBookingSignInPage = styled("div", {
  width: "100%",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "& .auth-form-container": {
    width: "100% !important",
    backgroundColor: "transparent !important",
    border: "none !important",
    boxShadow: "none !important",
    "& form": {
      width: "80% !important",
    },
    "& .back-button": {
      display: "none !important",
    },
  },
});
