import { Vec2d } from "./useMousePosition";

export function subV2d(a: Vec2d, b: Vec2d): Vec2d {
  return [a[0] - b[0], a[1] - b[1]];
}

export function addV2d(a: Vec2d, b: Vec2d): Vec2d {
  return [a[0] + b[0], a[1] + b[1]];
}

export function mulV2d(a: Vec2d, b: Vec2d): Vec2d {
  return [a[0] * b[0], a[1] * b[1]];
}

export function divV2d(a: Vec2d, b: Vec2d): Vec2d {
  return [a[0] / b[0], a[1] / b[1]];
}

export function toPrice(price: number): string {
  return price.toFixed(2);
}