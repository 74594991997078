export type MetaTags = {
    route: string;
    title: string;
    description: string;
}

interface VariableDict {
    key: string;
    value: string;
}

import { allDecks } from "~/ui/menus/DeckMenu";
import MetaTagDict from "../assets/metaTags.json";
import { deckPageIdToDeckName } from "~/pages/marketplace/DeckMarketplace";
import { getUserByUsername } from "~/api/userApi";


export const routeToMeta = async (route: string) => {
    let routeToSearch = route;

    let variables: VariableDict[] = []

    // Specific decks: get params
    if (route.includes("/decks/")) {
        routeToSearch = "/decks/{{deckName}}";
        const deckName = deckPageIdToDeckName(route.split("/decks/")[1])
        if (deckName) {
        variables.push({key: "deckName", value: deckName ? deckName : ""});
            const deck = allDecks.find((d) => d.name === deckName);
            if (deck) {
                variables.push({key: "deckType", value: deck.name.includes("Viator") ? "Oracle" : "Tarot"});
                variables.push({key: "deckAuthor", value: deck.creator});
                variables.push({key: "deckDescription", value: deck.description})
            }
        } else {
            routeToSearch = "/decks";
        }
    } 

    // Specific profiles: get params
    if (route.includes("/profile/")) {
        const username = route.split("/profile/")[1];
            const usr = await getUserByUsername(username);
            if (usr.isProfessional) {
                routeToSearch = "/profile/{{username}}";
                variables.push({key: "username", value: username});
                variables.push({key: "displayName", value: usr.name})
                variables.push({key: "bio", value: usr.bio})
              
            }
    }

    const searchedMetaTagsForRoute = MetaTagDict.find((meta: MetaTags) => meta.route === routeToSearch);
    const metaTagsForRoute = searchedMetaTagsForRoute ? searchedMetaTagsForRoute : MetaTagDict.find((meta: MetaTags) => meta.route === "/") as MetaTags;



    let title = metaTagsForRoute.title;
    let description = metaTagsForRoute.description;

     // iterate over variables array
    variables.forEach((variable) => {
        title = title.replace(`{{${variable.key}}}`, variable.value);
        description = description.replace(`{{${variable.key}}}`, variable.value);
    });

    return {
        title: title,
        description: description,
        route: window.location.origin + route,
    }
}

