import { createStitches } from "@stitches/react";

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  
  media: {
    mobile: "(max-width: 640px)",
    notmobile: "(min-width: 640px)",
    bp1: "(max-width: 1200px)",
    bp2: "(max-width: 1100px)",
    bp900: "(max-width: 900px)",
    bp3: "(max-width: 750px)",
    bpfilter: "(max-width: 840px)",

    smaller: "(max-width: 1000px)",
    notsmaller: "(min-width: 1000px)"
  },
  theme: {
    
    colors: {
      gray100: "hsla(0, 0%, 96%, 1)",
      gray200: "hsla(0, 0%, 92%, 1)",
      gray300: "hsla(0, 0%, 79%, 1)",
      gray400: "hsla(0, 0%, 68%, 1)",
      gray500: "hsla(0, 0%, 55%, 1)",
      gray600: "hsla(0, 0%, 40%, 1)",

      gray700: "hsla(0, 0%, 24%, 1)",

      gray800: "hsla(0, 0%, 20%, 1)",
      gray900: "hsla(0, 0%, 10%, 1)",
      gray950: "hsla(0, 0%, 5%, 1)",
      grayA30: "hsla(0, 0%, 0%, 0.03)",

      grayA50: "hsla(0, 0%, 0%, 0.05)",
      grayA700: "hsla(0, 0%, 0%, 0.7)",
      grayA100: "hsla(0, 0%, 0%, 0.1)",
      grayA200: "hsla(0, 0%, 0%, 0.2)",
      grayA300: "hsla(0, 0%, 0%, 0.3)",
      whiteA500: "hsla(0, 0%, 100%, 0.5)",
      wash: "$gray100",
      washA500: "hsla(0,0%, 96%, 0.5)",
      washA700: "hsla(0,0%, 96%, 0.7)",
      washA800: "hsla(0,0%, 96%, 0.8)",
      washA900: "hsla(0,0%, 96%, 0.9)",

      darkwash: "$gray900",
      darkwashA500: "hsla(0, 0%, 10%, 0.5)",

      darkwashA700: "hsla(0, 0%, 10%, 0.7)",
      darkwashA800: "hsla(0, 0%, 10%, 0.8)",

      error: "#DE6D5D",
      
      lightPurple: "#F0D8FF",
      lightPurpleHover: "#F2DCFF"
     

    },

    shadows: {
      lightPurpleSmallShadow: "0px 0px 7px 2px #F0D8FF",
    },

    sizes: {
      1: ".25rem",
      2: ".5rem",
      3: ".75rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      navHeight: "40px",
      navWidth: "148px",
    },
    space: {
      1: ".25rem",
      2: ".5rem",
      3: ".75rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      navHeight: "37px",
      navWidth: "148px",
    },
    radii: {
      1: ".25rem",
      2: ".5rem",
      3: ".75rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
    },
    fontSizes: {
      xs: ".5rem",
      sm: ".75rem",
      default: "1rem",
      lg: "1.25rem",
      xl: "1.5rem",
    },
  },
  utils: {
    
  },
});
