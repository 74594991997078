import { styled } from "@stitches/react";
import { motion } from "framer-motion";
import { useSnapshot } from "valtio";
import {
  ZOOM_BASE,
  DEFAULT_ZOOM_INDEX,
  MOBILE_ZOOM_INDEX_MOD,
  PAN_BASE,
} from "~/utils/consts";
import { localState } from "~/state/state";
import { useSelf, useStorage } from "../../state/liveblocks.config";
import { useEffect } from "react";

interface Props {
  id?: string;
  perspective: number;
  angle: number;
  exit?: Object;
  useDefaultZoom?: boolean;
  style?: Object;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function Perspective({
  id,
  children,
  perspective,
  angle,
  exit,
  useDefaultZoom,
  style,
  onClick,
}: React.PropsWithChildren<Props>) {
  const state = useStorage((root) => root.state);

  const { pan, panScreenDelta, zoomIndex, isOnMobile } =
    useSnapshot(localState);

  const zoomIndexMod = isOnMobile ? MOBILE_ZOOM_INDEX_MOD : 0;
  const defaultZoomModifier = ZOOM_BASE ** DEFAULT_ZOOM_INDEX;
  const zoomModifier = useDefaultZoom
    ? defaultZoomModifier
    : ZOOM_BASE ** (zoomIndex + zoomIndexMod);
  const panXModifier = useDefaultZoom
    ? 0
    : pan[0] * PAN_BASE + panScreenDelta[0];
  const panYModifier = useDefaultZoom
    ? 0
    : pan[1] * PAN_BASE + panScreenDelta[1];

  return (
    <>
      <StyledPerspective
        id={id}
        className="perspective"
        onClick={onClick}
        style={{
          perspective: perspective / zoomModifier + "px",
          transform: `scale(${zoomModifier}) translateX(${panXModifier}px) translateY(${panYModifier}px)`,
          ...style,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={exit}
        transition={{ duration: 3 }}
      >
        <StyledAngled
          style={{
            transform: `translate(-50%, -50%) rotateX(${angle}deg)`,
          }}
        >
          {children}
        </StyledAngled>
      </StyledPerspective>
    </>
  );
}

const StyledPerspective = styled(motion.div, {
  position: "absolute",
  inset: 0,
  userSelect: "none",
});

const StyledAngled = styled(motion.div, {
  position: "absolute",
  top: "50%",
  left: "50%",
  transformStyle: "preserve-3d",
});
