import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "wouter";
import { AxiosError } from "axios";
import { CredentialResponse } from "google-one-tap";
import { StyledForm } from "./SignUp";
import { Button } from "~/ui/components/Button";
import { Message } from "~/ui/components/Message";
import { localState } from "~/state/state";
import { AuthContext } from "~/utils/useAuth";
import { AuthFormState, StyledClickableText } from "~/pages/login/Auth";
import { sendFriendlyToast } from "~/utils/handleToasts";
import { trackEvent } from "~/api/analyticsApi";

type State = {
  email: string;
  password: string;
  isSubmitting: boolean;
  errorMessage: string | null;
};

const initialState = {
  email: "",
  password: "",
  isSubmitting: false,
  errorMessage: null,
};

export const SigninForm = ({
  setAuthState,
  shouldRedirectAfterSignIn,
  isInBookingFlow,
  onSubmitSignIn,
}: {
  setAuthState: Function;
  shouldRedirectAfterSignIn?: boolean;
  isInBookingFlow?: boolean;
  onSubmitSignIn?: Function;
}) => {
  const [data, setData] = useState<State>(initialState);
  const [passwordUpdatedMessage, setPasswordUpdatedMessage] = useState<
    string | null
  >(null);
  const { loginEmail, loginGoogle } = useContext(AuthContext);

  let [, setLocation] = useLocation();

  useEffect(() => {
    if (!isInBookingFlow) {
      trackEvent("/home/sign_in/viewed");
    }
  }, [isInBookingFlow]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const reset = urlParams.get("reset");
    if (reset) {
      // Password updated
      sendFriendlyToast(1148);
    }
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const redirectAfterSignIn = () => {
    if (!shouldRedirectAfterSignIn) return;
    let query = window.location.search;
    let urlQueryParams = new URLSearchParams(query);
    let dest = urlQueryParams.get("dest") || "";
    if (dest) {
      setLocation(decodeURIComponent(dest));
    } else {
      setLocation("/");
    }
  };

  const handleGoogleSignin = async (response: CredentialResponse) => {
    try {
      await loginGoogle(response);
      if (shouldRedirectAfterSignIn) redirectAfterSignIn();
      if (onSubmitSignIn) onSubmitSignIn();
    } catch (error) {
      let errorMessage = null;
      if (error instanceof AxiosError) {
        errorMessage = error.message;
      }
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: errorMessage,
      });
      trackEvent("/home/google_auth_signin");
    }
  };

  const handleSignin = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    trackEvent("/home/sign_in");

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    try {
      await loginEmail(data.email, data.password);
      if (shouldRedirectAfterSignIn) redirectAfterSignIn();
      if (onSubmitSignIn) onSubmitSignIn();
    } catch (error) {
      let errorMessage = null;
      if (error instanceof AxiosError) {
        errorMessage = error.message;
      }
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: errorMessage,
      });
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (window.google) {
      google.accounts.id.initialize({
        client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID as string,
        callback: handleGoogleSignin,
      });

      google.accounts.id.renderButton(
        document.getElementById("googlesignin") as HTMLElement,
        {
          type: "standard",
          theme: "outline",
          text: "continue_with",
          shape: "pill",
          logo_alignment: "left",
        }
      );
    }
  }, []);

  return (
    <>
      <StyledForm className="auth-form-container">
        <h3 className="title">
          {isInBookingFlow
            ? "Welcome back! Sign in to continue"
            : "Welcome back to Moonlight!"}
        </h3>
        <Message />

        <form onSubmit={handleSignin}>
          <div className="input-container">
            <input
              type="email"
              name="email"
              placeholder="Email address"
              aria-describedby="inputGroupPrepend"
              required
              onInvalid={(e) => e.preventDefault()}
              value={data.email}
              onChange={handleInputChange}
            />
            <p className="error-message">Invalid email. Check for typos</p>
          </div>

          <input
            className="sentry-mask"
            type="password"
            name="password"
            placeholder="Password"
            required
            onInvalid={(e) => e.preventDefault()}
            id="Login"
            value={data.password}
            onChange={handleInputChange}
          />

          <Button type="submit" formButton disabled={data.isSubmitting}>
            Sign in
          </Button>
          <h3 className="or">or</h3>
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
              height: "30px",
            }}
            id="googlesignin"
          ></div>
          <StyledClickableText
            style={{ marginTop: "24px" }}
            onClick={() => {
              setAuthState(AuthFormState.FORGOTPASSWORD);
            }}
          >
            Forgot password?
          </StyledClickableText>
        </form>
      </StyledForm>
      <StyledClickableText
        lowerText
        onClick={() => setAuthState(AuthFormState.REGISTER)}
      >
        Sign up instead
      </StyledClickableText>
    </>
  );
};
